import { motion } from 'framer-motion';
import React from 'react';
import bannerService from '../../../assets/image/bg-dichvu.svg';
import icon3 from '../../../assets/image/cung-caplark.svg';
import icon2 from '../../../assets/image/trienkhailark.svg';
import icon1 from '../../../assets/image/tubanlark.svg';
import { fadeInRight, fadeInUp } from '../../../assets/utils/utils';
import SlickSlider from '../../../components/slickSlider/SlickSlider';

const mocdata = [
    {
        icon: icon1,
        title: 'Cung cấp license ',
        title2: 'Lark với giá ưu đãi',
        content: [
            'Cung cấp license Lark Pro, Enterprise, Frontline với giá ưu đãi',
            'Cung cấp dịch vụ hợp đồng, xuất hóa đơn, chi phí nhà thầu phù hợp với quy định và luật pháp Việt Nam',
        ],
    },
    {
        icon: icon2,
        title: 'Tư vấn và ',
        title2: 'triển khai Lark',
        content: [
            'Triển khai các tính năng cơ bản của Lark',
            'Cấu hình và sử dụng các tính năng workplace trên Lark',
            'Tùy chỉnh và tích hợp Lark với các hệ thống bên ngoài',
        ],
    },
    {
        icon: icon3,
        title: 'Đào tạo',
        title2: 'sử dụng Lark',
        content: ['Đào tạo sử dụng Lark online và offline', ' Hỗ trợ ứng dụng, triển khai Lark theo tháng'],
    },
];

const Services = () => {
    const setting = {
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
    };
    return (
        <section
            id="Services"
            className="bg-scroll hero-section division "
            style={{
                backgroundImage: `url(${bannerService})`,
            }}
        >
            <div className="container list-box">
                <motion.div
                    className="hero-txt wow fadeInUp banner-mt"
                    initial="initial"
                    whileInView="animate"
                    variants={fadeInRight}
                    viewport={{ once: true }}
                >
                    <h2 className="h2-xs  text-center text-white" style={{ fontSize: '2.625rem', zIndex: 1 }}>
                        Các dịch vụ chính
                    </h2>
                    <p
                        className="p-md-custom pt-30 pt-30-custom px-custom-10  text-white"
                        style={{ textAlign: 'center', paddingTop: '30px', width: '75%', margin: 'auto' }}
                    >
                        AIV Group tự hào là đối tác chính thức đầu tiên của Lark Suite tại Việt Nam. Chúng tôi cung cấp
                        đầy đủ các dịch vụ triển khai Lark Suite dành cho doanh nghiệp.
                    </p>
                </motion.div>

                <div className="childrenbox pc-on md-on">
                    <div className="row" style={{ rowGap: '2rem' }}>
                        {mocdata.map((item, index) => (
                            <motion.div
                                className="col-md-6 col-lg-4"
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUp}
                                viewport={{ once: true }}
                                key={index}
                            >
                                <div className="fbox-1 center bg-white box-home p-4 icon-md wow fadeInUp text-left">
                                    <div className="pb-1" data-wow-delay="0.3s">
                                        <img className="img-35" src={item.icon} alt="discovery" />
                                    </div>
                                    <div className="mb-3 mt-3">
                                        <h5 className="h5-xl text-black" style={{ marginBottom: '0' }}>
                                            {' '}
                                            {item.title}
                                        </h5>
                                        <h5 className="h5-xl text-black"> {item.title2}</h5>
                                    </div>
                                    <ul style={{ listStyle: 'outside' }}>
                                        {item.content.map((i, index) => (
                                            <li className="">
                                                <p key={index} className="p-md mb-2 " style={{ textAlign: 'justify' }}>
                                                    {i}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
                {/*  */}
                <div className="md-off">
                    <SlickSlider settings={setting}>
                        {mocdata.map((item, index) => (
                            <motion.div
                                className="col-md-6 col-lg-4 mt-3"
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUp}
                                viewport={{ once: true }}
                                key={index}
                            >
                                <div
                                    className="fbox-1 center bg-white rounded-4 p-4 icon-md wow fadeInUp text-left"
                                    style={{ height: '400px' }}
                                >
                                    <div className="pb-1" data-wow-delay="0.3s">
                                        <img className="img-35" src={item.icon} alt="discovery" />
                                    </div>
                                    <div className="mb-3 mt-3">
                                        <h5 className="h5-xl text-black" style={{ marginBottom: '0' }}>
                                            {' '}
                                            {item.title}
                                        </h5>
                                        <h5 className="h5-xl text-black"> {item.title2}</h5>
                                    </div>
                                    <ul style={{ listStyle: 'outside' }}>
                                        {item.content.map((i, index) => (
                                            <li className="">
                                                <p key={index} className="p-md mb-2 " style={{ textAlign: 'justify' }}>
                                                    {i}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </motion.div>
                        ))}
                    </SlickSlider>
                </div>
                {/* mobile-on */}
                <div className="mobile-on  ">
                    <SlickSlider settings={{ dots: false }}>
                        {mocdata.map((item, index) => (
                            <motion.div
                                className="col-md-6 col-lg-4 mt-3"
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUp}
                                viewport={{ once: true }}
                                key={index}
                            >
                                <div
                                    className="fbox-1 center bg-white rounded-4 p-4 icon-md wow fadeInUp text-left"
                                    style={{ height: '300px' }}
                                >
                                    <div className="d-flex align-items-center justify-content-around mb-3">
                                        <div className="pb-1" data-wow-delay="0.3s">
                                            <img className="img-35" src={item.icon} alt="discovery" />
                                        </div>
                                        <div className="mb-3 mt-3 d-flex">
                                            <h5 className="h5-xl ms-1" style={{ marginBottom: '0' }}>
                                                {' '}
                                                {item.title}
                                            </h5>
                                            <h5 className="h5-xl ms-2"> {item.title2}</h5>
                                        </div>
                                    </div>
                                    <ul style={{ listStyle: 'outside' }}>
                                        {item.content.map((i, number) => (
                                            <li className="">
                                                <p key={number} className="p-md mb-2 " style={{ textAlign: 'justify' }}>
                                                    {i}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </motion.div>
                        ))}
                    </SlickSlider>
                </div>
            </div>
        </section>
    );
};

export default Services;
