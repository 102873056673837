import { motion } from 'framer-motion';
import React from 'react';
import benefit1 from '../../../assets/image/benefit-1.svg';
import benefit2 from '../../../assets/image/benefit-2.svg';
import benefit3 from '../../../assets/image/benefit-3.svg';
import { fadeInUp } from '../../../assets/utils/utils';
import SlickSlider from '../../../components/slickSlider/SlickSlider';
const Benefit = () => {
    const options = [
        {
            title: 'Giao tiếp chuyên nghiệp',
            img: benefit1,
            description:
                'Business email được sử dụng để giao tiếp trong môi trường kinh doanh, do đó nó có tính chuyên nghiệp hơn so với email cá nhân.',
        },
        {
            title: 'Tính bảo mật cao',
            img: benefit2,
            description:
                'Business email được bảo vệ bởi các giải pháp an ninh mạng chuyên nghiệp, bao gồm mã hóa dữ liệu và hệ thống xác thực, giúp bảo mật thông tin của doanh nghiệp.',
        },
        {
            title: 'Đồng bộ hóa nhiều thiết bị',
            img: benefit3,
            description: 'Giúp cho người dùng có thể truy cập thông tin và giao tiếp bất cứ khi nào, bất cứ đâu',
        },
    ];
    return (
        <div
            style={{
                backgroundColor: '#ECECF7',
                paddingTop: 100,
                paddingBottom: 100,
            }}
        >
            <div className="container">
                <h2 className="h2-xs text-center" style={{ marginBottom: 50 }}>
                    Lợi ích của Business Mail
                </h2>
                <div className="pc-on">
                    <div className="d-flex flex-row benefit">
                        {options.map((item, index) => (
                            <motion.div
                                className="col-md-6 col-lg-4 px-30  wow fadeInUp pricing-table benefit-item"
                                style={{
                                    marginBottom: '25px',
                                }}
                                initial="initial"
                                whileInView="animate"
                                key={index}
                                variants={fadeInUp}
                                viewport={{ once: true }}
                            >
                                <div className="bg-white h-100 box-home p-4 pt-5">
                                    <div
                                        className="pb-25 d-flex justify-content-center"
                                        data-wow-delay="0.3s"
                                        style={{ paddingBottom: '25px' }}
                                    >
                                        <img height={150} src={item.img} alt="data" />
                                    </div>
                                    <div>
                                        <h5 className="h5-sm text-upcase" style={{ color: '#222' }}>
                                            {item.title}
                                        </h5>
                                    </div>
                                    <p className="p-md" style={{ textAlign: 'justify' }}>
                                        {' '}
                                        {item.description}
                                    </p>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
                <div className="d-flex mobile-on" style={{ marginTop: 50 }}>
                    <SlickSlider>
                        {options.map((item, index) => (
                            <motion.div
                                className="col-md-6 col-lg-4 px-30  wow fadeInUp pricing-table"
                                style={{
                                    marginBottom: '25px',
                                }}
                                initial="initial"
                                whileInView="animate"
                                key={index}
                                variants={fadeInUp}
                                viewport={{ once: true }}
                            >
                                <div className="bg-white h-100 box-home p-4 pt-5" style={{ minHeight: 345 }}>
                                    <div
                                        className="pb-25 d-flex justify-content-center"
                                        data-wow-delay="0.3s"
                                        style={{ paddingBottom: '25px' }}
                                    >
                                        <img height={150} src={item.img} alt="data" />
                                    </div>
                                    <div>
                                        <h5 className="h5-sm text-upcase" style={{ color: '#222' }}>
                                            {item.title}
                                        </h5>
                                    </div>
                                    <p className="p-md"> {item.description}</p>
                                </div>
                            </motion.div>
                        ))}
                    </SlickSlider>
                </div>
            </div>
        </div>
    );
};

export default Benefit;
