import { motion } from 'framer-motion';
import { fadeInUpDelay1, fadeInUpDelay2 } from '../../assets/utils/utils';
export default function Option({ ...props }) {
    const { imagevd2 } = props;

    return (
        <section id="info-1" className="info-1-row wide-100 info-section division">
            <div className="container">
                <div>
                    <h3 className="h3-lg mb-5 text-center class-open" style={{ fontWeight: 700, fontSize: '2.875rem' }}>
                        Tại sao nên lựa chọn chúng tôi?
                    </h3>
                </div>
                <div className="row d-flex align-items-center">
                    <div className="col-md-6 custom-flexs">
                        <motion.div
                            className="img-block -m-rem  mb-40 wow fadeInUp"
                            data-wow-duration="1.5s"
                            data-wow-delay="0.8s"
                            style={{
                                visibility: 'visible',
                                animationDuration: '1.5s',
                                animationDelay: '0.8s',
                                animationName: 'fadeInUp',
                            }}
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUpDelay2}
                            viewport={{ once: true }}
                        >
                            <img className="img-fluid" src={imagevd2} alt="info" />
                        </motion.div>
                    </div>

                    <div className="col-md-6 custom-flexs">
                        <motion.div
                            className="txt-block pc-40 new-pc mb-40 wow fadeInUp"
                            data-wow-delay="0.3s"
                            style={{
                                visibility: 'visible',
                                animationDelay: '0.3s',
                                animationName: 'fadeInUp',
                            }}
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUpDelay1}
                            viewport={{ once: true }}
                        >
                            <h3 className="h3-lg class-off" style={{ fontWeight: 700, fontSize: '2.875rem' }}>
                                Tại sao nên lựa chọn chúng tôi?
                            </h3>
                            <div className="row">
                                <div className="col-md-6 col-lg-6 mb-2">
                                    <motion.div
                                        className="d-flex align-items-center pb-20 wow fadeInUp"
                                        data-wow-delay="0.3s"
                                        style={{
                                            visibility: 'visible',
                                            animationDelay: '0.3s',
                                            animationName: 'fadeInUp',
                                        }}
                                        initial="initial"
                                        whileInView="animate"
                                        variants={fadeInUpDelay1}
                                        viewport={{ once: true }}
                                    >
                                        <svg
                                            width={65}
                                            height={64}
                                            viewBox="0 0 65 64"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="0.742188"
                                                y="0.431641"
                                                width="63.2822"
                                                height="63.2822"
                                                rx={15}
                                                fill="#263238"
                                            />
                                            <g clipPath="url(#clip0)">
                                                <path
                                                    d="M48.7986 27.0458C48.5879 26.3979 48.0132 25.9391 47.336 25.8779L38.0985 25.0392L34.4478 16.4922C34.1783 15.8643 33.565 15.459 32.8824 15.459C32.1997 15.459 31.5862 15.8643 31.3184 16.4922L27.6677 25.0392L18.4288 25.8779C17.7515 25.9404 17.178 26.3991 16.9661 27.0458C16.7554 27.6938 16.95 28.4045 17.4622 28.8537L24.445 34.9766L22.3861 44.0447C22.2355 44.7114 22.4943 45.4009 23.0475 45.8008C23.3449 46.0169 23.6942 46.1248 24.0448 46.1248C24.3461 46.1248 24.6476 46.0447 24.9169 45.8836L32.8824 41.1208L40.8464 45.8836C41.4306 46.2327 42.1652 46.2007 42.7172 45.8008C43.2705 45.4009 43.5293 44.7114 43.3786 44.0447L41.3198 34.9766L48.3025 28.8537C48.8145 28.4045 49.0093 27.6953 48.7986 27.0458Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect
                                                        width={32}
                                                        height={32}
                                                        fill="white"
                                                        transform="translate(16.8828 14.7715)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </motion.div>
                                    <p className="p-md">Sản phẩm được phát triển hoàn toàn bởi tác giả Việt Nam</p>
                                </div>
                                <div className="col-md-6 col-lg-6  mb-2">
                                    <motion.div
                                        className="d-flex align-items-center pb-20 wow fadeInUp"
                                        data-wow-delay="0.3s"
                                        style={{
                                            visibility: 'visible',
                                            animationDelay: '0.3s',
                                            animationName: 'fadeInUp',
                                        }}
                                        initial="initial"
                                        whileInView="animate"
                                        variants={fadeInUpDelay1}
                                        viewport={{ once: true }}
                                    >
                                        <svg
                                            width={65}
                                            height={64}
                                            viewBox="0 0 65 64"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="0.976562"
                                                y="0.431641"
                                                width="63.2822"
                                                height="63.2822"
                                                rx={15}
                                                fill="#263238"
                                            />
                                            <g clipPath="url(#clip0)">
                                                <path
                                                    d="M18.3841 35.3018C17.9209 35.3018 17.542 35.6807 17.542 36.1438C17.542 36.607 17.9209 36.9859 18.3841 36.9859C18.8473 36.9859 19.2263 36.607 19.2263 36.1438C19.2262 35.6807 18.8472 35.3018 18.3841 35.3018Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M18.3841 28.5645C17.9209 28.5645 17.542 28.9434 17.542 29.4066C17.542 29.8698 17.9209 30.2487 18.3841 30.2487C18.8473 30.2487 19.2263 29.8698 19.2263 29.4066C19.2262 28.9434 18.8472 28.5645 18.3841 28.5645Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M30.1737 47.0908C29.7105 47.0908 29.3315 47.4698 29.3315 47.933C29.3315 48.3961 29.7105 48.7751 30.1737 48.7751C30.6369 48.7751 31.0158 48.3961 31.0158 47.933C31.0158 47.4698 30.6369 47.0908 30.1737 47.0908Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M23.4366 20.9863C22.5103 20.9863 21.7524 21.7442 21.7524 22.6705C21.7524 23.5968 22.5103 24.3548 23.4366 24.3548C24.363 24.3548 25.1208 23.5969 25.1208 22.6706C25.1208 21.7443 24.3629 20.9863 23.4366 20.9863Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M23.4366 27.7227C22.5103 27.7227 21.7524 28.4806 21.7524 29.4068C21.7524 30.3331 22.5103 31.091 23.4366 31.091C24.3629 31.091 25.1208 30.3331 25.1208 29.4068C25.1208 28.4806 24.3629 27.7227 23.4366 27.7227Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M23.4366 34.46C22.5103 34.46 21.7524 35.2179 21.7524 36.1441C21.7524 37.0704 22.5103 37.8283 23.4366 37.8283C24.3629 37.8283 25.1208 37.0705 25.1208 36.1441C25.1208 35.2179 24.3629 34.46 23.4366 34.46Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M48.7 30.2487C49.1632 30.2487 49.5422 29.8698 49.5422 29.4066C49.5422 28.9434 49.1632 28.5645 48.7 28.5645C48.2369 28.5645 47.8579 28.9434 47.8579 29.4066C47.8579 29.8698 48.2369 30.2487 48.7 30.2487Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M36.9105 18.4596C37.3737 18.4596 37.7526 18.0806 37.7526 17.6174C37.7526 17.1543 37.3737 16.7754 36.9105 16.7754C36.4473 16.7754 36.0684 17.1543 36.0684 17.6175C36.0684 18.0806 36.4474 18.4596 36.9105 18.4596Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M30.1734 24.3547C31.0997 24.3547 31.8576 23.5968 31.8576 22.6705C31.8576 21.7442 31.0997 20.9863 30.1734 20.9863C29.2472 20.9863 28.4893 21.7442 28.4893 22.6705C28.4893 23.5968 29.2472 24.3547 30.1734 24.3547Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M30.1737 18.4596C30.6369 18.4596 31.0158 18.0806 31.0158 17.6174C31.0158 17.1543 30.6369 16.7754 30.1737 16.7754C29.7105 16.7754 29.3315 17.1543 29.3315 17.6175C29.3315 18.0806 29.7105 18.4596 30.1737 18.4596Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M36.9103 24.3547C37.8365 24.3547 38.5944 23.5968 38.5944 22.6705C38.5944 21.7442 37.8365 20.9863 36.9103 20.9863C35.984 20.9863 35.2261 21.7442 35.2261 22.6705C35.2261 23.5968 35.984 24.3547 36.9103 24.3547Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M23.4366 41.1963C22.5103 41.1963 21.7524 41.9542 21.7524 42.8805C21.7524 43.8068 22.5103 44.5647 23.4366 44.5647C24.3629 44.5647 25.1208 43.8068 25.1208 42.8806C25.1208 41.9542 24.3629 41.1963 23.4366 41.1963Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M36.9106 26.8809C35.5127 26.8809 34.3843 28.0093 34.3843 29.4072C34.3843 30.8051 35.5127 31.9335 36.9106 31.9335C38.3085 31.9335 39.4369 30.8051 39.4369 29.4072C39.4369 28.0093 38.3085 26.8809 36.9106 26.8809Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M43.6476 34.46C42.7213 34.46 41.9634 35.2179 41.9634 36.1441C41.9634 37.0704 42.7213 37.8283 43.6476 37.8283C44.5739 37.8283 45.3317 37.0704 45.3317 36.1441C45.3317 35.2179 44.5739 34.46 43.6476 34.46Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M43.6476 41.1963C42.7213 41.1963 41.9634 41.9542 41.9634 42.8805C41.9634 43.8068 42.7213 44.5647 43.6476 44.5647C44.5739 44.5647 45.3317 43.8068 45.3317 42.8806C45.3317 41.9542 44.5739 41.1963 43.6476 41.1963Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M43.6476 27.7227C42.7213 27.7227 41.9634 28.4806 41.9634 29.4068C41.9634 30.3331 42.7213 31.091 43.6476 31.091C44.5739 31.091 45.3317 30.3331 45.3317 29.4068C45.3317 28.4806 44.5739 27.7227 43.6476 27.7227Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M48.7 35.3018C48.2369 35.3018 47.8579 35.6807 47.8579 36.1439C47.8579 36.607 48.2369 36.9859 48.7 36.9859C49.1632 36.9859 49.5422 36.607 49.5422 36.1438C49.5421 35.6807 49.1631 35.3018 48.7 35.3018Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M43.6476 20.9863C42.7213 20.9863 41.9634 21.7442 41.9634 22.6705C41.9634 23.5968 42.7212 24.3548 43.6476 24.3548C44.5739 24.3548 45.3317 23.5969 45.3317 22.6706C45.3317 21.7443 44.5739 20.9863 43.6476 20.9863Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M30.1738 33.6172C28.7759 33.6172 27.6475 34.7456 27.6475 36.1435C27.6475 37.5414 28.7759 38.6698 30.1738 38.6698C31.5717 38.6698 32.7001 37.5414 32.7001 36.1435C32.7001 34.7456 31.5717 33.6172 30.1738 33.6172Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M30.1734 41.1963C29.2472 41.1963 28.4893 41.9542 28.4893 42.8805C28.4893 43.8068 29.2472 44.5647 30.1734 44.5647C31.0997 44.5647 31.8576 43.8068 31.8576 42.8805C31.8576 41.9542 31.0997 41.1963 30.1734 41.1963Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M30.1738 26.8809C28.7759 26.8809 27.6475 28.0093 27.6475 29.4072C27.6475 30.8051 28.7759 31.9335 30.1738 31.9335C31.5717 31.9335 32.7001 30.8051 32.7001 29.4072C32.7001 28.0093 31.5717 26.8809 30.1738 26.8809Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M36.9103 41.1963C35.984 41.1963 35.2261 41.9542 35.2261 42.8805C35.2261 43.8068 35.984 44.5647 36.9103 44.5647C37.8365 44.5647 38.5944 43.8068 38.5944 42.8806C38.5945 41.9542 37.8366 41.1963 36.9103 41.1963Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M36.9105 47.0908C36.4473 47.0908 36.0684 47.4698 36.0684 47.933C36.0684 48.3961 36.4473 48.7751 36.9105 48.7751C37.3737 48.7751 37.7526 48.3961 37.7526 47.933C37.7526 47.4698 37.3737 47.0908 36.9105 47.0908Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M36.9106 33.6172C35.5127 33.6172 34.3843 34.7456 34.3843 36.1435C34.3843 37.5414 35.5127 38.6698 36.9106 38.6698C38.3085 38.6698 39.4369 37.5414 39.4369 36.1435C39.4369 34.7456 38.3085 33.6172 36.9106 33.6172Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect
                                                        width={32}
                                                        height={32}
                                                        fill="white"
                                                        transform="translate(17.542 16.7754)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </motion.div>
                                    <p className="p-md">Có thể sản xuất hàng trăm video trong thời gian ngắn</p>
                                </div>
                                <div className="col-md-6 col-lg-6 ">
                                    <motion.div
                                        className="d-flex align-items-center pb-20 wow fadeInUp"
                                        data-wow-delay="0.3s"
                                        style={{
                                            visibility: 'visible',
                                            animationDelay: '0.3s',
                                            animationName: 'fadeInUp',
                                        }}
                                        initial="initial"
                                        whileInView="animate"
                                        variants={fadeInUpDelay1}
                                        viewport={{ once: true }}
                                    >
                                        <svg
                                            width={64}
                                            height={64}
                                            viewBox="0 0 64 64"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="0.585938"
                                                y="0.448242"
                                                width="63.2822"
                                                height="63.2822"
                                                rx={15}
                                                fill="#263238"
                                            />
                                            <g clipPath="url(#clip0)">
                                                <path
                                                    d="M48.6461 43.2779L40.6464 33.2783L37.2668 35.4634C37.1121 35.5634 36.935 35.6136 36.7577 35.6136C36.591 35.6136 36.424 35.5691 36.2754 35.4799L32.8515 33.4256L29.4276 35.48C29.121 35.6639 28.7364 35.6574 28.4363 35.4634L25.0567 33.2783L17.057 43.2779C16.8319 43.5594 16.788 43.9449 16.9441 44.2697C17.1002 44.5945 17.4286 44.801 17.7891 44.801H47.914C48.2744 44.801 48.6028 44.5945 48.759 44.2697C48.9151 43.9449 48.8712 43.5594 48.6461 43.2779Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M28.9638 33.5715L32.3691 31.5282C32.666 31.35 33.0369 31.35 33.3338 31.5282L36.7392 33.5715L39.469 31.8065L33.7889 24.7064V21.849L40.802 18.3425C41.1196 18.1837 41.3202 17.8591 41.3202 17.504C41.3202 17.1489 41.1196 16.8243 40.802 16.6654L33.2707 12.8998C32.9801 12.7545 32.635 12.7699 32.3586 12.9409C32.0822 13.1117 31.9139 13.4135 31.9139 13.7384V24.7064L26.2339 31.8065L28.9638 33.5715Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect
                                                        width={32}
                                                        height={32}
                                                        fill="white"
                                                        transform="translate(16.8516 12.8008)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </motion.div>
                                    <p className="p-md">
                                        Là sản phẩm tiên phong trong lĩnh vực sản xuất video tự động tại Việt Nam
                                    </p>
                                </div>
                                <div className="col-md-6 col-lg-6 ">
                                    <motion.div
                                        className="d-flex align-items-center pb-20 wow fadeInUp"
                                        data-wow-delay="0.3s"
                                        initial="initial"
                                        whileInView="animate"
                                        variants={fadeInUpDelay1}
                                        viewport={{ once: true }}
                                        style={{
                                            visibility: 'visible',
                                            animationDelay: '0.3s',
                                            animationName: 'fadeInUp',
                                        }}
                                    >
                                        <svg
                                            width={65}
                                            height={64}
                                            viewBox="0 0 65 64"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="0.976562"
                                                y="0.448242"
                                                width="63.2822"
                                                height="63.2822"
                                                rx={15}
                                                fill="#263238"
                                            />
                                            <g clipPath="url(#clip0)">
                                                <path
                                                    d="M31.2837 40.7923C31.2837 41.5283 31.881 42.1257 32.617 42.1257C33.353 42.1257 33.9504 41.5283 33.9504 40.7923V24.7923C33.9504 24.0563 33.353 23.459 32.617 23.459C31.881 23.459 31.2837 24.0563 31.2837 24.7923V40.7923Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M17.9505 24.792C17.2145 24.792 16.6172 25.3893 16.6172 26.1253V39.4587C16.6172 40.1947 17.2145 40.792 17.9505 40.792C18.6865 40.792 19.2839 40.1947 19.2839 39.4587V26.1253C19.2839 25.3893 18.6865 24.792 17.9505 24.792Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M29.2839 38.125V27.4583C29.2839 26.7223 28.6865 26.125 27.9505 26.125C27.2145 26.125 26.6172 26.7223 26.6172 27.4583V38.125C26.6172 38.861 27.2145 39.4583 27.9505 39.4583C28.6865 39.4583 29.2839 38.861 29.2839 38.125Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M23.284 36.792C24.02 36.792 24.6174 36.1947 24.6174 35.4587V30.1253C24.6174 29.3893 24.02 28.792 23.284 28.792C22.548 28.792 21.9507 29.3893 21.9507 30.1253V35.4587C21.9507 36.1947 22.548 36.792 23.284 36.792Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M38.6173 38.125V27.4583C38.6173 26.7223 38.02 26.125 37.284 26.125C36.548 26.125 35.9507 26.7223 35.9507 27.4583V38.125C35.9507 38.861 36.548 39.4583 37.284 39.4583C38.02 39.4583 38.6173 38.861 38.6173 38.125Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M47.284 24.792C46.548 24.792 45.9507 25.3893 45.9507 26.1253V39.4587C45.9507 40.1947 46.548 40.792 47.284 40.792C48.02 40.792 48.6173 40.1947 48.6173 39.4587V26.1253C48.6173 25.3893 48.02 24.792 47.284 24.792Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M41.9505 28.792C41.2145 28.792 40.6172 29.3893 40.6172 30.1253V35.4587C40.6172 36.1947 41.2145 36.792 41.9505 36.792C42.6865 36.792 43.2839 36.1947 43.2839 35.4587V30.1253C43.2839 29.3893 42.6865 28.792 41.9505 28.792Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M31.2837 47.4587C31.2837 48.1947 31.881 48.792 32.617 48.792C33.353 48.792 33.9504 48.1947 33.9504 47.4587V46.1253C33.9504 45.3893 33.353 44.792 32.617 44.792C31.881 44.792 31.2837 45.3893 31.2837 46.1253V47.4587Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M32.617 20.792C33.353 20.792 33.9504 20.1947 33.9504 19.4587V18.1253C33.9504 17.3893 33.353 16.792 32.617 16.792C31.881 16.792 31.2837 17.3893 31.2837 18.1253V19.4587C31.2837 20.1947 31.881 20.792 32.617 20.792Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect
                                                        width={32}
                                                        height={32}
                                                        fill="white"
                                                        transform="translate(16.6172 16.792)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </motion.div>
                                    <p className="p-md">
                                        Tích hợp tính năng chuyển đổi từ văn bản sang giọng nói và giọng nói sang văn
                                        bản
                                    </p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
}
