import React from 'react';
import img01 from '../../../assets/image/mindmaid-img02.svg';

import { motion } from 'framer-motion';
import { fadeInLeft, fadeInRight, fadeInUp } from '../../../assets/utils/utils';
const Feature = () => {
    return (
        <div className="container feature">
            <motion.div
                initial="initial"
                whileInView="animate"
                variants={fadeInUp}
                viewport={{ once: true }}
                className="feature-title"
            >
                <h1>
                    Tất cả các tính năng mà bạn <br /> cần và hơn thế nữa
                </h1>
            </motion.div>
            <div className="feature-content">
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={fadeInLeft}
                    viewport={{ once: true }}
                    className="feature-content-left"
                >
                    <div className="feature-content-item">
                        <div className="top">
                            <div className="icon">
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.6668 1.33337L7.3335 8.66671M14.6668 1.33337L10.0002 14.6667L7.3335 8.66671M14.6668 1.33337L1.3335 6.00004L7.3335 8.66671"
                                        stroke="white"
                                        stroke-width="1.07"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="title">Trả lời thông minh</div>
                        </div>
                        <div className="bottom">
                            <div>
                                Hiểu tốt ngôn ngữ tự nhiên, gồm cả từ viết tắt, từ đồng nghĩa, lối diễn đạt tự nhiên
                            </div>
                            <div>Trả lời mượt mà và có phong cách nhờ ChatGPT</div>
                        </div>
                    </div>
                    <div className="feature-content-item">
                        <div className="top">
                            <div
                                className="icon"
                                style={{ background: 'linear-gradient(315.55deg, #EF4444 0%, #FF8989 92.87%)' }}
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.88334 7.11686L3.62722 12.373M4.19447 8.55839L1.61993 7.95026C1.33668 7.88335 1.23706 7.5317 1.44225 7.32577L3.48968 5.27833C3.62722 5.1408 3.81382 5.06199 4.00935 5.0605L6.31773 5.04043M9.22533 2.39899C10.9434 3.57065 12.4296 5.05679 13.6012 6.77488M7.44107 11.8057L8.0492 14.3803C8.11611 14.6635 8.46776 14.7631 8.67369 14.558L10.7211 12.5105C10.8587 12.373 10.9375 12.1864 10.939 11.9909L10.959 9.68247M14.0421 5.56679L14.6428 2.67629C14.8063 1.88973 14.1105 1.19387 13.3239 1.35743L10.4334 1.95813C9.58218 2.13507 8.80157 2.5566 8.18748 3.17142L5.81591 5.54225C4.91114 6.44702 4.32234 7.62017 4.13648 8.88625L4.1283 8.94052C4.01084 9.74864 4.28145 10.5642 4.85836 11.1418C5.43527 11.7188 6.25157 11.9894 7.05969 11.8712L7.11396 11.863C8.38004 11.6779 9.55318 11.0883 10.458 10.1836L12.8288 7.81272C13.4436 7.19864 13.8651 6.41803 14.0421 5.56679Z"
                                        stroke="white"
                                        stroke-width="1.07"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="title">Huấn luyện dễ dàng</div>
                        </div>

                        <div className="bottom">
                            <div>Dữ liệu dưới dạng văn bản</div>
                            <div> Tài liệu Excel/Google Sheet </div>
                            <div> Đường link website/blog (sắp ra mắt) </div>
                        </div>
                    </div>
                    <div className="feature-content-item">
                        <div className="top">
                            <div
                                className="icon"
                                style={{ background: ' linear-gradient(314.44deg, #F59E0B 0%, #FFD285 91.08%)' }}
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.1399 10.5934C13.7158 11.5964 13.0525 12.4802 12.2079 13.1676C11.3633 13.855 10.3631 14.325 9.2949 14.5366C8.22668 14.7481 7.12289 14.6948 6.08004 14.3812C5.03719 14.0677 4.08703 13.5034 3.31262 12.7378C2.53822 11.9722 1.96315 11.0285 1.6377 9.98934C1.31225 8.95014 1.24632 7.84703 1.44568 6.77646C1.64503 5.70589 2.10361 4.70046 2.78131 3.84806C3.45901 2.99566 4.3352 2.32225 5.33328 1.88671M14.6666 8.00004C14.6666 7.12456 14.4942 6.25765 14.1591 5.44882C13.8241 4.63998 13.333 3.90505 12.714 3.286C12.0949 2.66694 11.36 2.17588 10.5512 1.84084C9.74233 1.50581 8.87542 1.33337 7.99994 1.33337V8.00004H14.6666Z"
                                        stroke="white"
                                        stroke-width="1.07"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="title">Đo lường & thống kê</div>
                        </div>
                        <div className="bottom">
                            <div>Thống kê sử dụng Trợ lý ảo</div>
                            <div>Chỉ số chất lượng Trợ lý ảo</div>
                            <div>Báo cáo tuỳ chỉnh (Dashboard)</div>
                            <div>Bóc/tách số điện thoại, email</div>
                            <div> Tải về dữ liệu (csv)</div>
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={fadeInUp}
                    viewport={{ once: true }}
                    className="feature-content-center"
                >
                    <img src={img01} alt="mindmaid" />
                </motion.div>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={fadeInRight}
                    viewport={{ once: true }}
                    className="feature-content-right"
                >
                    <div className="feature-content-item">
                        <div className="top">
                            <div
                                className="icon"
                                style={{ background: 'linear-gradient(314.44deg, #14B8A6 0%, #48DFCE 91.08%)' }}
                            >
                                <svg
                                    width="12"
                                    height="14"
                                    viewBox="0 0 12 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10.6666 13L5.99992 9.66667L1.33325 13V2.33333C1.33325 1.97971 1.47373 1.64057 1.72378 1.39052C1.97382 1.14048 2.31296 1 2.66659 1H9.33325C9.68687 1 10.026 1.14048 10.2761 1.39052C10.5261 1.64057 10.6666 1.97971 10.6666 2.33333V13Z"
                                        stroke="white"
                                        stroke-width="1.07"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="title">Tính năng hỗ trợ cao cấp</div>
                        </div>
                        <div className="bottom">
                            <div>Trò truyện bằng giọng nói</div>
                            <div>Gợi ý câu hỏi, tự hoàn thiện câu hỏi</div>
                            <div>Hiển thị nguồn dữ liệu</div>
                        </div>
                    </div>
                    <div className="feature-content-item">
                        <div className="top">
                            <div
                                className="icon"
                                style={{ background: ' linear-gradient(318.24deg, #FF9635 0%, #FFDD99 94.95%)' }}
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.6666 7.38662V7.99995C14.6658 9.43756 14.2003 10.8364 13.3395 11.9878C12.4787 13.1393 11.2688 13.9816 9.89016 14.3892C8.51154 14.7968 7.03809 14.7479 5.68957 14.2497C4.34104 13.7515 3.18969 12.8307 2.40723 11.6247C1.62476 10.4186 1.25311 8.99199 1.3477 7.55749C1.44229 6.12299 1.99806 4.7575 2.93211 3.66467C3.86615 2.57183 5.12844 1.81021 6.53071 1.49338C7.93298 1.17656 9.4001 1.32151 10.7133 1.90662M14.6666 2.66662L7.99992 9.33995L5.99992 7.33995"
                                        stroke="white"
                                        stroke-width="1.07"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="title">Kiểm soát thông minh</div>
                        </div>

                        <div className="bottom">
                            <div>Kiểm soát hành vi chatbot bằng ngôn ngữ tự nhiên </div>
                            <div> Lọc câu hỏi xấu độc & chống hack (DAN Injection) </div>
                            <div> Tinh chỉnh nội dung câu trả lời</div>
                        </div>
                    </div>
                    <div className="feature-content-item">
                        <div className="top">
                            <div
                                className="icon"
                                style={{ background: 'linear-gradient(318.24deg, #7539F4 0%, #D3B8FF 94.95%)' }}
                            >
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.66667 1H1V5.66667H5.66667V1Z"
                                        stroke="white"
                                        stroke-width="1.07"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M13 1H8.33333V5.66667H13V1Z"
                                        stroke="white"
                                        stroke-width="1.07"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M13 8.33333H8.33333V13H13V8.33333Z"
                                        stroke="white"
                                        stroke-width="1.07"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M5.66667 8.33333H1V13H5.66667V8.33333Z"
                                        stroke="white"
                                        stroke-width="1.07"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="title">Tích hợp đa kênh</div>
                        </div>
                        <div className="bottom">
                            <div>Website</div>
                            <div>Messenger</div>
                            <div>Viber</div>
                            <div>Telegram</div>
                            <div>Zalo</div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Feature;
