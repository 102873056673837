import React from 'react';
import { FiGlobe, FiMail } from 'react-icons/fi';
import { GrLocation } from 'react-icons/gr';
import '../../assets/css/ebookfooter.scss';
import faceBook from '../../assets/image/icon-fb-2.svg';
import instagram from '../../assets/image/icon-insta-2.svg';
import twitter from '../../assets/image/icon-tw-2.svg';
import linken from '../../assets/image/linken.svg';
import logo from '../../assets/image/Logo.svg';
const FooterEbook = () => {
    return (
        <div
            className="ebookfooter "
            style={{
                background:
                    'linear-gradient(89.82deg, #344ABE -13.49%, #633E9B 21.7%, #B12A61 70.07%, #CD234C 107.14%)',
            }}
        >
            <div
                className="container d-flex justify-content-between footer"
                style={{
                    paddingTop: '45px',
                    paddingBottom: '45px',
                    borderBottom: 'solid rgb(229, 229, 234)',
                }}
            >
                <div className="footer-item" style={{ width: 600, marginTop: '30px' }}>
                    <img className="ebook-footer-logo" src={logo} alt="" />
                    <div className="d-flex flex-column gap-3">
                        <h5
                            className="text-[20px] footer-h5 font-semibold ms-2"
                            style={{ marginTop: 30, color: 'white' }}
                        >
                            Công ty Cổ phần Công nghệ và Truyền thông AIV Group
                        </h5>
                        <div className="d-flex  gap-2 ms-2">
                            <i className="locaticon" style={{ fontSize: '30px', color: 'white' }}>
                                <GrLocation />
                            </i>
                            <p className="p-md text-white-footer" style={{ margin: '0px', marginLeft: '10px' }}>
                                Địa chỉ: Tầng 23 tòa nhà Eurowindow Multi Complex, 27 Trần Duy Hưng,quận Cầu Giấy, T.P
                                Hà Nội
                            </p>
                        </div>
                        <div className="d-flex  gap-2 ms-2">
                            <i className="text-[20px]" style={{ color: 'white', fontSize: '30px' }}>
                                <FiGlobe />
                            </i>
                            <p
                                className="p-md text-white-footer"
                                style={{
                                    margin: '10px 0 0 10px',
                                    color: 'white!important',
                                }}
                            >
                                https://aivgroup.vn
                            </p>
                        </div>
                        <div className="d-flex  gap-2 ms-2">
                            <i className="text-[20px]" style={{ color: 'white', fontSize: '30px' }}>
                                <FiMail />
                            </i>
                            <p
                                className="p-md text-white-footer"
                                style={{
                                    margin: '10px 0 0 10px',
                                    color: 'white!important',
                                }}
                            >
                                infor@aivgroup.vn
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex flex-column gap-3 footer-item"
                    style={{ marginTop: '2.5rem', textAlign: 'center', width: '400px' }}
                >
                    <h3
                        className="footer-h3"
                        style={{
                            color: 'white',
                        }}
                    >
                        Đăng ký tư vấn
                    </h3>
                    <input className="input " type="text" placeholder="Họ và tên" />
                    <input className="input " type="text" placeholder="Email" />
                    <input className="input " type="text" placeholder="Số điện thoại" />
                    <div
                        className="btn mx-auto "
                        style={{
                            width: '150px',
                        }}
                    >
                        Đăng ký
                    </div>
                </div>
            </div>
            <div className="container pt-5 pb-5">
                <div className="row d-flex align-items-center justify-content-between">
                    <div className="col-md-4 col-sm-12 col-lg-5">
                        <ul className=" d-flex gap-5 footer_social" style={{ marginBottom: 0 }}>
                            <li className="pr-40 pr-custom bg-white">
                                <img className="" src={faceBook} alt="" />
                            </li>
                            <li className="">
                                <img className="" src={twitter} alt="" />
                            </li>
                            <li className="pr-40 pr-custom ">
                                <img className="" src={linken} alt="" />
                            </li>
                            <li className="pr-40 pr-custom ">
                                <img className="" src={instagram} alt="" />
                            </li>
                        </ul>
                    </div>
                    <div
                        className="col-lg-7 pc-on mt-un col-md-8 col-sm-12 footer-info"
                        style={{
                            marginBottom: '40px',
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default FooterEbook;
