/* eslint-disable jsx-a11y/alt-text */
import { motion } from 'framer-motion';
import React from 'react';
import image2 from '../../../assets/image/clip-path-group.svg';
import contentImg from '../../../assets/image/content-1.svg';
import { fadeInUpDelay1, fadeInUpDelay2 } from '../../../assets/utils/utils';
const Content = () => {
    return (
        <div
            style={{
                position: 'relative',
                paddingTop: 150,
                paddingBottom: 150,
            }}
            className="custom-pd"
        >
            <object
                style={{
                    position: 'absolute',
                    bottom: 30,
                    right: 0,
                    width: 350,
                    opacity: 0.5,
                    transform: 'scaleX(-1)',
                }}
                className="pc-on "
                type="image/svg+xml"
                data={image2}
            />
            <div
                className="container content row"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div className="col-md-6 ">
                    <motion.div
                        className="img-block mb-40 wow fadeInUp"
                        initial="initial"
                        whileInView="animate"
                        variants={fadeInUpDelay2}
                        viewport={{ once: true }}
                    >
                        <img className="img-fluid" width={500} src={contentImg} alt="info" />
                    </motion.div>
                </div>
                <div className="col-md-6">
                    <motion.div
                        className="txt-block pl-custom pr-custom-larkmail px-5 sm:pl-0 mb-40 wow fadeInUp"
                        initial="initial"
                        whileInView="animate"
                        variants={fadeInUpDelay1}
                        viewport={{ once: true }}
                    >
                        <h2 className="h2-xs mb-20" style={{ textTransform: 'uppercase' }}>
                            Business Mail là Gì ?
                        </h2>
                        <p className="p-md left-xs-md">
                            Business email là email thuộc sở hữu của công ty và được dùng để liên lạc, trao đổi với đối
                            tác và khách hàng. Loại email này có cấu trúc đuôi là tên domain doanh nghiệp. Ví dụ
                            “abc@aivgroup.com” là một email doanh nghiệp.
                        </p>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default Content;
