import { motion } from 'framer-motion';
import React from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import backgroundBanner from '../../../assets/image/background-banner.svg';
import bannerLark from '../../../assets/image/banner-lark.svg';
import { fadeInRight, fadeInUp } from '../../../assets/utils/utils';

const Banner = () => {
    return (
        <section
            id="banner-lark"
            className="bg-scroll hero-section division "
            style={{
                backgroundImage: `url(${backgroundBanner})`,
                backgroundSize: 'cover',
                paddingTop: '140px',
                paddingBottom: '100px',
            }}
        >
            <div
                className="container white-color list-box"
                style={{
                    marginTop: '100px',
                }}
            >
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-xl-6 mb-10 mobile-on">
                        <motion.div
                            className="hero-txt wow fadeInUp "
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <div key="hero-txt" className="hero-txt wow fadeInUp " data-wow-delay="0.3s">
                                <h2 className="h2-xs  mt-sp" style={{ fontSize: '2.625rem', zIndex: 1 }}>
                                    <span className="text-banner">Nền tảng</span> <br />
                                    làm việc số hợp nhất
                                </h2>
                            </div>
                        </motion.div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6">
                        <motion.div
                            className="hero-txt wow fadeInUp banner-mt"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <h2 className="h2-xs pc-on" style={{ fontSize: '2.625rem', zIndex: 1 }}>
                                <span className="text-banner">Nền tảng</span> <br />
                                làm việc số hợp nhất
                            </h2>
                            <p
                                className="p-md-custom pt-30 pt-30-custom px-custom-10"
                                style={{ textAlign: 'justify', paddingTop: '30px' }}
                            >
                                Giải pháp gia tăng hiệu quả giao tiếp và phối hợp trong doanh nghiệp. AIV Group tự hào
                                là đối tác chính thức của Lark tại Việt Nam, trực tiếp phân phối, tư vấn và triển khai
                                Lark cho các doanh nghiệp.
                            </p>
                            <div className="stores-badge d-flex">
                                <a
                                    href="https://www.larksuite.com/paid/partner/aivgroup?utm_source=aivgroup&utm_medium=partner_portal&utm_campaign=2022+APAC+Partner+Campaign&tracking_code=701BB0000009sawYAA"
                                    className="btn mr-4 "
                                    style={{ fontSize: '1rem', zIndex: 1 }}
                                >
                                    Liên hệ tư vấn
                                    <BsArrowRightShort className="icon-arrown" size={25} />
                                </a>
                                <a
                                    href="https://zalo.me/0961160917"
                                    className="btn mr-4 "
                                    style={{ fontSize: '1rem', zIndex: 1 }}
                                >
                                    Đăng kí dùng thử
                                    <BsArrowRightShort className="icon-arrown" size={25} />
                                </a>
                            </div>
                        </motion.div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                        {/* hero-9-img */}
                        <motion.div
                            className="hero-txt wow fadeInUp"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInRight}
                            viewport={{ once: true }}
                        >
                            <div
                                className=" text-center wow fadeInRight"
                                data-wow-duration="1.5s"
                                data-wow-delay="0.6s"
                            >
                                <img className="img-fluid scale" src={bannerLark} alt="hero" />
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
