import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/navbar.css';
import logoRed from '../../assets/image/AIV-logo-red.svg';
import closeIcon from '../../assets/image/charm_chevrons-left.svg';
import logo from '../../assets/image/Logo.svg';

import { GoThreeBars } from 'react-icons/go';
const NavBarBusiness = () => {
    const headerRef = useRef(null);
    const [isLogoRed, setIsLogoRed] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80 || window.innerWidth < 776) {
                headerRef.current && headerRef.current.classList.add('scroll');
                setIsLogoRed(true);
            } else {
                headerRef.current && headerRef.current.classList.remove('scroll');
                setIsLogoRed(false);
            }
        });
        return () => {
            window.removeEventListener('scroll', null);
        };
    }, []);

    useEffect(() => {
        if (window.innerWidth < 776) {
            headerRef.current.classList.add('scroll');
            setIsLogoRed(true);
        } else {
            headerRef.current.classList.remove('scroll');
            setIsLogoRed(false);
        }
    }, []);

    return (
        <header id="recruit-style" className="header" style={{ height: 0 }}>
            <div
                ref={headerRef}
                className="navbar fixed-top navbar-expand-md hover-menu navbar-dark bg-tra white-scroll"
            >
                <div className="container d-flex justify-content-between">
                    <Link to="/" className=" logo-white">
                        <img src={isLogoRed ? logoRed : logo} width={143} height={57} alt="header-logo" />
                    </Link>
                    <label
                        htmlFor="tab-mobile"
                        className="navbar-toggler mobile-on"
                        data-target="#navbarContent"
                        aria-controls="navbarContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        style={{ width: '30px' }}
                    >
                        <span className="navbar-bar-icon">
                            <GoThreeBars />
                        </span>
                    </label>
                    <div style={{ width: '70%' }}>
                        <div
                            id="navbarContent"
                            className="collapse pc-on navbar-collapse text-white"
                            style={{ width: '100%' }}
                        >
                            <ul
                                className="navbar-nav ml-auto "
                                style={{
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <li className="nav-item nl-simple">
                                    <div
                                        className="nav-div"
                                        style={{
                                            color: isLogoRed && '#DA2043',
                                            fontWeight: 400,
                                            fontSize: '18px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Tính năng
                                    </div>
                                </li>
                                <li className="nav-item nl-simple">
                                    <div
                                        className="nav-div"
                                        style={{
                                            color: isLogoRed && '#DA2043',
                                            fontWeight: 400,
                                            fontSize: '18px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Giải pháp{' '}
                                    </div>
                                </li>
                                <li className="nav-item nl-simple">
                                    <div
                                        className="nav-div"
                                        style={{
                                            color: isLogoRed && '#DA2043',
                                            fontWeight: 400,
                                            fontSize: '18px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Hướng dẫn sử dụng
                                    </div>
                                </li>
                                <li className="nav-item nl-simple">
                                    <div
                                        className="nav-div"
                                        style={{
                                            color: isLogoRed && '#DA2043',
                                            fontWeight: 400,
                                            fontSize: '18px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Biểu phí
                                    </div>
                                </li>
                                <li className="nav-item nl-simple">
                                    <div
                                        className="nav-div"
                                        style={{
                                            color: isLogoRed && '#DA2043',
                                            fontWeight: 400,
                                            fontSize: '18px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Blog
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <input type="checkbox" hidden id="tab-mobile" className="nav-input-mobile" />
            {/* overlay */}
            <label htmlFor="tab-mobile" className="overlay" />
            {/* nav mobile */}

            <nav className="mobile-menu mobile-on navbar  navbar-expand-md hover-menu navbar-dark">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item nl-simple d-flex">
                        <div className="new-mt" to={`/about_us`}>
                            Tính năng
                        </div>
                        <label htmlFor="tab-mobile" className="close-tab-moblie">
                            <img src={closeIcon} style={{ cursor: 'pointer', height: '48px', zIndex: 5 }} alt="" />
                        </label>
                    </li>
                    <li className="nav-item nl-simple d-flex">
                        <div className="new-mt" to={`/about_us`}>
                            Giải pháp
                        </div>
                    </li>
                    <li className="nav-item nl-simple d-flex">
                        <div className="new-mt" to={`/about_us`}>
                            Hướng dẫn sử dụng
                        </div>
                    </li>
                    <li className="nav-item nl-simple d-flex">
                        <div className="new-mt" to={`/about_us`}>
                            Biểu phí
                        </div>
                    </li>
                    <li className="nav-item nl-simple d-flex">
                        <div className="new-mt" to={`/about_us`}>
                            Blog
                        </div>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default NavBarBusiness;
