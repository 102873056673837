import { motion } from 'framer-motion';
import React from 'react';
import reasonImg1 from '../../../assets/image/reason-1.svg';
import reasonImg2 from '../../../assets/image/reason-2.svg';
import { fadeInUp } from '../../../assets/utils/utils';
import SlickSlider from '../../../components/slickSlider/SlickSlider';
const Reason = () => {
    return (
        <div className="container  " style={{ paddingTop: 90, paddingBottom: 90 }}>
            <h2 className="h2-xs">VÌ SAO NÊN LỰA CHỌN</h2>
            <div className="pc-on">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2rem',
                    }}
                >
                    <motion.div
                        className="d-flex justify-content-between"
                        initial="initial"
                        whileInView="animate"
                        variants={{
                            ...fadeInUp,
                            animate: {
                                transform: ' translate3d(0,0,0)',
                                opacity: 1,
                                transition: {
                                    duration: 1.25,
                                    ease: 'easeInOut',
                                    delay: 0.3,
                                },
                            },
                        }}
                        viewport={{ once: true }}
                    >
                        <div
                            className="max-w-[500px] reason "
                            style={{
                                maxWidth: 580,
                                paddingTop: 0,
                            }}
                        >
                            <p className="p-md font-400" style={{ paddingBottom: 10 }}>
                                Tư vấn - Hỗ trợ doanh nghiệp sử dụng 50 Business Mail<br></br> với giá 0đ của AIV Group
                            </p>
                            <p className="p-md" style={{ textAlign: 'justify' }}>
                                AIV Group là một trong số những đơn vị đạt chuẩn partner đầu tiên của Lark tại miền Bắc
                                Việt Nam - Bộ công cụ làm việc số đến từ kì lân công nghệ thế giới ByteDance (công ty mẹ
                                của TikTok).
                                <br></br>
                                <br></br>
                                Từ đó, chúng tôi hiểu rõ các giá trị mà Lark Business Email mang lại, cũng như thành
                                thục trong việc giúp các doanh nghiệp chuyển đổi hệ thống Business Email từ Gsuite và
                                các hệ thống khác về Lark, nhằm tận dụng những ưu đãi hào phóng trong gói sản phẩm Lark
                                Basic Plan dành cho người dùng tại Việt Nam.
                            </p>
                        </div>
                        <img className="img-reason" src={reasonImg1} alt="" />
                    </motion.div>
                    <motion.div
                        className="d-flex justify-content-between flex-row-reverse"
                        initial="initial"
                        whileInView="animate"
                        variants={{
                            ...fadeInUp,
                            animate: {
                                transform: ' translate3d(0,0,0)',
                                opacity: 1,
                                transition: {
                                    duration: 1.25,
                                    ease: 'easeInOut',
                                    delay: 0.3,
                                },
                            },
                        }}
                        viewport={{ once: true }}
                    >
                        <div
                            className="max-w-[500px]"
                            style={{
                                maxWidth: 580,
                                paddingTop: 30,
                            }}
                        >
                            <p className="p-md mt-4" style={{ textAlign: 'justify' }}>
                                Hợp tác cùng AIV Group cũng bảo đảm doanh nghiệp có thể khai thác tốt không chỉ tính
                                năng Email, mà còn có đối tác tin cậy để triển khai những tính năng hữu ích khác trong
                                bộ công cụ Lark như Lark Messenger, Lark Meeting, Lark Task, Lark Wiki, Lark Docs,
                                Bitable, Approval, Lark OKR…nhằm giúp doanh nghiệp hoạt động hiệu quả và tối ưu.
                            </p>
                        </div>
                        <img className="img-reason" src={reasonImg2} alt="" />
                    </motion.div>
                </div>
            </div>
            <div className="mobile-on slider">
                <SlickSlider>
                    <motion.div
                        className="reason-item"
                        initial="initial"
                        whileInView="animate"
                        variants={{
                            ...fadeInUp,
                            animate: {
                                transform: ' translate3d(0,0,0)',
                                opacity: 1,
                                transition: {
                                    duration: 1.25,
                                    ease: 'easeInOut',
                                    delay: 0.3,
                                },
                            },
                        }}
                        viewport={{ once: true }}
                    >
                        <div
                            className="max-w-[500px] reason reason-pt-top"
                            style={{
                                maxWidth: 500,
                            }}
                        >
                            <p className="p-md" style={{ textAlign: 'justify' }}>
                                Hợp tác cùng AIV Group cũng bảo đảm doanh nghiệp có thể khai thác tốt không chỉ tính
                                năng Email, mà còn có đối tác tin cậy để triển khai những tính năng hữu ích khác trong
                                bộ công cụ Lark như Lark Messenger, Lark Meeting, Lark Task, Lark Wiki, Lark Docs,
                                Bitable, Approval, Lark OKR…nhằm giúp doanh nghiệp hoạt động hiệu quả và tối ưu. 
                            </p>
                        </div>
                        <img className="img-reason" src={reasonImg1} alt="" />
                    </motion.div>
                    <motion.div
                        className="reason-item"
                        initial="initial"
                        whileInView="animate"
                        variants={{
                            ...fadeInUp,
                            animate: {
                                transform: ' translate3d(0,0,0)',
                                opacity: 1,
                                transition: {
                                    duration: 1.25,
                                    ease: 'easeInOut',
                                    delay: 0.3,
                                },
                            },
                        }}
                        viewport={{ once: true }}
                    >
                        <div
                            className="max-w-[500px] reason-pt-top"
                            style={{
                                maxWidth: 500,
                            }}
                        >
                            <h5 style={{ paddingBottom: 10 }}>
                                Tư vấn - hỗ trợ doanh nghiệp sử dụng 50 Business Mail với giá 0đ của AivGroup
                            </h5>
                            <p className="p-md">
                                AIV Group là một trong số những đơn vị đạt chuẩn partner đầu tiên của Lark tại miền Bắc
                                Việt Nam - Bộ công cụ làm việc số đến từ kì lân công nghệ thế giới ByteDance (công ty mẹ
                                của TikTok).
                                <br></br>
                                <br></br>
                                Từ đó, chúng tôi hiểu rõ các giá trị mà Lark Business Email mang lại, cũng như thành
                                thục trong việc giúp các doanh nghiệp chuyển đổi hệ thống Business Email từ Gsuite và
                                các hệ thống khác về Lark, nhằm tận dụng những ưu đãi hào phóng trong gói sản phẩm Lark
                                Basic Plan dành cho người dùng tại Việt Nam.
                            </p>
                        </div>
                        <img className="img-reason" src={reasonImg2} alt="" />
                    </motion.div>
                </SlickSlider>
            </div>
        </div>
    );
};

export default Reason;
