import { motion } from 'framer-motion';
import React from 'react';
import icon7 from '../../../assets/icon/Approval.svg';
import icon8 from '../../../assets/icon/Base.svg';
import icon5 from '../../../assets/icon/Calendar.svg';
import icon2 from '../../../assets/icon/Docs.svg';
import icon6 from '../../../assets/icon/Email.svg';
import icon3 from '../../../assets/icon/Meetings.svg';
import icon4 from '../../../assets/icon/Minutes.svg';
import icon10 from '../../../assets/icon/OKR.svg';
import icon11 from '../../../assets/icon/Oprn-Platform.svg';
import icon9 from '../../../assets/icon/Wiki.svg';
import icon1 from '../../../assets/icon/messenger.svg';
import { fadeInLeft } from '../../../assets/utils/utils';
import TextCustomer from './common/textCustomer';

const mocdata = [
    {
        icon: icon1,
        name: 'Messenger',
    },
    {
        icon: icon2,
        name: 'Docs',
    },
    {
        icon: icon3,
        name: 'Meetings',
    },
    {
        icon: icon4,
        name: 'Minutes',
    },
    {
        icon: icon5,
        name: 'Calendar',
    },
    {
        icon: icon6,
        name: 'Email',
    },
    {
        icon: icon7,
        name: 'Approval',
    },
    {
        icon: icon8,
        name: 'Base',
    },
    {
        icon: icon9,
        name: 'Wiki',
    },
    {
        icon: icon10,
        name: 'OKR',
    },
    {
        icon: icon11,
        name: 'Oprn Platform',
    },
];

const Integration = () => {
    return (
        <div id="integration" className="container">
            <motion.div
                className="hero-txt wow fadeInUp col white-color"
                initial="initial"
                whileInView="animate"
                variants={fadeInLeft}
                viewport={{ once: true }}
                style={{
                    marginTop: '100px',
                    marginBottom: '50px',
                }}
            >
                <TextCustomer title="Tất cả các ứng dụng bạn cần" />
                <p
                    className="p-md-custom pt-30 pt-30-custom px-custom-10"
                    style={{ textAlign: 'center', paddingTop: '30px' }}
                >
                    Giải pháp hoàn hảo giúp doanh nghiệp tổ chức công việc, tự động hóa quy trình, thúc đẩy doanh thu và
                    hơn thế nữa
                </p>
            </motion.div>
            <motion.div
                className="hero-txt wow fadeInUp row"
                initial="initial"
                whileInView="animate"
                variants={fadeInLeft}
                viewport={{ once: true }}
            >
                {mocdata.map((item, index) => (
                    <div key={index} className="col px-1 box-Integration">
                        <div className="flex-image">
                            <img src={item.icon} alt="icon" />
                        </div>
                        <p className="">{item.name}</p>
                    </div>
                ))}
            </motion.div>
            <div className="d-flex justify-content-center mt-5">
                <a
                    href="https://workbetter.vn/huong-dan-su-dung-lark/"
                    className="btn mr-4 "
                    style={{ fontSize: '1rem', zIndex: 1 }}
                >
                    Hướng dẫn triển khai từ A -&gt; Z
                </a>
            </div>
        </div>
    );
};

export default Integration;
