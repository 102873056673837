const Banner = () => {
  return (
    <>
      <section
        id="banner-giai-phap"
        className="bg-scroll hero-section division"
      >
        <div className="container white-color mb-100 mt-100"></div>
      </section>
    </>
  );
};

export default Banner;
