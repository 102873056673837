import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { IoIosRemove } from 'react-icons/io';
import { IoAddSharp } from 'react-icons/io5';
import { fadeInDown, fadeInUpDelay1 } from '../../../assets/utils/utils';

const mockData = [
    {
        id: 1,
        title: 'Mindmaid là gì ?',
        desc: 'Nền tảng trợ lý ảo Mindmaid là nền tảng cho phép các cá nhân, doanh nghiệp, tổ chức nhanh chóng tạo ra các trợ lý ảo thế hệ mới và khai thác công nghệ trợ lý ảo để cải tiến quy trình vận hành, sáng tạo mô hình kinh doanh mới.',
    },
    {
        id: 2,
        title: 'Trợ lý ảo là gì?',
        desc: 'Intelligent Virtual Assistant (Trợ lý ảo thông mình) là một trợ lý ảo được hỗ trợ bởi trí tuệ nhân tạo (AI) có khả năng tạo ra các phản hồi cá nhân hóa bằng cách kết hợp phân tích dữ liệu và điện toán nhận thức (cognitive computing) dựa trên cuộc trò chuyện trước đây và dữ liệu được huấn luyện.',
    },
    {
        id: 3,
        title: 'Ưu điểm của Trợ lý ảo thế hệ mới được tạo bởi Mindmaid?',
        desc: [
            {
                title: 'Khả năng hiểu tốt tiếng Việt: bao gồm cả hiểu câu hỏi (xử lý từ viết tắt, từ đồng nghĩa...) lẫn câu trả lời (trả lời mượt mà, sát nghĩa câu hỏi)',
            },
            {
                title: 'Huấn luyện thuận tiện, dễ dàng, nhanh chóng: nhân viên bình thường cũng có thể tự tạo Trợ lý ảo mà không cần bất cứ hỗ trợ nào từ dev',
            },
            {
                title: 'Khả năng nhập vai: giúp Trợ lý ảo có tính cách, có thể trả lời người hỏi một cách sinh động, giúp trải nghiệm hỏi-đáp không chỉ hữu ích mà còn có tính giải trí, thu hút người dùng ở lại lâu.',
            },
            {
                title: 'Hỏi ngoài dữ liệu huấn luyện: Trợ lý ảo có thể bật/tắt chế độ sử dụng như ChatGPT thông thường, giúp người hỏi thực hiện các yêu cầu tương tự với ChatGPT',
            },
            { title: 'Nhiều tính năng cao cấp: gợi ý câu hỏi, câu hỏi follow-up, tự động hoàn thiện câu hỏi.' },
        ],
    },
    {
        id: 4,
        title: 'Làm sao để tạo Trợ lý ảo Mindmaid ?',
        desc: 'Để tạo trợ lý ảo Mindmaid, mời bạn truy cập link sau: https://mindmaid.ai/và làm theo hướng dẫn tại đây.',
    },
    {
        id: 5,
        title: 'Chi phí sử dụng Mindmaid như thế nào?',
        desc: 'Mindmaid cung cấp gói Basic luôn miễn phí, không giới hạn thời gian. Cho phép doanh nghiệp tự sử dụng tài khoản ChatGPT của mình để tối ưu chi phí. Ngoài ra Mindmaid còn cung cấp các gói trả phí tùy theo nhu cầu của doanh nghiệp, mời bạn tham khảo tại đây.',
    },
];

const AnswerQuestion = () => {
    const [dropOpen, setDropOpen] = useState(null);
    const handleClick = (id) => {
        setDropOpen(id);
    };

    return (
        <div className="question">
            <div className="container">
                <motion.h3
                    initial="initial"
                    className="title"
                    whileInView="animate"
                    variants={fadeInDown}
                    viewport={{ once: true }}
                >
                    Câu hỏi thường gặp
                </motion.h3>
                <div className="content">
                    {mockData.map((item, index) => (
                        <motion.div
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUpDelay1}
                            viewport={{ once: true }}
                            key={index}
                            className="content-item"
                        >
                            <div
                                className="top"
                                onClick={() => (dropOpen === item.id ? setDropOpen('') : handleClick(item.id))}
                            >
                                <p className="content-title">{item.title}</p>
                                <div className="icon">
                                    {dropOpen === item.id ? <IoIosRemove size={25} /> : <IoAddSharp size={25} />}
                                </div>
                            </div>
                            <div className={`bottom ${dropOpen === item.id && 'active'}`}>
                                {item.desc !== null && item.desc.constructor === Array ? (
                                    <ul className="pl-5" style={{ listStyle: 'auto' }}>
                                        {item.desc.map((i, index) => (
                                            <li key={index}>{i.title}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>{item.desc}</p>
                                )}
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AnswerQuestion;
