import React from 'react';

const BookVer2 = ({ color = '#da2043' }) => {
    return (
        <svg width="38" height="49" viewBox="0 0 38 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.8871 0.844604H2.58665C1.42663 0.844604 0.483765 1.74095 0.483765 2.84332V43.5026C0.483765 46.0445 2.65808 48.1127 5.32955 48.1127H35.9071C37.0557 48.1127 37.99 47.2164 37.99 46.114V2.84332C37.99 1.74095 37.0471 0.844604 35.8871 0.844604ZM2.76951 38.6409V3.01456H35.7043V37.5707H6.14384C4.92954 37.5707 3.74381 37.9506 2.76951 38.6409ZM6.14384 39.7433H35.7071L35.7043 45.9428H5.9067C4.1781 45.9428 2.76951 44.6023 2.76951 42.9567C2.76951 41.1828 4.28382 39.7433 6.14384 39.7433Z"
                fill={color}
            />
            <path
                d="M26.287 23.1034L24.6898 21.5836L20.6555 25.474L20.5526 25.4312V12.5747H18.0812V25.3563L14.0411 21.5034L12.184 23.1569L19.2012 29.7069L26.287 23.1034Z"
                fill={color}
            />
        </svg>
    );
};

export default BookVer2;
