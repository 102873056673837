import { motion } from 'framer-motion';
import React from 'react';
import Book from '../../../assets/icon/Book';
import BookVer2 from '../../../assets/icon/BookVer2';
import Hand from '../../../assets/icon/Hand';
import HandVer2 from '../../../assets/icon/HandVer2';
import { fadeInUp } from '../../../assets/utils/utils';
const Benefit = () => {
    const options = [
        {
            img: <Book></Book>,
            title: 'Truy cập hàng nghìn cuốn sách trên một thiết bị',
            description:
                'Bạn không phải lo lắng về giới hạn lưu trữ, chỉ cần một thiết bị duy nhất là đủ để đọc bất kỳ số lượng sách nào bạn muốn',
        },
        {
            img: <BookVer2></BookVer2>,
            title: (
                <div>
                    Tùy chọn thân thiện với<br className="pc-on"></br> môi trường
                </div>
            ),
            description:
                'Sách điện tử thân thiện với môi trường. Giảm thiểu khoảng 2,2 triệu cuốn sách giấy được xuất bản mỗi năm, tương đương khoảng 3 triệu cây xanh',
        },
        {
            img: <Hand></Hand>,
            title: 'Truy cập mọi nơi',
            description: 'Sách điện tử có thể được tải xuống và lưu trữ để sử dụng bất kỳ khi nào bạn muốn',
        },
        {
            img: <HandVer2></HandVer2>,
            title: 'Tiết kiệm chi phí hơn',
            description: 'Không bìa mềm, không giấy in, không chi phí đóng gói, không vận chuyển',
        },
    ];
    return (
        <div
            className="container benefit-ebook"
            style={{
                paddingBottom: '100px',
                paddingTop: '100px',
            }}
        >
            <h2 className="h2-custom-ebook" style={{ textAlign: 'center', marginBottom: '50px' }}>
                Lợi ích của điện tử sách
            </h2>
            <div className="benefit-box">
                {options.map((item) => (
                    <motion.div
                        className="box-item"
                        initial="initial"
                        whileInView="animate"
                        key={item.description}
                        variants={fadeInUp}
                        viewport={{ once: true }}
                    >
                        <div className="box-img" data-wow-delay="0.3s">
                            {item.img}
                        </div>
                        <div>
                            <h5 className="h5-sm" style={{ color: '#222' }}>
                                {item.title}
                            </h5>
                        </div>
                        <p className="p-md"> {item.description}</p>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default Benefit;
