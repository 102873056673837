import { motion } from 'framer-motion';
import { fadeInUp, fadeInUpDelay1, fadeInUpDelay2 } from '../../assets/utils/utils';
import { imageReducer } from '../../components/utils';
const TamNhinXuMenh = ({ dataVision }) => {
    return (
        <>
            <section id="bg-ve-chung-toi" className="wide-100 bg-white pb-0 features-section division  ">
                <div className="container ">
                    <div className="row ">
                        <motion.div
                            className="col-lg-10 offset-lg-1 section-title wow fadeInUp"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <h3
                                className="h3-lg un-mb- mb-35 text-tranfromss"
                                style={{ fontWeight: 700, fontSize: '2.5rem' }}
                            >
                                Tầm nhìn và sứ mệnh
                            </h3>
                            <div className="gach-tran" />
                        </motion.div>
                    </div>
                </div>

                <div className="container mt-100">
                    {dataVision.map((item) => (
                        <div
                            className="row d-flex align-items-center"
                            style={{
                                flexDirection: item.id % 2 === 0 && 'row-reverse',
                            }}
                            key={item.id}
                        >
                            <div className="col-md-6 d-block none">
                                <motion.div
                                    className="img-block mb-40 wow fadeInUp"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay2}
                                    viewport={{ once: true }}
                                >
                                    <img
                                        className="img-fluid"
                                        src={imageReducer(item?.attributes?.img.data[0]?.attributes?.url)?.url}
                                        alt="info"
                                    />
                                </motion.div>
                            </div>
                            <div className="col-md-6">
                                <motion.div
                                    className="txt-block pl-custom pl-35 mb-40 wow fadeInUp"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay1}
                                    viewport={{ once: true }}
                                >
                                    <h3 className="h3-lg" style={{ fontSize: '2.25rem' }}>
                                        {item.attributes.title}
                                    </h3>
                                    <div className="gach-tran-2 mb-35" />
                                    <p className="p-md">{item.attributes.description}</p>
                                </motion.div>
                            </div>
                            <div className="col-md-6 d-none">
                                <motion.div
                                    className="img-block mb-40 wow fadeInUp animated"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay2}
                                    viewport={{ once: true }}
                                >
                                    <img
                                        className="img-fluid"
                                        src={imageReducer(item?.attributes?.img.data[0]?.attributes?.url).url}
                                        alt="info"
                                    />
                                </motion.div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
};

export default TamNhinXuMenh;
