import Banners from "./banner";
import Option from "./boxOption";
import Outstanding from "./BoxOutstanding";
import Price from "./BoxPrice";
import "./style.scss";
import imageVd from "../../assets/image/img-product-32.png";
import imagevd2 from "../../assets/image/img-product-3.png";
import icon1 from "../../assets/image/bill-1.png";
import icon2 from "../../assets/image/diamond.png";

export default function AutoVid() {
  return (
    <div id="autovid-style">
      <Banners />
      <Outstanding imageVd={imageVd} />
      <Option imagevd2={imagevd2} />
      <Price icon1={icon1} icon2={icon2} />
    </div>
  );
}
