import React from 'react';
import { FiGlobe, FiMail, FiPhoneCall } from 'react-icons/fi';
import '../../assets/css/business.scss';
import faceBook from '../../assets/image/icon-f.svg';
import instagram from '../../assets/image/icon-insta.svg';
import twitter from '../../assets/image/icon-tw.svg';
import linken from '../../assets/image/linken.svg';
import logo from '../../assets/image/logo-red.png';
import logolocation from '../../assets/image/map-pin.svg';
const FooterBusiness = () => {
    return (
        <div
            className="bg-[#ECECF7] business"
            style={{
                background: '#ECECF7',
            }}
        >
            <div
                className="container d-flex justify-content-between footer"
                style={{
                    paddingTop: '45px',
                    paddingBottom: '45px',
                }}
            >
                <div className="custom-footer-left-w-100" style={{ width: 600 }}>
                    <img className="footer-logo" height={100} src={logo} alt="" />
                    <h5
                        className="text-[20px] font-semibold"
                        style={{ marginTop: 30, color: '#000000!important', marginBottom: 30 }}
                    >
                        Công ty Cổ phần Công nghệ và Truyền thông AIV Group
                    </h5>
                    <div className="d-flex flex-column gap-4">
                        <div className="d-flex  gap-2">
                            <i className="pe-2 d-flex">
                                <img src={logolocation} alt="" style={{ width: '25px' }} />
                            </i>
                            <p className="p-md fw-bolder text-black " style={{ margin: 0 }}>
                                Địa chỉ: Tầng 23 tòa nhà Eurowindow Multi Complex,<br className="pc-on"></br> 27 Trần
                                Duy Hưng, Quận Cầu Giấy, T.P Hà Nội
                            </p>
                        </div>
                        <div className="d-flex  gap-2 ">
                            <FiPhoneCall style={{ fontSize: '25px', marginRight: '6.5px' }} />
                            <p className="p-md fw-bolder text-black" style={{ margin: 0 }}>
                                0931 458 189
                            </p>
                        </div>
                        <div className="d-flex  gap-2 ">
                            <FiGlobe style={{ fontSize: '25px', marginRight: '6.5px' }} />
                            <p className="p-md fw-bolder text-black" style={{ margin: 0 }}>
                                https://aivgroup.vn
                            </p>
                        </div>
                        <div className="d-flex  gap-2 ">
                            <FiMail style={{ fontSize: '25px', marginRight: '6.5px' }} />
                            <div className="p-md fw-bolder text-black" style={{ margin: 0 }}>
                                info@aivgroup.vn
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex flex-column gap-3 footer-item"
                    style={{ marginTop: '2.5rem', textAlign: 'center', width: '400px' }}
                >
                    <h3
                        className="h3-custom-mobile"
                        style={{
                            color: '#D41933',
                        }}
                    >
                        Đăng ký tư vấn
                    </h3>
                    <input className="input " type="text" placeholder="Họ và tên" />
                    <input className="input " type="text" placeholder="Email" />
                    <input className="input " type="text" placeholder="Số điện thoại" />
                    <div
                        className="btn mx-auto text-white"
                        style={{
                            width: '150px',
                            background: '#DA2043',
                        }}
                    >
                        Đăng ký
                    </div>
                </div>
            </div>
            <div
                className="container"
                style={{
                    paddingTop: 40,
                    paddingBottom: 40,
                }}
            >
                <div className="row d-flex align-items-center justify-content-between">
                    <div className="col-md-4 col-sm-12 col-lg-5">
                        <ul
                            className=" d-flex gap-5 footer_social"
                            style={{
                                margin: 0,
                                alignItems: 'center',
                            }}
                        >
                            <li className="pr-40 pr-custom">
                                <img src={faceBook} alt="" />
                            </li>
                            <li className="">
                                <img src={twitter} alt="" />
                            </li>
                            <li className="pr-40 pr-custom ">
                                <img src={linken} alt="" />
                            </li>
                            <li className="pr-40 pr-custom">
                                <img src={instagram} alt="" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterBusiness;
