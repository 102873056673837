import React from 'react';

import { motion } from 'framer-motion';
import icon1 from '../../../assets/image/mindmaid-icon01.svg';
import icon2 from '../../../assets/image/mindmaid-icon02.svg';
import icon3 from '../../../assets/image/mindmaid-icon03.svg';
import icon4 from '../../../assets/image/mindmaid-icon04.svg';
import icon5 from '../../../assets/image/mindmaid-icon05.svg';
import { fadeInUp } from '../../../assets/utils/utils';
import SlickSlider from '../../../components/slickSlider/SlickSlider';

const Benefit = () => {
    const arr = [
        {
            title: 'Dễ dàng xây dựng chatbot có tính cách',
            icon: icon1,
            content: [
                'Cho phép chatbot nhập vai nhân vật có tính cách, phong thái cụ thể',
                'Điều khiển chatbot bằng ngôn ngữ tự nhiên đơn giản, dễ dùng',
                'Huấn luyện chatbot dễ dàng từ nhiều định dạng dữ liệu',
            ],
        },
        {
            title: 'Tích hợp vào mọi nền tảng phổ biến',
            icon: icon2,
            content: [
                'Hỗ trợ: Website, Fanpage, Viber, Telegram, Zalo, QRCode',
                'Tích hợp dễ dàng, nhanh chóng ngay trên platform',
                ' Có tích hợp qua API (tính năng business)',
            ],
        },
        {
            title: 'Tính năng cao cấp giúp nâng cao trải nghiệm',
            icon: icon3,
            content: [
                'Khả năng hiểu từ viết tắt, câu hỏi đồng nghĩa',
                'Tính năng câu hỏi follow-up &  autocomplete',
                'Chế độ voice chat',
            ],
        },
        {
            title: 'Phân tích dữ liệu & <br />Tự động hoá',
            icon: icon4,
            content: [
                'Tích hợp dashboard phân tích, thống kê',
                'Tự động nhận diện thông tin khách hàng để lại và đẩy vào CRM',
                'Thiết lập automation để kết nối chatbot vào luồng tự động hoá của doanh nghiệp',
            ],
        },
        {
            title: 'Nắm bắt cơ hội kinh doanh mới',
            icon: icon5,
            content: [
                'Giải quyết mọi bài toán về chatbot, giúp doanh nghiệp nắm bắt cơ hội kinh doanh mới',
                'Hỗ trợ function calling giúp chatbot kết nối dữ liệu bên ngoài và thực hiện được nhiều tác vụ phức tạp',
                '  Hỗ trợ đa ngôn ngữ, giúp doanh nghiệp tiếp cận thị trường toàn cầu',
            ],
        },
    ];

    const setting = {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,

                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="container">
            <motion.div
                initial="initial"
                whileInView="animate"
                variants={fadeInUp}
                viewport={{ once: true }}
                className="benefit"
            >
                <h1 className="">Lợi ích nổi bật</h1>

                <div className="benefit-content">
                    <SlickSlider settings={setting}>
                        {arr.map((item, index) => (
                            <div className="benefit-item" key={index}>
                                <img src={item.icon} className="benefit-icon" alt="illustration" />
                                <h3 style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: item.title }} />
                                <ul className="content-item">
                                    {item.content.map((i, number) => (
                                        <li key={number}>{i}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </SlickSlider>
                </div>
            </motion.div>
        </div>
    );
};

export default Benefit;
