import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { fetchAPI } from '../../api';
import { fadeInUp, fadeInUpDelay1 } from '../../assets/utils/utils';
import { imageReducer } from '../../components/utils';
export default function BoxDiscover() {
    const [dataRecruit, setDataRecruit] = useState([]);
    useEffect(() => {
        fetchAPI('/recruitment-types').then((res) => setDataRecruit(res.data));
    }, []);
    return (
        <section id="features-1" className=" features-section division wide-100 mt-custom- pt-custom- mt-50">
            <div className="container">
                <div className="row">
                    <motion.div
                        className="col-lg-10 offset-lg-1 section-title  wow fadeInUp"
                        initial="initial"
                        whileInView="animate"
                        variants={fadeInUp}
                        viewport={{ once: true }}
                    >
                        <h3 className="h3-lg mb-35 text-tranfromss" style={{ fontWeight: 700, fontSize: '2.5rem' }}>
                            Khám phá các cơ hội
                        </h3>
                        <div className="gach-tran" />
                    </motion.div>
                </div>

                <div className="row" style={{ rowGap: '2rem' }}>
                    {dataRecruit.map((item) => (
                        <motion.div
                            className="col-md-6 col-lg-4"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUpDelay1}
                            viewport={{ once: true }}
                            key={item.id}
                        >
                            <div className="fbox-1 center bg-white box-home p-4 pb-5 icon-md wow fadeInUp text-left">
                                <div className="pb-1" data-wow-delay="0.3s">
                                    <img
                                        className="img-90"
                                        src={imageReducer(item?.attributes.img.data[0]?.attributes?.url)?.url}
                                        alt="discovery"
                                    />
                                </div>
                                <div className="mb-15">
                                    <h5 className="h5-sm"> {item.attributes.title}</h5>
                                </div>
                                <p className="p-md">{item.attributes.description}</p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
}
