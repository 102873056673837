import { motion } from 'framer-motion';
import { fadeInUpDelay1 } from '../../assets/utils/utils';
export default function Banners() {
    return (
        <section id="banner-san-xuat-one" className="bg-scroll hero-section division">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-6 col-xl-6"></div>
                    <div className="col-md-6 col-xl-6 offset-xl-0">
                        <motion.div
                            className="hero-txt mb-40 wow fadeInUp mt-100"
                            data-wow-delay="0.3s"
                            style={{
                                visibility: 'visible',
                                animationDelay: '0.3s',
                                animationName: 'fadeInUp',
                            }}
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUpDelay1}
                            viewport={{ once: true }}
                        >
                            <h3 className="h3-xl" style={{ color: 'white' }}>
                                Phần mềm sản xuất và đăng tải video tự động từ nội dung văn bản
                            </h3>
                            <p className="p-md grey-color pt-10" style={{ color: 'white' }}>
                                Sản xuất nhanh chóng hàng loạt video theo các template cho trước từ nội dung văn bản và
                                hình ảnh
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
}
