import 'animate.css/animate.css';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { fetchAPI } from '../../api';
import banner from '../../assets/image/banners.svg';
import Communication from '../../assets/image/Comunication.svg';
import { fadeInRight, fadeInUp } from '../../assets/utils/utils';

const Section = () => {
    const [bannerData, setBannerData] = useState([]);

    useEffect(() => {
        fetchAPI('/home-banners').then((res) => setBannerData(res.data[0].attributes));
    }, []);

    return (
        <section
            id="banner-home"
            className="bg-scroll hero-section division "
            style={{
                backgroundImage: `url(${banner})`,
                backgroundSize: 'cover',
                paddingTop: '100px',
                paddingBottom: '340px',
            }}
        >
            <div
                className="container white-color "
                style={{
                    marginTop: '100px',
                }}
            >
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-xl-6 mb-10 mobile-on">
                        <motion.div
                            className="hero-txt wow fadeInUp"
                            style={{
                                marginTop: '70px',
                            }}
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <div key="hero-txt" className="hero-txt wow fadeInUp " data-wow-delay="0.3s">
                                <h2 className="h2-xs mt-sp" style={{ fontSize: '2.625rem' }}>
                                    Giải quyết các bài toán thú vị dựa trên tư duy phân tích{' '}
                                </h2>
                            </div>
                        </motion.div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                        {/* hero-9-img */}
                        <motion.div
                            className="hero-txt wow fadeInUp"
                            style={{
                                marginTop: '20px',
                            }}
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInRight}
                            viewport={{ once: true }}
                        >
                            <div
                                className=" text-center wow fadeInRight"
                                data-wow-duration="1.5s"
                                data-wow-delay="0.6s"
                            >
                                <img className="img-fluid " src={Communication} alt="hero" />
                            </div>
                        </motion.div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6">
                        <motion.div
                            className="hero-txt wow fadeInUp"
                            style={{
                                marginTop: '70px',
                            }}
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <h2 className="h2-xs  pc-on" style={{ fontSize: '2.625rem', zIndex: 1 }}>
                                {bannerData?.title}
                            </h2>
                            <p
                                className="p-md-custom pt-30 pt-30-custom px-custom-10"
                                style={{ textAlign: 'justify', paddingTop: '30px' }}
                            >
                                {bannerData?.description}
                            </p>
                            <div className="stores-badge">
                                <a
                                    href={bannerData?.href || '# chưa có link dẫn'}
                                    className="btn btn-white black-hover mr-10 font-weight-bold"
                                    style={{ fontSize: '1.375rem', zIndex: 1 }}
                                >
                                    Tìm hiểu thêm
                                </a>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section;
