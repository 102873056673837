/* eslint-disable jsx-a11y/img-redundant-alt */
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { fetchAPI } from '../../api';
import { fadeInUp } from '../../assets/utils/utils';
import { imageReducer } from '../../components/utils';
const Solutions = () => {
    const [dataSolutions, setDataSolutions] = useState([]);
    useEffect(() => {
        fetchAPI('/solution-banners').then((res) => setDataSolutions(res.data));
    }, []);

    return (
        <>
            <section
                id="bg-ve-chung-toi"
                className="wide-100 bg-scroll hero-section bg-white pb-0 features-section division  "
            >
                <div className="container pc-on">
                    <div className="row ">
                        <motion.div
                            className="col-lg-10 offset-lg-1 section-title wow fadeInUp"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <h3
                                className="h3-lg mb-35 text-tranfromss font-title"
                                style={{ fontWeight: 700, fontSize: '2.5rem' }}
                            >
                                Giải pháp
                            </h3>
                            <div className="gach-tran" />
                        </motion.div>
                    </div>
                    {dataSolutions.map((item, index) => (
                        <div
                            className="row d-flex align-items-center"
                            key={item.id}
                            style={{
                                flexDirection: index % 2 === 0 && 'row-reverse',
                            }}
                        >
                            <div className="col-md-6 d-block">
                                <motion.div
                                    className="img-block mb-40 wow fadeInUp"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={{
                                        ...fadeInUp,
                                        animate: {
                                            transform: ' translate3d(0,0,0)',
                                            opacity: 1,
                                            transition: {
                                                duration: 1.5,
                                                ease: 'easeInOut',
                                                delay: 0.8,
                                            },
                                        },
                                    }}
                                    viewport={{ once: true }}
                                >
                                    <img
                                        className="img-fluid"
                                        src={imageReducer(item?.attributes.img.data[0]?.attributes?.url)?.url}
                                        alt="info"
                                    />
                                </motion.div>
                            </div>
                            <div className="col-md-6">
                                <motion.div
                                    className="txt-block pl-35 mb-40 wow fadeInUp"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={{
                                        ...fadeInUp,
                                        animate: {
                                            transform: ' translate3d(0,0,0)',
                                            opacity: 1,
                                            transition: {
                                                duration: 1,
                                                ease: 'easeInOut',
                                                delay: 0.3,
                                            },
                                        },
                                    }}
                                    viewport={{ once: true }}
                                >
                                    <h3
                                        className="h3-lg text-left-custom_ font"
                                        style={{ fontSize: '2.25rem', textTransform: 'uppercase' }}
                                    >
                                        {item.attributes.title}
                                    </h3>
                                    <div className="gach-tran-2 mb-35" />
                                    <p className="p-md">{item.attributes.description}</p>
                                </motion.div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="container mt-10 mobile-on">
                    <div className="row d-flex align-items-center mt-custom-100">
                        {dataSolutions.map((item, index) => (
                            <div key={item.id}>
                                <div className="col-md-6">
                                    <motion.div
                                        className="txt-block  mb-40 wow fadeInUp"
                                        initial="initial"
                                        whileInView="animate"
                                        variants={{
                                            ...fadeInUp,
                                            animate: {
                                                transform: ' translate3d(0,0,0)',
                                                opacity: 1,
                                                transition: {
                                                    duration: 1.25,
                                                    ease: 'easeInOut',
                                                    delay: 0.3,
                                                },
                                            },
                                        }}
                                        viewport={{ once: true }}
                                    >
                                        <h3
                                            className="h3-lg text-left-custom_ font"
                                            style={{ fontSize: '2.25rem', textTransform: 'uppercase' }}
                                        >
                                            {item.attributes.title}
                                        </h3>
                                        <div className="gach-tran-2 mb-35" />
                                        <p className="p-md" style={{ textAlign: 'center' }}>
                                            {item.attributes.description}
                                        </p>
                                    </motion.div>
                                </div>
                                <div className="col-md-6">
                                    <motion.div
                                        className="img-block  mb-40 wow fadeInUp"
                                        initial="initial"
                                        whileInView="animate"
                                        variants={{
                                            ...fadeInUp,
                                            animate: {
                                                transform: ' translate3d(0,0,0)',
                                                opacity: 1,
                                                transition: {
                                                    duration: 1.5,
                                                    ease: 'easeInOut',
                                                    delay: 0.8,
                                                },
                                            },
                                        }}
                                        viewport={{ once: true }}
                                    >
                                        <img
                                            className="img-fluid"
                                            src={imageReducer(item?.attributes.img.data[0]?.attributes.url)?.url}
                                            alt="info-image"
                                        />
                                    </motion.div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/*  */}
        </>
    );
};

export default Solutions;
