import { motion } from 'framer-motion';
import React from 'react';
import { fadeInUp } from '../../../assets/utils/utils';
const Information = () => {
    return (
        <div className="information-section">
            <motion.div
                className="container"
                initial="initial"
                whileInView="animate"
                variants={fadeInUp}
                viewport={{ once: true }}
            >
                <h2 className="title h2-custom-ebook">Sách điện tử là gì?</h2>
                <p className="p-md content">
                    Sách điện tử (viết tắt: e-book hay eBook), là một quyển sách được xuất bản và phát hành cho các
                    thiết bị kỹ thuật số, bao gồm văn bản, hình ảnh hoặc cả hai, có thể đọc được trên các thiết bị như
                    máy tính bàn, laptop, máy tính bảng hoặc các thiết bị điện thoại thông minh.
                </p>
            </motion.div>
        </div>
    );
};

export default Information;
