import React, { useEffect, useRef } from 'react';
import { GoThreeBars } from 'react-icons/go';
import { Link } from 'react-router-dom';
import logoRed from '../../../assets/image/AIV-logo-red.svg';
import closeIcon from '../../../assets/image/charm_chevrons-left.svg';
import './index.scss';
const HeaderMindmaid = () => {
    const headerRef = useRef(null);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80 || window.innerWidth < 776) {
                headerRef.current && headerRef.current.classList.add('scroll');
            } else {
                headerRef.current && headerRef.current.classList.remove('scroll');
            }
        });
        return () => {
            window.removeEventListener('scroll', null);
        };
    }, []);

    return (
        <div className="mindmaid">
            <div
                className=" navbar fixed-top navbar-expand-md hover-menu navbar-dark bg-tra white-scroll"
                ref={headerRef}
            >
                <div className="header-mindmaid container">
                    <Link to="/">
                        <img src={logoRed} className="logo" alt="" />
                    </Link>

                    <div className="content">
                        <a href="https://mindmaid.ai/price">Biểu phí</a>
                        <a href="https://mindmaid.ai/document">Hướng dẫn</a>
                        <a href="https://blog.mindmaid.ai/">Blog</a>
                        <a href="https://mindmaid.ai/">Truy cập Mindmaid</a>
                    </div>

                    <input type="checkbox" hidden id="tab-mobile" className="nav-input-mobile" />
                    <label
                        htmlFor="tab-mobile"
                        className="navbar-toggler mobile-on"
                        data-target="#navbarContent"
                        aria-controls="navbarContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        style={{ width: '30px' }}
                    >
                        <span className="navbar-bar-icon">
                            <GoThreeBars />
                        </span>
                    </label>
                    {/* overlay */}
                    <nav className="mobile-menu mobile-on navbar  navbar-expand-md hover-menu navbar-dark">
                        <ul className="navbar-nav ml-auto">
                            <label htmlFor="tab-mobile" className="close-tab-moblie">
                                <img src={closeIcon} style={{ cursor: 'pointer', height: '48px', zIndex: 5 }} alt="" />
                            </label>
                            <li>
                                <a href="https://mindmaid.ai/price">Biểu phí</a>
                            </li>
                            <li>
                                <a href="https://mindmaid.ai/document">Hướng dẫn</a>
                            </li>

                            <li>
                                <a href="https://blog.mindmaid.ai/">Blog</a>
                            </li>
                            <li>
                                <a href="https://mindmaid.ai/">Truy cập Minmaid</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default HeaderMindmaid;
