import { motion } from 'framer-motion';
import { fadeInUpDelay1, fadeInUpDelay2 } from '../../assets/utils/utils';
export default function Outstanding({ ...props }) {
    const { imageVd } = props;
    return (
        <section id="info-1" className="info-1-row wide-100 info-section division">
            <div className="container">
                <div className="row mb-40 mt-100">
                    <div className="col-lg-10 offset-lg-1 section-title">
                        <h3 className="h3-lg text-tranfromss" style={{ fontWeight: 700, fontSize: '2.875rem' }}>
                            Tính năng nổi bật
                        </h3>
                    </div>
                </div>
                <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                        <div className="-m-rem ">
                            <motion.div
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUpDelay1}
                                viewport={{ once: true }}
                            >
                                <h5>
                                    <span class="pr-1">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M30 10L16 26L2 10H30Z"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </span>
                                    <a
                                        class=""
                                        style={{ color: '#333' }}
                                        data-bs-toggle="collapse"
                                        href="#collapseExample"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="collapseExample"
                                    >
                                        Tự động hóa
                                    </a>
                                </h5>
                                <div class="collapse show" id="collapseExample">
                                    <div class="card card-body border-0">
                                        <ul className="txt-list pl-4">
                                            <li>
                                                “Bóc băng” file video/audio từ đường link youtube/ facebook/ người dùng
                                                tải lên để tạo thành văn bản text
                                            </li>
                                            <li>
                                                Cho phép người dùng lấy bài viết, ảnh, video tự động từ báo chí/mạng xã
                                                hội
                                            </li>
                                            <li>Tạo nội dung văn bản dựa trên những công thức copywriter có sẵn</li>
                                            <li>
                                                Tự động tạo và thêm lời bình (voice over) vào video từ văn bản text.
                                            </li>
                                            <li>Thêm phụ đề tự động vào video, hiển thị khớp với lời bình</li>
                                        </ul>
                                    </div>
                                </div>
                            </motion.div>
                            <motion.div
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUpDelay1}
                                viewport={{ once: true }}
                            >
                                <h5>
                                    <span class="pr-1">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M30 10L16 26L2 10H30Z"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </span>
                                    <a
                                        class=""
                                        style={{ color: '#333' }}
                                        data-bs-toggle="collapse"
                                        href="#collapseExample2"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="collapseExample2"
                                    >
                                        Tùy biến theo nhu cầu
                                    </a>
                                </h5>
                                <div class="collapse " id="collapseExample2">
                                    <div class="card card-body border-0">
                                        <ul className="txt-list pl-4">
                                            <li>
                                                Người dùng có thể chọn nhiều giọng đọc và điều chỉnh tốc độ đọc cho phù
                                                hợp
                                            </li>
                                            <li>Thêm nhạc từ kho nhạc no copy right hoặc do người dùng tải lên</li>
                                            <li>Export thumbnail tự động cho video theo một số template có sẵn</li>
                                            <li>Tạo video đa dạng theo nhiều format, template khác nhau</li>
                                        </ul>
                                    </div>
                                </div>
                            </motion.div>
                            <motion.div
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUpDelay1}
                                viewport={{ once: true }}
                            >
                                <h5>
                                    <span class="pr-1">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M30 10L16 26L2 10H30Z"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </span>
                                    <a
                                        class=""
                                        style={{ color: '#333' }}
                                        data-bs-toggle="collapse"
                                        href="#collapseExample3"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="collapseExample3"
                                    >
                                        Hỗ trợ kết nối
                                    </a>
                                </h5>
                                <div class="collapse " id="collapseExample3">
                                    <div class="card card-body border-0">
                                        <ul className="txt-list pl-4">
                                            <li>
                                                Kết nối tài khoản Youtube/Facebook để tự động upload video theo lịch đặt
                                                trước
                                            </li>
                                            <li>Hỗ trợ SEO trên Youtube/Facebook</li>
                                            <li>Thêm watermark tự động vào video </li>
                                        </ul>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <motion.div
                            className="img-block  mb-40 wow fadeInUp"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUpDelay2}
                            viewport={{ once: true }}
                        >
                            <img className="img-fluid" src={imageVd} alt="info" />
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
}
