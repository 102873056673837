import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { fadeInLeft, fadeInUp } from '../../../assets/utils/utils';
import banner1 from '../../../assets/image/Lark-04.svg';
import banner2 from '../../../assets/image/Lark-05.svg';
import banner3 from '../../../assets/image/Lark-03.svg';
import banner4 from '../../../assets/image/Lark-02.svg';
import banner5 from '../../../assets/image/Lark-01.svg';
import banner6 from '../../../assets/image/Anycross-01.svg';

const mocdata = [
    {
        name: 'Messenger',
        content:
            'Trung tâm giao tiếp và quản lý hiệu suất công việc. Hơn cả giao tiếp, Messenger giúp kết nối mọi tính năng từ Lịch, Meetings, Tasks, Email và Phê duyệt trong một không gian duy nhất mà không cần rời khỏi giao diện trò chuyện.',
        banner: banner1,
    },
    {
        name: 'Docs',
        content:
            'Cộng tác với nhóm của bạn một cách sáng tạo và hiệu quả Từ Docs đến Sheets đến Mindnotes, mọi ý tưởng đều được đồng bộ hóa theo thời gian thực nâng cao sự cộng tác nội dung của bạn lên một tầm cao mới.',
        banner: banner2,
    },
    {
        name: 'Base',
        content:
            'Khai thác tối đa sức mạnh của dữ liệu Base giúp doanh nghiệp dễ dàng khai thác, xử lý dữ liệu theo thời gian thực, góp phần xây dựng một quy trình làm việc tự động trong tầm tay.',
        banner: banner3,
    },
    {
        name: 'Meeting',
        content:
            'Biến các cuộc họp thành trải nghiệm cộng tác tuyệt vời Cộng tác với nhau mọi lúc mọi nơi và trên mọi thiết bị với tính năng chia sẻ và chỉnh sửa tài liệu ngay trong cuộc họp mà không cần rời khỏi cửa sổ video.',
        banner: banner4,
    },
    {
        name: 'Approval',
        content:
            'Trung tâm xử lý phê duyệt Tối ưu quy trình phê duyệt nhờ khả năng tổng hợp mọi yêu cầu trong một không gian duy nhất, đồng thời đồng bộ hóa dữ liệu phê duyệt từ các hệ thống khác.',
        banner: banner5,
    },
    {
        name: 'Anycross',
        content: 'Tiết kiệm nguồn lực - Tối ưu quy trình Dễ dàng thiết lập các quy trình tự động liên bộ phận.',
        banner: banner6,
    },
];
const Salientfeatures = () => {
    const [active, setActive] = useState('Messenger');
    const [image, setImage] = useState(banner1);

    return (
        <div className="container" id="Salientfeatures-box" style={{ paddingBottom: '100px' }}>
            <motion.div
                className="hero-txt wow fadeInUp mb-5 mb-5-custom"
                initial="initial"
                whileInView="animate"
                variants={fadeInUp}
                viewport={{ once: true }}
            >
                <div key="hero-txt" className="hero-txt wow fadeInUp" data-wow-delay="0.3s">
                    <h2 className="h2-xs" style={{ fontSize: '2.625rem', zIndex: 1, textAlign: 'center' }}>
                        Tính năng nổi bật
                    </h2>
                </div>
            </motion.div>
            <div className="row">
                <motion.div className=" col-12 col-sm-6 mobile-on mb-5">
                    <div className="banner custom-mt">
                        <img className="" src={image} alt="" />
                    </div>
                </motion.div>
                <div className="col-12 col-sm-6">
                    {mocdata.map((item, index) => (
                        <div
                            style={{ paddingLeft: '20px' }}
                            className={`mb-4  ${active === item.name ? 'box-border-red' : 'box-border'}`}
                            key={index}
                        >
                            <motion.div
                                className={`hero-txt wow fadeInUp`}
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInLeft}
                                viewport={{ once: true }}
                                onClick={() => {
                                    setActive(item.name);
                                    setImage(item.banner);
                                }}
                            >
                                <h2
                                    className={` text-mobile ${active === item.name ? 'text-active' : ''} `}
                                    style={{ fontSize: '24px', marginBottom: '0px', cursor: 'pointer' }}
                                >
                                    {item.name}
                                </h2>
                                <p
                                    className={`p-md-custom pt-30 pt-30-custom px-custom-10 ${
                                        active === item.name ? 'block' : 'hidden'
                                    }`}
                                    style={{ textAlign: 'left', paddingTop: '10px' }}
                                >
                                    {item.content}
                                </p>
                            </motion.div>
                        </div>
                    ))}
                </div>
                <motion.div className="banner pc-on col-12 col-sm-6">
                    <div className="mt-5">
                        <img className="" src={image} alt="" />
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Salientfeatures;
