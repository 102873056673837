import { motion } from 'framer-motion';
import React from 'react';
import icon1 from '../../../assets/image/mindmaid-icon06.svg';
import icon2 from '../../../assets/image/mindmaid-icon07.svg';
import icon3 from '../../../assets/image/mindmaid-icon08.svg';
import icon4 from '../../../assets/image/mindmaid-icon09.svg';
import icon5 from '../../../assets/image/mindmaid-icon10.svg';
import icon6 from '../../../assets/image/mindmaid-icon11.svg';
import { fadeInUp } from '../../../assets/utils/utils';
import SlickSlider from '../../../components/slickSlider/SlickSlider';
const Application = () => {
    const arr = [
        {
            icon: icon1,
            title: 'Tư vấn & Chăm sóc <br/> khách hàng',
            description: 'Dịch vụ khách hàng xuất sắc - Khách hàng hạnh phúc - Nhân viên hạnh phúc',
            href: '',
        },
        {
            icon: icon2,
            title: 'Quy trình nội bộ ',
            description: 'Xây dựng hệ thống hỏi-đáp quy trình nội bộ doanh nghiệp, hỗ trợ bộ phận nhân sự',
            href: '',
        },
        {
            icon: icon3,
            title: 'Trợ lý cá nhân',
            description: 'Hỗ trợ quản lý thời gian, lên kế hoạch và tổ chức công việc linh hoạt',
            href: '',
        },
        {
            icon: icon4,
            title: 'Hành chính công',
            description: 'Xây dựng hệ thống tư vấn thủ tục hành chính, hỏi đáp văn bản pháp quy...',
            href: '',
        },
        {
            icon: icon5,
            title: 'Trợ giảng ảo',
            description: 'Trợ giảng ảo tương tác với học viên, trả lời câu hỏi và hướng dẫn học tập',
            href: '',
        },
        {
            icon: icon6,
            title: 'Tuyển dụng',
            description: 'Hỗ trợ ứng viên tìm kiếm thông tin về doanh nghiệp và vị trí tuyển dụng',
            href: '',
        },
    ];

    const setting = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,

                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="application">
            <motion.div
                initial="initial"
                whileInView="animate"
                variants={fadeInUp}
                viewport={{ once: true }}
                className=""
            >
                <div className="title">
                    <h1>Showcase một số ứng dụng thực tế</h1>
                </div>

                <div className="content">
                    <SlickSlider settings={setting}>
                        {arr.map((item, index) => (
                            <div className="application-item" key={index}>
                                <img src={item.icon} className="application-icon" alt="illustration" />
                                <div className="application-title" dangerouslySetInnerHTML={{ __html: item.title }} />
                                <div className="application-desc">{item.description}</div>
                                <a href={item.href} className="link">
                                    Link demo
                                    <svg
                                        width="17"
                                        height="12"
                                        viewBox="0 0 17 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M16.3429 6.49381C16.6376 6.19912 16.6376 5.72135 16.3429 5.42667L11.5408 0.624549C11.2461 0.329867 10.7684 0.329867 10.4737 0.624549C10.179 0.919231 10.179 1.397 10.4737 1.69169L14.7422 5.96024L10.4737 10.2288C10.179 10.5235 10.179 11.0012 10.4737 11.2959C10.7684 11.5906 11.2461 11.5906 11.5408 11.2959L16.3429 6.49381ZM0.717773 6.71482L15.8094 6.71482L15.8094 5.20566L0.717774 5.20566L0.717773 6.71482Z"
                                            fill="#3071FF"
                                        />
                                    </svg>
                                </a>
                            </div>
                        ))}
                    </SlickSlider>
                </div>
            </motion.div>
        </div>
    );
};

export default Application;
