import { motion } from 'framer-motion';
import React from 'react';
import image1 from '../../../assets/image/ebook-product-1.svg';
import image2 from '../../../assets/image/ebook-product-2.svg';
import image3 from '../../../assets/image/ebook-product-3.svg';
import image4 from '../../../assets/image/ebook-product-4.svg';
import { fadeInUp } from '../../../assets/utils/utils';
import SlickSlider from '../../../components/slickSlider/SlickSlider';
const Illustration = () => {
    const options = [
        {
            img: image1,
            description: 'Giao diện trang chủ của website sách điện tử',
        },
        {
            img: image2,
            description: 'Phân loại danh mục chi tiết các đầu sách/tài liệu',
        },
        {
            img: image3,
            description: 'Giao diện đọc sách trực tuyến theo chế độ lật trang',
        },
        {
            img: image4,
            description: 'Giao diện quản trị đăng tải sách/tài liệu của website',
        },
    ];
    return (
        <div className="illustration" style={{ background: '#F5F8FF', paddingTop: 100, paddingBottom: 100 }}>
            <motion.div
                className="container"
                initial="initial"
                whileInView="animate"
                variants={fadeInUp}
                viewport={{ once: true }}
            >
                <div
                    style={{
                        paddingBottom: 50,
                        textAlign: 'center',
                    }}
                >
                    <h2 className="h2-custom-ebook">Một số hình ảnh minh họa sản phẩm</h2>
                </div>
                <SlickSlider>
                    {options.map((item) => (
                        <div className="d-flex flex-column box " key={item.description}>
                            <img src={item.img} className="illustration-img" alt="illustration" />
                            <p className="p-md" style={{ textAlign: 'center' }}>
                                {item.description}
                            </p>
                        </div>
                    ))}
                </SlickSlider>
            </motion.div>
        </div>
    );
};

export default Illustration;
