import { motion } from 'framer-motion';
import React from 'react';
import image2 from '../../../assets/image/clip-path-group.svg';
import ebookImg1 from '../../../assets/image/ebook-1.svg';
import ebookImg2 from '../../../assets/image/ebook-banner.svg';
import { fadeInUp } from '../../../assets/utils/utils';
const Banner = () => {
    return (
        <div
            className="bg-scroll hero-section division "
            style={{
                position: 'relative',
            }}
        >
            <object
                style={{
                    position: 'absolute',
                    top: 100,
                    left: 0,
                    width: 350,
                    opacity: 0.5,
                }}
                className="pc-on "
                type="image/svg+xml"
                data={image2}
                aria-label="clip-path"
            />
            <object
                style={{
                    position: 'absolute',
                    bottom: 40,
                    right: 0,
                    width: 350,
                    opacity: 0.5,
                    transform: 'scaleX(-1)',
                }}
                className="pc-on"
                type="image/svg+xml"
                data={image2}
                aria-label="clip-path"
            />
            <object
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: 900,
                    opacity: 0.5,
                    transform: 'translate(-47%, -50%)',
                }}
                className="circle"
                type="image/svg+xml"
                data={ebookImg2}
                aria-label="circle"
            />
            <motion.div
                className="container ebook-banner "
                initial="initial"
                whileInView="animate"
                variants={fadeInUp}
                viewport={{ once: true }}
            >
                <h2 className="h2-custom-ebook" style={{ textAlign: 'center', marginBottom: '60px' }}>
                    Giải pháp website sách điện tử
                </h2>
                <img
                    className="img-fluid"
                    style={{ maxWidth: '600px', margin: 'auto' }}
                    src={ebookImg1}
                    alt="ebookImg1"
                />
            </motion.div>
        </div>
    );
};

export default Banner;
