import React from "react";
import "../../assets/css/business.scss";
import Benefit from "./components/benefit";
import Content from "./components/content";
import Different from "./components/Different";
import Reason from "./components/reason";
import Section from "./components/section";
import Service from "./components/service";
import "./style.scss";
const BusinessPage = () => {
  return (
    <div className="business">
      <Section />
      <Content />
      <Benefit />
      <Different />
      <Service />
      <Reason />
    </div>
  );
};

export default BusinessPage;
