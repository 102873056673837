import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect, useState } from 'react';
import { fetchAPI } from '../../api';
import './style.css';
import BannerVeChungToi from './table1';
import TamNhinXuMenh from './table2';
import Feature2 from './table3';
import Table4 from './table4';
const AboutUs = () => {
    const [dataProducts, setDataProducts] = useState([]);
    const [dataAchievement, setDataAchievement] = useState([]);
    const [dataVision, setDataVision] = useState([]);
    useEffect(() => {
        fetchAPI('/about-us-products').then((res) => setDataProducts(res.data));
        fetchAPI('/about-us-achievements').then((res) => setDataAchievement(res.data));
        fetchAPI('/about-us-visions').then((res) => setDataVision(res.data));
    }, []);
    return (
        <div
            className="aboutUs-style"
            style={{
                overflow: 'hidden',
            }}
        >
            <BannerVeChungToi />
            <TamNhinXuMenh dataVision={dataVision} />
            <Feature2 dataAchievement={dataAchievement} />
            <Table4 dataProducts={dataProducts} />
        </div>
    );
};

export default AboutUs;
