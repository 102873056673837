import React, { useState } from 'react';
import Select from 'react-select';
import dots from '../../../assets/image/dots.svg';
import elip from '../../../assets/image/elip.svg';
import tamgiac from '../../../assets/image/tamgiac.svg';
import Tick from '../../../assets/image/ticck.svg';

const optionEmploy = [
    {
        label: '1-20 người',
        value: '1-20 người',
    },
    {
        label: '21-50 người',
        value: '21-50 người',
    },
    {
        label: '51-100 người',
        value: '51-100 người',
    },
    {
        label: '101-300 người',
        value: '101-300 người',
    },
];

const optionJob = [
    {
        label: 'CEO/Founder/Chairman',
        value: 'CEO/Founder/Chairman',
    },
    {
        label: 'Giám đốc bộ phận CTO, CFO, CMO',
        value: 'Giám đốc bộ phận CTO, CFO, CMO',
    },
    {
        label: 'Quản lý/trưởng phòng',
        value: 'Quản lý/trưởng phòng',
    },
    {
        label: 'Nhân viên',
        value: 'Nhân viên',
    },
];

export default function Contact() {
    const [employSelected, setEmploySelected] = useState(null);
    const [jobSelected, setJobSelected] = useState(null);

    const handleChangeValue = (value, action) => {
        action(value);
    };

    return (
        <section id="contact" className=" hero-section division">
            <div className="container d-flex align-items-center py-5 flex-mb">
                <div className="col-xl-6  position-relative">
                    <img
                        src={tamgiac}
                        alt=""
                        className="position-absolute "
                        style={{ left: '-100px', width: '100px', top: '-50px' }}
                    />
                    <h2 className="h2-xs  text-left mb-3 mt-5" style={{ fontSize: '2.625rem', zIndex: 1 }}>
                        Liên hệ với chúng tôi
                    </h2>
                    <p className="p-md">Để bắt đầu tiến trình chuyển đổi số của bạn</p>

                    <div className="d-flex align-items-center ">
                        <img src={Tick} alt="" />
                        <p className="mt-3 ms-3 p-md">Đặt lịch demo Lark</p>
                    </div>
                    <div className="d-flex align-items-center ">
                        <img src={Tick} alt="" />
                        <p className="mt-3 ms-3 p-md">Mua license Lark với giá ưu đãi</p>
                    </div>
                    <div className="d-flex align-items-center ">
                        <img src={Tick} alt="" />
                        <p className="mt-3 ms-3 p-md">Tư vấn triển khai Lark</p>
                    </div>
                    <div className="d-flex align-items-center ">
                        <img src={Tick} alt="" />
                        <p className="mt-3 ms-3 p-md">Đào tạo sử dụng Lark</p>
                    </div>
                    <img src={dots} alt="" className=" pt-5 none-mb" style={{ width: '100px' }} />
                </div>
                <div className="d-flex align-items-center ">
                    <div
                        className="col-xl-6 border p-4 rounded-3 position-relative w-100"
                        style={{ backgroundColor: '#fff' }}
                    >
                        <form className="">
                            <div className="row mb-3 row-mb">
                                <div className="form-group col-md-6 ">
                                    <label for="inputName" className="p-md" style={{ fontWeight: '400' }}>
                                        Họ và tên
                                    </label>
                                    <input type="email" className="form-control" id="inputName" />
                                </div>
                                <div className="form-group col-md-6 mt-5-mb">
                                    <label for="inputPhone" className="p-md" style={{ fontWeight: '400' }}>
                                        Số điện thoại
                                    </label>
                                    <input className="form-control" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 px-1 col-12">
                                    <label for="inputEmail" className="p-md" style={{ fontWeight: '400' }}>
                                        Email
                                    </label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 mt-5-mb col-12">
                                    <label for="inputAddress2" className="p-md" style={{ fontWeight: '400' }}>
                                        Tên công ty
                                    </label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="form-group col-md-6">
                                    <label className="p-md" style={{ fontWeight: '400' }}>
                                        Quy mô nhân sự
                                    </label>
                                    <Select
                                        value={employSelected}
                                        placeholder="Nhân sự"
                                        onChange={(e) => handleChangeValue(e, setEmploySelected)}
                                        options={optionEmploy}
                                    />
                                </div>
                                <div className="form-group col-md-6 mt-5-mb">
                                    <label className="p-md" style={{ fontWeight: '400' }}>
                                        Vị trí công việc
                                    </label>
                                    <Select
                                        value={jobSelected}
                                        placeholder="Công việc"
                                        onChange={(e) => handleChangeValue(e, setJobSelected)}
                                        options={optionJob}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 px-1 col-12">
                                    <label className="p-md" style={{ fontWeight: '400' }}>
                                        Lời nhắn
                                    </label>
                                    <textarea
                                        type="text"
                                        name=""
                                        id=""
                                        cols="30"
                                        rows="5"
                                        className="form-control"
                                    ></textarea>
                                </div>
                            </div>
                        </form>

                        <span className="input-group-btn ">
                            <button
                                className="btn  tra-hover w-100 "
                                style={{ background: 'linear-gradient(90.53deg, #E23444 0.45%, #FF576C 112.77%)' }}
                            >
                                Đăng ký ngay
                            </button>
                        </span>
                    </div>
                    <img src={elip} alt="" className="position-absolute w-25 elip" />
                </div>
            </div>
        </section>
    );
}
