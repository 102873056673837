import React from 'react';
import krvLogo from '../../../assets/image/krv.svg';
import NTMLogo from '../../../assets/image/nhattruongminh.svg';
import techMasterLogo from '../../../assets/image/techmaster.svg';
import topLink from '../../../assets/image/toplink.svg';
import vtvLite from '../../../assets/image/vtvlite.svg';
import SlickSlider from '../../../components/slickSlider/SlickSlider';
import TextCustomer from './common/textCustomer';
export default function Story() {
    const images = [vtvLite, topLink, NTMLogo, krvLogo, techMasterLogo];
    const settings = {
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
    };
    return (
        <div id="story" className="" style={{ paddingTop: '100px' }}>
            <TextCustomer title="Câu chuyện khách hàng" />
            <div className="container mt-5 mx-auto images lg-on">
                <a href="https://workbetter.vn/aivgroup-vtvlive/">
                    <img src={vtvLite} alt="VTV Lite" className="img-item" />
                </a>
                <a href="https://workbetter.vn/aivgroup-vtvlive/">
                    <img src={topLink} alt="TopLink" className="img-item" />
                </a>
                <a href="https://workbetter.vn/aivgroup-vtvlive/">
                    <img src={NTMLogo} alt="Nhat Truong Minh" className="img-item" />
                </a>
                <a href="https://workbetter.vn/aivgroup-vtvlive/">
                    <img src={krvLogo} alt="Kho Ruou Vang" className="img-item" />
                </a>
                <a href="https://workbetter.vn/aivgroup-vtvlive/">
                    <img src={techMasterLogo} alt="TechMaster" className="img-item" />
                </a>
            </div>
            {/* mobile */}
            <div style={{ marginLeft: '30px', marginTop: '30px' }} className="mobile-on">
                <SlickSlider settings={{ ...settings, dots: false }}>
                    {images.map((item, indx) => (
                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                        <img key={indx} src={item} alt={`Image ${indx}`} />
                    ))}
                </SlickSlider>
            </div>
        </div>
    );
}
