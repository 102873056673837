import { motion } from 'framer-motion';
import React from 'react';
import image from '../../../assets/image/ebook-2.svg';
import { fadeInLeft, fadeInUp } from '../../../assets/utils/utils';
const Section = () => {
    return (
        <div
            style={{
                background:
                    'linear-gradient(89.82deg, #344ABE -13.49%, #633E9B 21.7%, #B12A61 70.07%, #CD234C 107.14%)',
                paddingTop: '100px',
                paddingBottom: '100px',
            }}
        >
            <div className="container ebook-section d-flex gap-5">
                <motion.img
                    className="img-fluid"
                    src={image}
                    alt="testing"
                    initial="initial"
                    whileInView="animate"
                    variants={fadeInLeft}
                    viewport={{ once: true }}
                />
                <motion.p
                    className="text-white d-flex align-items-center custom-p "
                    style={{
                        fontSize: '20px',
                    }}
                    initial="initial"
                    whileInView="animate"
                    variants={fadeInUp}
                    viewport={{ once: true }}
                >
                    Thấu hiểu những khó khăn đó, Giải pháp Website Sách điện tử của công ty AIV Group được phát triển để
                    đáp ứng nhu cầu đặc thù của các cơ quan địa phương trong phổ biến sách báo, tài liệu điện tử tới
                    người dân
                </motion.p>
            </div>
        </div>
    );
};

export default Section;
