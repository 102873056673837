import { motion } from 'framer-motion';
import React from 'react';
import img1 from '../../../assets/image/mindmaid-img03.svg';
import img2 from '../../../assets/image/mindmaid-img04.svg';
import img3 from '../../../assets/image/mindmaid-img05.svg';
import img4 from '../../../assets/image/mindmaid-img06.svg';
import { fadeInLeft, fadeInRight, fadeInUp } from '../../../assets/utils/utils';
const Reason = () => {
    const arr = [
        {
            img: img1,
            title: 'Đi đầu về AI',
            desc: 'AIV Group là đơn vị đi đầu về ứng dụng mô hình ngôn ngữ lớn (LLM) vào Trợ lý ảo, chatbot thế hệ mới.',
        },
        {
            img: img2,
            title: 'Hệ thống đo lường',
            desc: 'Với kinh nghiệm nhiều năm phân tích dữ liệu, Mindmaid giúp bạn thấu hiểu khách hàng qua dữ liệu của Trợ lý ảo.',
        },
        {
            img: img3,
            title: 'Kinh nghiệm triển khai',
            desc: 'Đội ngũ AIV Group đã triển khai huấn luyện trợ lý ảo hiệu quả cho nhiều đơn vị, bao gồm hành chính công, giáo dục, ecommerce...',
        },
        {
            img: img4,
            title: 'Liên tục cải tiến',
            desc: 'AIV Group cam kết không ngừng nỗ lực về chất lượng sản phẩm để mang đến cho khách hàng sự hài lòng tốt nhất.',
        },
    ];

    return (
        <>
            <div className="reason">
                <div className="container">
                    <motion.h1
                        initial="initial"
                        whileInView="animate"
                        variants={fadeInUp}
                        viewport={{ once: true }}
                        className="title"
                    >
                        Tại sao lại chọn chúng tôi
                    </motion.h1>

                    <div className="content">
                        {arr.map((item, index) => (
                            <div className="content-item" key={index}>
                                <motion.img
                                    initial="initial"
                                    whileInView="animate"
                                    variants={index % 2 === 0 ? fadeInLeft : fadeInRight}
                                    viewport={{ once: true }}
                                    src={item.img}
                                    alt="banner"
                                />
                                <motion.div
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUp}
                                    viewport={{ once: true }}
                                    className="content-title"
                                >
                                    {item.title}
                                </motion.div>
                                <motion.div
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUp}
                                    viewport={{ once: true }}
                                    className="content-desc"
                                >
                                    {item.desc}
                                </motion.div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="experience">
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={fadeInUp}
                    viewport={{ once: true }}
                    className="content "
                >
                    <h1>
                        Bạn đã sẵn sàng trải nghiệm
                        <br /> miễn phí với Mindmaid?
                    </h1>
                    <div className="desc">
                        Chi phí 0 đồng để tích hợp Trợ lý ảo vào kênh Website, Fanpage, Telegram, Viber...của bạn
                    </div>
                    <a href="mindmaid.ai" className="button">
                        <span>Tạo miễn phí</span>
                    </a>
                </motion.div>
            </div>
        </>
    );
};

export default Reason;
