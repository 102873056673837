import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { fetchAPI } from '../../api';
import '../../assets/css/implement.css';
import { fadeInUp } from '../../assets/utils/utils';
import SlickSlider from '../slickSlider/SlickSlider';
import { imageReducer } from '../utils';
const Implement = () => {
    const [implementData, setImplementData] = useState([]);

    useEffect(() => {
        fetchAPI('/home-implements').then((res) => setImplementData(res.data));
    }, []);
    return (
        <>
            <section
                id="banner-home-1"
                className="bg-white relative pb-60 features-section bg-scroll  hero-section division  pc-on"
            >
                <div className="container absolute  " style={{ bottom: '-10rem' }}>
                    <motion.div
                        className="hero-txt text-center "
                        style={{
                            marginBottom: '60px',
                        }}
                        initial="initial"
                        whileInView="animate"
                        variants={fadeInUp}
                        viewport={{ once: true }}
                    >
                        <h3
                            className="h3-lg text-tranfromss wow fadeInUp "
                            style={{
                                fontFamily: '"Roboto"',
                                fontWeight: 700,
                                fontSize: '2.5rem',
                                marginBottom: '35px',
                            }}
                        >
                            Cách chúng tôi thực hiện
                        </h3>
                        <div className="gach-tran "></div>
                    </motion.div>
                    {/* pc */}
                    <div className="row ">
                        {implementData.map((item) => (
                            <motion.div
                                className="col-md-6 col-lg-4 px-30  wow fadeInUp pricing-table"
                                style={{
                                    marginBottom: '25px',
                                }}
                                initial="initial"
                                whileInView="animate"
                                key={item.id}
                                variants={fadeInUp}
                                viewport={{ once: true }}
                            >
                                <div className="bg-white h-100 box-home p-4 pt-5">
                                    <div className=" pb-25" data-wow-delay="0.3s" style={{ paddingBottom: '25px' }}>
                                        <img
                                            className="img-90"
                                            src={imageReducer(item?.attributes?.img?.data[0]?.attributes?.url).url}
                                            alt="data"
                                        />
                                    </div>
                                    <div>
                                        <h5 className="h5-sm" style={{ color: '#222' }}>
                                            {item.attributes.title}
                                        </h5>
                                    </div>
                                    <p className="p-md"> {item.attributes.description}</p>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>
            {/* mobile  */}
            <section
                id="banner-home-1"
                className="bg-white relative pb-60 features-section bg-scroll  division mobile-on "
            >
                <div className="container absolute  " style={{ bottom: '-10rem' }}>
                    <motion.div
                        className="hero-txt text-center"
                        style={{
                            marginBottom: '60px',
                        }}
                        initial="initial"
                        whileInView="animate"
                        variants={fadeInUp}
                        viewport={{ once: true }}
                    >
                        <h3
                            className="h3-lg wow fadeInUp text-tranfromss"
                            style={{
                                fontFamily: '"Roboto"',
                                fontWeight: 700,
                                fontSize: '2.5rem',
                                marginBottom: '35px',
                            }}
                        >
                            Cách chúng tôi thực hiện
                        </h3>
                        <div className="gach-tran"></div>
                    </motion.div>
                    <SlickSlider>
                        {implementData.map((item) => (
                            <motion.div
                                className="col-md-12 px-custom  col-lg-12 px-30  wow fadeInUp"
                                style={{
                                    marginBottom: '25px',
                                }}
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUp}
                                viewport={{ once: true }}
                                key={item.id}
                            >
                                <div className="bg-white   text-center h-100 box-home p-4 pt-5">
                                    <motion.div
                                        className=" pb-25 justify-mobile wow fadeInUp "
                                        data-wow-delay="0.7s"
                                        initial="initial"
                                        whileInView="animate"
                                        variants={fadeInUp}
                                        viewport={{ once: true }}
                                    >
                                        <img
                                            className="img-90"
                                            src={imageReducer(item?.attributes?.img?.data[0]?.attributes?.url).url}
                                            style={{
                                                margin: 'auto',
                                            }}
                                            alt="feature-icon"
                                        />
                                    </motion.div>
                                    <div>
                                        <h5 className="h5-sm" style={{ color: '#222' }}>
                                            {item.attributes?.title}
                                        </h5>
                                    </div>
                                    <p className="p-md">{item.attributes?.description}</p>
                                </div>
                            </motion.div>
                        ))}
                    </SlickSlider>
                </div>
            </section>
        </>
    );
};

export default Implement;
