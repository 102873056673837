import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AboutUs from '../page/AboutUs';
import Introduce from '../page/Introduce';
import PageNotFound from '../page/NotFound';
import Province from '../page/VnProvince';
import AutoVid from '../page/autovid';
import BusinessPage from '../page/business';
import Ebook from '../page/ebook';
import HomePage from '../page/homepage';
import Lark from '../page/lark';
import Mindmaid from '../page/mindmaid';
import Recruit from '../page/recruit';
import Solution from '../page/solutions';

export default function Routers() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/recruit" element={<Recruit />} />
            <Route path="/autovid" element={<AutoVid />} />
            <Route path="/vnalert-province" element={<Province />} />
            <Route path="/introduce" element={<Introduce />} />
            <Route path="/about_us" element={<AboutUs />} />
            <Route path="/solution" element={<Solution />} />
            <Route path="/business" element={<BusinessPage />} />
            <Route path="/ebook" element={<Ebook />} />
            <Route path="/lark" element={<Lark />} />
            <Route path="/mindmaid" element={<Mindmaid />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}
