import React from 'react';
import { GrNext, GrPrevious } from 'react-icons/gr';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
const SlickSlider = ({ children, props, settings }) => {
    const SampleNextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    fontSize: '2rem',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: '#ffeded',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 0.8,
                }}
                onClick={onClick}
            >
                <GrNext />
            </div>
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    fontSize: '2rem',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: '#ffeded',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10,
                    opacity: 0.8,
                }}
                onClick={onClick}
            >
                <GrPrevious />
            </div>
        );
    };

    const options = {
        dots: true,
        nextArrow: <SampleNextArrow className="next" />,
        prevArrow: <SamplePrevArrow className="prev" />,
        ...settings,
    };
    const autoplay = {
        autoplay: true,
        autoplaySpeed: 2000,
        ...settings,
    };
    return (
        <Slider {...props} {...options} {...autoplay}>
            {children}
        </Slider>
    );
};
export default SlickSlider;
