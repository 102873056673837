import React from 'react';
import Application from './components/application';
import BannerMinmaid from './components/banner';
import Benefit from './components/benefit';
import Feature from './components/feature';
import Partner from './components/partner';
import AnswerQuestion from './components/question';
import Reason from './components/reason';
import './index.scss';

const Mindmaid = () => {
    return (
        <div className="mindmaid">
            <BannerMinmaid />
            <Benefit />
            <Feature />
            <Application />
            <Partner />
            <Reason />
            <AnswerQuestion />
        </div>
    );
};

export default Mindmaid;
