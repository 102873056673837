import React from 'react';
import image1 from '../../../assets/image/image 4.png';
import image2 from '../../../assets/image/image 2.png';
import image3 from '../../../assets/image/image 3.png';
import SlickSlider from '../../../components/slickSlider/SlickSlider';
export default function DigitalConv() {
    const autoplaySettings = {
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
    };
    return (
        <div id="digitalConv">
            <div className="list-box">
                <div className="container">
                    <h2 className="h2-xs  text-center " style={{ fontSize: '2.625rem', zIndex: 1 }}>
                        Chuyển đổi số cùng AIV
                    </h2>
                    <p
                        className=" pt-30 pt-30-custom px-custom-10  "
                        style={{
                            textAlign: 'center',
                            paddingTop: '30px',
                            width: '75%',
                            margin: 'auto',
                            fontSize: '1.125rem',
                            paddingBottom: '50px',
                        }}
                    >
                        AIV Group tự hào là đối tác chính thức đầu tiên của Lark Suite tại Việt Nam. Chúng tôi cung cấp
                        đầy đủ các dịch vụ triển khai Lark Suite dành cho doanh nghiệp.
                    </p>
                </div>
                {/* pc */}
                <div className=" background pc-on">
                    <div className="container d-flex  flex-mb justify-content-center align-items-center">
                        <div className="p-4 border me-4 rounded col-3 custom-h" style={{ height: '450px' }}>
                            <img src={image1} alt="" className="w-100" />
                            <h5 className="mt-4">Xây dựng không gian làm việc hiệu quả hơn với Lark</h5>
                        </div>
                        <div className="p-4 border mx-3 rounded col-4 custom-h" style={{ height: '450px' }}>
                            <img src={image2} alt="" className="w-100" />
                            <h5 className="mt-4">Tìm hiểu về Lark và giao lưu trực tiếp với đại diện hãng</h5>
                        </div>
                        <div className="p-4 border ms-4 rounded col-3 custom-h" style={{ height: '450px' }}>
                            <img src={image3} alt="" className="w-100" />
                            <h5 className="mt-4">Tự động hóa doanh nghiệp với Lark</h5>
                        </div>
                    </div>
                </div>
                {/* mobile */}
                <div className="container mobile-on">
                    <SlickSlider settings={autoplaySettings}>
                        <div className="p-4 border  rounded ">
                            <img src={image1} alt="" className="mx-auto" />
                            <h4 className="mt-4 text-center">Xây dựng không gian làm việc hiệu quả hơn với Lark</h4>
                        </div>
                        <div className="p-4 border rounded">
                            <img src={image2} alt="" className="mx-auto" style={{ width: '95%' }} />
                            <h4 className="mt-4 text-center">
                                Tìm hiểu về Lark và giao lưu trực tiếp với đại diện hãng
                            </h4>
                        </div>
                        <div className="p-4 border rounded">
                            <img src={image3} alt="" className="mx-auto" />
                            <h4 className="mt-4 text-center">Tự động hóa doanh nghiệp với Lark</h4>
                        </div>
                    </SlickSlider>
                </div>
            </div>
        </div>
    );
}
