import React from 'react';
import banner from '../../../assets/image/mindmaid-img01.png';

import { motion } from 'framer-motion';
import { fadeInLeft, fadeInRight } from '../../../assets/utils/utils';
const BannerMinmaid = () => {
    return (
        <div className="banner">
            <div className=" container banner-content">
                <motion.div
                    className="wrap-text"
                    initial="initial"
                    whileInView="animate"
                    variants={fadeInLeft}
                    viewport={{ once: true }}
                >
                    <div>
                        <h1>Mở khóa sức mạnh</h1>
                        <h1>Trợ lý ảo</h1>
                        <div className="text-content">
                            Mindmaid giúp doanh nghiệp tạo ra Trợ lý ảo thế hệ mới với cá tính riêng biệt và trải nghiệm
                            hỏi-đáp có cảm xúc như người thật nhờ công nghệ ChatGPT. Từ đó mang đến phương thức mới để
                            bán hàng, chăm sóc khách hàng, tuyển dụng, tư vấn, đào tạo...và khai thác các mô hình kinh
                            doanh mới trong kỉ nguyên AI
                        </div>
                    </div>
                    <div className="button">Tạo miễn phí</div>
                </motion.div>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={fadeInRight}
                    viewport={{ once: true }}
                    className="banner-img"
                >
                    <img src={banner} />
                </motion.div>
            </div>
        </div>
    );
};

export default BannerMinmaid;
