/* eslint-disable jsx-a11y/alt-text */
import { motion } from 'framer-motion';
import React from 'react';
import section2 from '../../../assets/image/banter-larkmail.svg';
import { fadeInLeft, fadeInUp } from '../../../assets/utils/utils';
const Section = () => {
    return (
        <div
            className="section"
            style={{
                paddingBottom: 60,
            }}
        >
            <div
                className="container section-business row"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '150px',
                }}
            >
                <motion.div
                    style={{
                        color: '#ffffff',
                        marginTop: '50px',
                    }}
                    className="custom-mobile-busines col-xl-6 col-lg-6 col-md-12 col-xs-12 col-md-12"
                    initial={'initial'}
                    whileInView={'animate'}
                    variants={fadeInLeft}
                    viewport={{ once: true }}
                >
                    <h2 className="h2-xs white-color">
                        Tư vấn - Hỗ trợ <br className="tablet-on"></br>doanh nghiệp
                    </h2>
                    <h2 className="h2-xs text-custom-h2  white-color">Sử dụng 50 Business Mail với giá 0đ</h2>
                    <div
                        className="btn border-white  h-10 mt-10 py-custom-busines"
                        style={{
                            width: 180,
                            marginTop: 50,
                        }}
                    >
                        Xem Thêm
                    </div>
                </motion.div>
                <motion.div
                    className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-md-12"
                    initial={'initial'}
                    whileInView={'animate'}
                    variants={fadeInUp}
                    viewport={{ once: true }}
                >
                    {/* <img src={section2} className="img-fluid" alt="banner" /> */}
                    <object className="w-100" type="image/svg+xml" data={section2} />
                </motion.div>
            </div>
        </div>
    );
};

export default Section;
