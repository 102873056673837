import { motion } from 'framer-motion';
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { fadeInUp } from '../../../assets/utils/utils';
import SlickSlider from '../../../components/slickSlider/SlickSlider';
const Service = () => {
    return (
        <div className="section-service">
            <div className="container px-[100px]">
                <h2
                    className="uppercase h2-xs text-white text-center"
                    // style={{ fontSize: "42px" }}
                >
                    DỊCH VỤ TRỌN GÓI SIÊU RẺ
                </h2>
                <div className="pc-on">
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '80px',
                            justifyContent: 'center',
                        }}
                        className="service"
                    >
                        <motion.div
                            className=" bg-white d-flex service-item"
                            style={{
                                backgroundColor: '#ffffff',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                borderRadius: '30px',
                                width: '417px',
                                padding: '30px',
                                marginRight: 50,
                            }}
                            initial={'initial'}
                            whileInView={'animate'}
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <div>
                                <h4
                                    className="linear uppercase text-[21px] font-semibold text-h4-custom"
                                    style={{ width: '80%' }}
                                >
                                    Doanh nghiệp chưa có business mail
                                </h4>
                                <h3 className="h3-lg text-40 font-semibold py-1">500.000đ</h3>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.75rem',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        <i>
                                            <FaCheckCircle />
                                        </i>
                                        <span>Setup hosting mail mới</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        <i>
                                            <FaCheckCircle />
                                        </i>
                                        <span>Tạo 50 mail mới</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        <i>
                                            <FaCheckCircle />
                                        </i>
                                        <span>Sử dụng trên cả website/laptop/mobile</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        <i>
                                            <FaCheckCircle />
                                        </i>
                                        <span>Tài liệu hướng dẫn sử dụng tiếng Việt</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        <i>
                                            <FaCheckCircle />
                                        </i>
                                        <span>Group support Zalo</span>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="btn "
                                style={{
                                    color: '#ffffff',
                                    height: '2.9rem',
                                    marginTop: '30px',
                                    background: '#DA2043',
                                    width: '320px',
                                }}
                            >
                                Tư vấn
                            </div>
                        </motion.div>
                        <motion.div
                            className=" bg-white d-flex service-item"
                            style={{
                                backgroundColor: '#ffffff',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                borderRadius: '30px',
                                width: '417px',
                                padding: '30px',
                            }}
                            initial={'initial'}
                            whileInView={'animate'}
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <div>
                                <h4 className="linear uppercase text-h4-custom ">
                                    Doanh nghiệp đã có sẵn business mail ở Gsuite/Server riêng
                                </h4>
                                <h3 className="h3-lg text-40 font-semibold py-1">300.000đ</h3>
                                <div className="d-flex" style={{ flexDirection: 'column', gap: '0.75rem' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 500,
                                            color: '#CEC6C6',
                                        }}
                                    >
                                        <span>+30.000đ/mail cần migrate</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        <i>
                                            <FaCheckCircle />
                                        </i>
                                        <span>Setup hosting mail mới</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        <i>
                                            <FaCheckCircle />
                                        </i>
                                        <span>Tạo 50 mail mới</span>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        <i>
                                            <FaCheckCircle />
                                        </i>
                                        <span>Sử dụng trên cả website/laptop/mobile</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        <i>
                                            <FaCheckCircle />
                                        </i>
                                        <span>Tài liệu hướng dẫn sử dụng tiếng Việt</span>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        <i>
                                            <FaCheckCircle />
                                        </i>
                                        <span>Chuyển dữ liệu business mail cũ sang mail mới</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        <i>
                                            <FaCheckCircle />
                                        </i>
                                        <span>
                                            {' '}
                                            Quá trình migrate được record để bảo đảm privacy cho email khách hàng
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        <i>
                                            <FaCheckCircle />
                                        </i>
                                        <span>Group support Zalo</span>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="btn "
                                style={{
                                    color: '#ffffff',
                                    height: '2.9rem',
                                    marginTop: '30px',
                                    background: '#DA2043',
                                    width: '320px',
                                }}
                            >
                                Tư vấn
                            </div>
                        </motion.div>
                    </div>
                </div>
                <div className="mobile-on">
                    <div
                        style={{
                            marginTop: '90px',
                        }}
                    >
                        <SlickSlider>
                            <div className="bg-white d-flex service-item ">
                                <div>
                                    <h4 className="linear uppercase text-[21px] font-semibold text-h4-custom">
                                        Doanh nghiệp chưa có business mail
                                    </h4>
                                    <h3 className="h3-lg text-40 font-semibold py-1">500.000đ</h3>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '0.75rem',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>Setup hosting mail mới</span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>Tạo 50 mail mới</span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>Sử dụng trên cả website/laptop/mobile</span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>Tài liệu hướng dẫn sử dụng tiếng Việt</span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>Group support zalo</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="btn "
                                    style={{
                                        color: '#ffffff',
                                        height: '2.9rem',
                                        marginTop: '30px',
                                        background: '#DA2043',
                                    }}
                                >
                                    Tư vấn
                                </div>
                            </div>
                            <div className=" bg-white d-flex service-item">
                                <div>
                                    <h4 className="linear uppercase text-h4-custom">
                                        Doanh nghiệp đã có sẵn business mail ở Gsuite/Server riêng
                                    </h4>
                                    <h3 className="h3-lg text-40 font-semibold py-1">300.000đ</h3>
                                    <div className="d-flex" style={{ flexDirection: 'column', gap: '0.75rem' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>Setup hosting mail mới</span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>Setup hosting mail mới</span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>Tạo 50 mail mới</span>
                                        </div>

                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>Sử dụng trên cả website/laptop/mobile</span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>Tài liệu hướng dẫn sử dụng tiếng Việt</span>
                                        </div>

                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>Chuyển dữ liệu business mail cũ sang mail mới</span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>
                                                {' '}
                                                Quá trình migrate được record để bảo đảm privacy cho email khách hàng
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <i>
                                                <FaCheckCircle />
                                            </i>
                                            <span>Group support zalo</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="btn "
                                    style={{
                                        color: '#ffffff',
                                        height: '2.9rem',
                                        marginTop: '30px',
                                        background: '#DA2043',
                                    }}
                                >
                                    Tư vấn
                                </div>
                            </div>
                        </SlickSlider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;
