/* eslint-disable react/jsx-no-target-blank */
import { default as React, useEffect, useRef } from 'react';
import { GoThreeBars } from 'react-icons/go';
import { Link } from 'react-router-dom';
import '../../../assets/css/navbar.css';
import logo from '../../../assets/image/Logo.svg';
import closeIcon from '../../../assets/image/charm_chevrons-left.svg';
import './index.scss';
const NavbarLark = () => {
    const headerRef = useRef(null);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80 || window.innerWidth < 776) {
                headerRef.current && headerRef.current.classList.add('scroll');
            } else {
                headerRef.current && headerRef.current.classList.remove('scroll');
            }
        });
        return () => {
            window.removeEventListener('scroll', null);
        };
    }, []);

    // useEffect(() => {
    //     if (window.innerWidth < 776) {
    //         headerRef.current.classList.add('scroll');
    //         setIsLogoRed(true);
    //     } else {
    //         headerRef.current.classList.remove('scroll');
    //         setIsLogoRed(false);
    //     }
    // }, []);

    return (
        <div className="lark">
            <header
                id="recruit-style"
                className="header"
                style={{
                    height: 0,
                }}
            >
                <div
                    ref={headerRef}
                    className="navbar fixed-top navbar-expand-md hover-menu navbar-dark bg-tra white-scroll"
                >
                    <div className="container d-flex justify-content-between">
                        <Link to="/" className=" logo-white">
                            <img src={logo} width={143} height={57} alt="header-logo" />
                        </Link>

                        <div>
                            <label
                                htmlFor="tab-mobile"
                                className="navbar-toggler mobile-on"
                                data-target="#navbarContent"
                                aria-controls="navbarContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                style={{ width: '30px' }}
                            >
                                <span className="navbar-bar-icon">
                                    <GoThreeBars />
                                </span>
                            </label>
                            <div id="navbarContent" className="collapse pc-on navbar-collapse text-white">
                                <ul className="navbar-nav ml-auto ">
                                    <li className="nav-item nl-simple ">
                                        <a className="default-text nav-link" href="#Salientfeatures-box">
                                            Tính năng
                                        </a>
                                    </li>

                                    <li className="nav-item nl-simple">
                                        <a className="default-text nav-link" href="#Services">
                                            Giải pháp
                                        </a>
                                    </li>
                                    <li className="nav-item nl-simple">
                                        <a
                                            className="default-text nav-link"
                                            href="https://workbetter.vn/huong-dan-su-dung-lark/"
                                        >
                                            Hướng dẫn sử dụng
                                        </a>
                                    </li>
                                    <li className="nav-item nl-simple">
                                        <a className="default-text nav-link" href="https://workbetter.vn/">
                                            Blog
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <input type="checkbox" hidden id="tab-mobile" className="nav-input-mobile" />
                {/* overlay */}
                <label htmlFor="tab-mobile" className="overlay" />
                {/* nav mobile */}

                <nav className="mobile-menu mobile-on navbar  navbar-expand-md hover-menu navbar-dark">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item nl-simple d-flex">
                            <a href="#Salientfeatures-box">Tính năng</a>
                            <label htmlFor="tab-mobile" className="close-tab-moblie">
                                <img src={closeIcon} style={{ cursor: 'pointer', height: '48px', zIndex: 5 }} alt="" />
                            </label>
                        </li>

                        <li className="nav-item nl-simple">
                            <a href="#Services">Giải pháp</a>
                        </li>
                        <li className="nav-item nl-simple">
                            <a href="https://workbetter.vn/huong-dan-su-dung-lark/">Hướng dẫn sử dụng</a>
                        </li>
                        <li className="nav-item nl-simple">
                            <a href="https://workbetter.vn/">Blog</a>
                        </li>
                    </ul>
                </nav>
            </header>
        </div>
    );
};

export default NavbarLark;
