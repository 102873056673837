import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./style.css";
import Banner from "./banner";
import Solutions from "./solution";

const Solution = () => {
  return (
    <div className="solution-style">
      <Banner />
      <Solutions />
    </div>
  );
};

export default Solution;
