import { motion } from 'framer-motion';
import { useState } from 'react';
import { PostAPI } from '../../api';
import { fadeInUp, fadeInUpDelay1 } from '../../assets/utils/utils';
export default function BoxConnect({ ...props }) {
    const { IconLocation, IconPhone, IconEmail, IconFb, IconIn } = props;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [isError, setIsError] = useState(false);

    const handleClearValues = () => {
        setName('');
        setEmail('');
        setDescription('');
    };

    const onSubmit = async () => {
        const data = await PostAPI('/recruitment-infos', {
            name: name,
            email: email,
            description: description,
        });

        if (data?.data?.attributes) {
            handleClearValues();
            setIsError(false);
        } else {
            setIsError(true);
        }
    };

    return (
        <section
            id="newsletter-1 mt-20"
            style={{
                background: 'linear-gradient(276.28deg, #DA2043 0%, #344ABE 96.31%)',
            }}
            className="bg-scroll  wide-70 newsletter-section division"
        >
            <div className="container white-color">
                <div className="row mb-40">
                    <motion.div
                        className="col-lg-10 offset-lg-1 section-title  wow fadeInUp"
                        initial="initial"
                        whileInView="animate"
                        variants={fadeInUp}
                        viewport={{ once: true }}
                    >
                        <h3 className="h3-lg" style={{ fontWeight: 700, fontSize: '2.875rem', color: 'white' }}>
                            Kết nối với chúng tôi
                        </h3>
                    </motion.div>
                </div>
                <div className="row d-flex align-items-center">
                    <div className="col-md-6 col-sm-10 mx-auto mobile-on  pc-20">
                        <motion.div
                            className="txt-block pc-45 mb-40 wow fadeInUp animated"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUpDelay1}
                            viewport={{ once: true }}
                        >
                            <ul className="foo-links clearfix">
                                <li className="mb-4">
                                    <div className="d-flex items-center">
                                        <div style={{ width: '30px' }} className="mr-3">
                                            <img src={IconLocation} alt="" />
                                        </div>
                                        <a href="#map" className="font-weight-bold ml-1">
                                            Tầng 23, Toà Eurowindow Multi-complex <br className="mobile-on" /> 27 Trần
                                            Duy Hưng, Trung Hoà, Cầu Giấy, Hà Nội
                                        </a>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <div className="d-flex items-center">
                                        <div style={{ width: '30px' }} className="mr-3">
                                            <img src={IconPhone} alt="" />
                                        </div>
                                        <a href="tel:0931 458 189" className="font-weight-bold">
                                            0931 458 189
                                        </a>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <div className="d-flex items-center">
                                        <div style={{ width: '30px' }} className="mr-3">
                                            <img src={IconEmail} alt="" />
                                        </div>
                                        <a href="mailto:marketing@aivgroup.vn" className="font-weight-bold">
                                            marketing@aivgroup.vn
                                        </a>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex items-center mt-40" style={{ marginLeft: '-5px' }}>
                                        <div className="mr-15">
                                            <img src={IconFb} alt="" />
                                        </div>
                                        <div className="ml-15">
                                            <img src={IconIn} alt="" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </motion.div>
                    </div>
                    <div className="col-md-6">
                        <motion.div
                            className="img-block pc-45 wow fadeInUp"
                            initial="initial"
                            whileInView="animate"
                            variants={{
                                ...fadeInUp,
                                animate: {
                                    y: 0,
                                    opacity: 1,
                                    transition: {
                                        duration: 1.5,
                                        ease: 'easeInOut',
                                        delay: 0.8,
                                    },
                                },
                            }}
                            viewport={{ once: true }}
                        >
                            <div className="newsletter-txt text-left bg-white white-color pricing-table mb-40 res-mobile">
                                <div className="newsletter-form py-custom">
                                    <div>
                                        <div className="mb-30">
                                            <input
                                                type="text"
                                                className="form-control new w-100 border-bg-gray"
                                                placeholder="Họ Tên"
                                                required
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-30">
                                            <input
                                                type="email"
                                                className="form-control new w-100 border-bg-gray"
                                                placeholder="Địa chỉ Email "
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            {isError && (
                                                <span style={{ color: 'red', paddingTop: '3px' }}>
                                                    Email không đúng định dạng
                                                </span>
                                            )}
                                        </div>
                                        <div>
                                            <textarea
                                                className="form-control w-100"
                                                style={{
                                                    borderRadius: '10px',
                                                    height: '12rem',
                                                    paddingTop: '10px',
                                                    background: '#F5F5F5',
                                                }}
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                cols={30}
                                                rows={10}
                                                placeholder="Lời nhắn"
                                            />
                                        </div>
                                        <span className="input-group-btn">
                                            <button onClick={onSubmit} className="btn btn-red tra-hover">
                                                Gửi
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    <div className="col-md-6 pc-on">
                        <motion.div
                            className="txt-block pc-45 mb-40 wow fadeInUp"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUpDelay1}
                            viewport={{ once: true }}
                        >
                            <ul className="foo-links clearfix">
                                <li className="mb-4">
                                    <div className="d-flex items-center">
                                        <div style={{ width: '30px' }} className="mr-3">
                                            <img src={IconLocation} alt="" />
                                        </div>
                                        <a href="/#" className="font-weight-bold ml-1">
                                            Tầng 23, Toà Eurowindow Multi-complex <br className="mobile-on" /> 27 Trần
                                            Duy Hưng, Trung Hoà, Cầu Giấy, Hà Nội
                                        </a>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <div className="d-flex items-center">
                                        <div style={{ width: '30px' }} className="mr-3">
                                            <img src={IconPhone} alt="" />
                                        </div>
                                        <a href="tel:0931458189" className="font-weight-bold">
                                            0931 458 189
                                        </a>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <div className="d-flex items-center">
                                        <div style={{ width: '30px' }} className="mr-3">
                                            <img src={IconEmail} alt="" />
                                        </div>
                                        <a href="mailto:info@aivgroup.vn" className="font-weight-bold">
                                            info@aivgroup.vn
                                        </a>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex items-center mt-40" style={{ marginLeft: '-5px' }}>
                                        <div className="mr-15">
                                            <img src={IconFb} alt="" />
                                        </div>
                                        <div className="ml-15">
                                            <img src={IconIn} alt="" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
}
