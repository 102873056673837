import React from 'react';

import logo2 from '../../../assets/image/cole-logo.svg';
import logo1 from '../../../assets/image/techmaster-logo.svg';
import logo3 from '../../../assets/image/ubox-logo.svg';
import logo4 from '../../../assets/image/unidry-logo.svg';

import { motion } from 'framer-motion';
import { fadeInUp } from '../../../assets/utils/utils';
const Partner = () => {
    const arr = [logo1, logo2, logo3, logo4];

    return (
        <motion.div
            initial="initial"
            whileInView="animate"
            variants={fadeInUp}
            viewport={{ once: true }}
            className="partner"
        >
            <div className="title">Đối tác triển khai trợ lý ảo</div>
            <div className="partner-bg">
                <div className="content">
                    {arr.map((item, index) => (
                        <img key={index} src={item} alt="logo-partner" />
                    ))}
                </div>
            </div>
        </motion.div>
    );
};

export default Partner;
