import { default as React, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../assets/css/navbar.css';
import logo from '../../assets/image/Logo.svg';
import closeIcon from '../../assets/image/charm_chevrons-left.svg';

import { GoThreeBars } from 'react-icons/go';
const NavbarEbook = () => {
    const headerRef = useRef(null);
    const { pathname } = useLocation();
    const services = ['vnalert-province', 'autovid', 'business', 'ebook', 'contentbid'];
    const isService = services.some((service) => pathname.includes(service));
    return (
        <header className="header-ebook ">
            <div
                ref={headerRef}
                className=" navbar-ebook  fixed-top navbar-expand-md hover-menu"
                style={{
                    alignItems: 'center',
                }}
            >
                <div className="container d-flex justify-content-between" style={{ alignItems: 'center' }}>
                    <Link to="/" className=" logo-white">
                        <img src={logo} width={143} height={57} alt="header-logo" />
                    </Link>
                    <div>
                        <label
                            htmlFor="tab-mobile"
                            className="navbar-toggler mobile-on"
                            data-target="#navbarContent"
                            aria-controls="navbarContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            // style={{ width: '30px' }}
                        >
                            <span className="navbar-bar-icon">
                                <GoThreeBars color="white" />
                            </span>
                        </label>
                        <div id="navbarContent" className="collapse pc-on navbar-collapse text-white">
                            <ul className="navbar-nav ml-auto ">
                                <li className="nav-item nl-simple ">
                                    <Link
                                        className={`${
                                            pathname === '/about_us' ? 'red-text nav-link' : 'default-text nav-link'
                                        }`}
                                        to={`/about_us`}
                                        style={{ color: 'white' }}
                                    >
                                        Về chúng tôi
                                    </Link>
                                    {/* <div className="nav-link">Về chúng tôi</div> */}
                                </li>
                                <li className="nav-item dropdown">
                                    <div
                                        className={'default-text nav-link dropdown-toggle'}
                                        style={{ color: isService ? '#343a40' : 'white' }}
                                    >
                                        Sản phẩm
                                    </div>
                                    <ul className="dropdown-menu ml-1" aria-labelledby="DropdownMenu">
                                        <li>
                                            <Link className="dropdown-item" href="/mindmaid">
                                                Mindmaid
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/lark">
                                                Tư vấn & Triển khai Lark
                                            </Link>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://vnalert.vn/">
                                                VnAlert
                                            </a>
                                        </li>
                                        {/* <li
                                            style={{
                                                backgroundColor:
                                                    pathname.includes('vnalert-province') && 'rgba(142, 227, 253, 0.2)',
                                            }}
                                        >
                                            <Link
                                                className="dropdown-item"
                                                to="/vnalert-province"
                                                style={{
                                                    color: pathname.includes('vnalert-province') && '#3386fa',
                                                }}
                                            >
                                                VnAlert Province
                                            </Link>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://report.vnalert.vn/">
                                                VnAlert Fast Report{' '}
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="https://contentbid.vn/app/home">
                                                ContentBid
                                            </a>
                                        </li>
                                        <li
                                            style={{
                                                backgroundColor:
                                                    pathname.includes('autovid') && 'rgba(142, 227, 253, 0.2)',
                                            }}
                                        >
                                            <Link
                                                className="dropdown-item"
                                                to={`/autovid`}
                                                style={{
                                                    color: pathname.includes('autovid') && '#3386fa',
                                                }}
                                            >
                                                Autovid
                                            </Link>
                                        </li>
                                        <li
                                            style={{
                                                backgroundColor:
                                                    pathname.includes('business') && 'rgba(142, 227, 253, 0.2)',
                                            }}
                                        >
                                            <Link
                                                className="dropdown-item"
                                                to={`/business`}
                                                style={{
                                                    color: pathname.includes('business') && '#3386fa',
                                                }}
                                            >
                                                Business
                                            </Link>
                                        </li> */}
                                        <li
                                            style={{
                                                backgroundColor:
                                                    pathname.includes('ebook') && 'rgba(142, 227, 253, 0.2)',
                                            }}
                                        >
                                            <Link
                                                className="dropdown-item"
                                                to={`/ebook`}
                                                style={{
                                                    color: pathname.includes('ebook') && '#3386fa',
                                                }}
                                            >
                                                Ebook
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item nl-simple">
                                    <Link
                                        className={`${
                                            pathname === '/recruit' ? 'red-text nav-link' : 'default-text nav-link'
                                        }`}
                                        to={`/recruit`}
                                        style={{ color: 'white' }}
                                    >
                                        Tuyển dụng
                                    </Link>
                                    {/* <div className="nav-link">Tuyển dụng</div> */}
                                </li>
                                <li className="nav-item nl-simple">
                                    <div className="nav-link" style={{ color: 'white' }}>
                                        {' '}
                                        Tin Tức
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <input type="checkbox" hidden id="tab-mobile" className="nav-input-mobile" />
            {/* overlay */}
            <label htmlFor="tab-mobile" className="overlay" />
            {/* nav mobile */}
            <nav className="mobile-menu mobile-on navbar  navbar-expand-md hover-menu navbar-dark">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item nl-simple d-flex">
                        <Link
                            className="new-mt"
                            to="/about_us"
                            style={{
                                color: pathname.includes('about_us') && 'red',
                            }}
                        >
                            Về chúng tôi
                        </Link>
                        <label htmlFor="tab-mobile" className="close-tab-moblie">
                            <img src={closeIcon} style={{ cursor: 'pointer', height: '48px', zIndex: 5 }} alt="" />
                        </label>
                    </li>
                    <li className="nav-item dropdown">
                        <div
                            className=" dropdown-toggle"
                            id="DropdownMenu"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{
                                color: isService && 'red',
                            }}
                        >
                            Sản phẩm
                        </div>
                        <ul className="dropdown-menu ml-1" aria-labelledby="DropdownMenu">
                            {/* <li>
                                <a className="dropdown-item" href="https://vnalert.vn/">
                                    VnAlert
                                </a>
                            </li>
                            <li>
                                <Link
                                    style={{
                                        backgroundColor: pathname.includes('vnalert-province') && '#f8f9fa',
                                    }}
                                    to="/vnalert-province"
                                    className="dropdown-item"
                                >
                                    VnAlert Province
                                </Link>
                            </li>
                            <li>
                                <a className="dropdown-item" href="https://report.vnalert.vn/">
                                    VnAlert Fast Report{' '}
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="https://contentbid.vn/app/home">
                                    ContentBid
                                </a>
                            </li>
                            <li>
                                <Link
                                    to="/autovid"
                                    className="dropdown-item"
                                    style={{
                                        backgroundColor: pathname.includes('autovid') && '#f8f9fa',
                                    }}
                                >
                                    Autovid
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/business"
                                    className="dropdown-item"
                                    style={{
                                        backgroundColor: pathname.includes('business') && '#f8f9fa',
                                    }}
                                >
                                    Business
                                </Link>
                            </li> */}
                            <li>
                                <Link className="dropdown-item" href="/mindmaid">
                                    Mindmaid
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/lark">
                                    Tư vấn & Triển khai Lark
                                </Link>
                            </li>
                            <li>
                                <a className="dropdown-item" href="https://vnalert.vn/">
                                    VnAlert
                                </a>
                            </li>
                            <li>
                                <Link
                                    to="/ebook"
                                    className="dropdown-item"
                                    style={{
                                        backgroundColor: pathname.includes('ebook') && '#f8f9fa',
                                    }}
                                >
                                    Ebook
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item nl-simple">
                        <Link
                            style={{
                                color: pathname.includes('solution') && 'red',
                            }}
                            to="/solution"
                        >
                            Giải pháp
                        </Link>
                    </li>
                    <li className="nav-item nl-simple">
                        <Link
                            to="/recruit"
                            style={{
                                color: pathname.includes('recruit') && 'red',
                            }}
                        >
                            Tuyển dụng
                        </Link>
                    </li>
                    <li className="nav-item nl-simple">
                        <a href="https://tintuc.aivgroup.vn">Tin Tức</a>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default NavbarEbook;
