import { motion } from 'framer-motion';
import React from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import banner from '../../../assets/image/ebook-feature-bg.svg';
import image from '../../../assets/image/feature.svg';
import { fadeInLeft, fadeInUp } from '../../../assets/utils/utils';
const Feature = () => {
    return (
        <div
            className="bg-image position-relative bottom-0 feature"
            style={{
                backgroundImage: `url(${banner})`,
                backgroundSize: 'cover',
                paddingTop: 100,
                paddingBottom: 100,
            }}
        >
            <div className="container">
                <div
                    style={{
                        paddingBottom: 50,
                        textAlign: 'center',
                    }}
                >
                    <h2 className="h2-custom-ebook">Các tính năng nổi bật mà giải pháp cung cấp</h2>
                </div>
                <div className="d-flex justify-content-around box-father">
                    <motion.img
                        className="img-feature"
                        src={image}
                        alt=""
                        initial="initial"
                        whileInView="animate"
                        variants={fadeInLeft}
                        viewport={{ once: true }}
                    />
                    <motion.div
                        className="d-flex flex-column box"
                        style={{ width: 400 }}
                        initial="initial"
                        whileInView="animate"
                        variants={fadeInUp}
                        viewport={{ once: true }}
                    >
                        <div className="d-flex gap-2 box-item">
                            <i>
                                <BsCheckCircleFill />
                            </i>
                            <p className="p-md">Giao diện thân thiện, tối ưu để sử dụng trên điện thoại di động</p>
                        </div>
                        <div className="d-flex gap-2 box-item">
                            <i>
                                <BsCheckCircleFill />
                            </i>
                            <p className="p-md">Chế độ đọc sách trực tuyến tạo trải nghiệm như đọc sách giấy</p>
                        </div>
                        <div className="d-flex gap-2 box-item">
                            <i>
                                <BsCheckCircleFill />
                            </i>
                            <p className="p-md">Tích hợp công cụ thống kê lượng truy cập để phục vụ làm báo cáo </p>
                        </div>
                        <div className="d-flex gap-2 box-item">
                            <i>
                                <BsCheckCircleFill />
                            </i>
                            <p className="p-md">Giao diện quản trị giúp cán bộ tự đăng tải sách </p>
                        </div>
                        <div className="d-flex gap-2 box-item">
                            <i>
                                <BsCheckCircleFill />
                            </i>
                            <p className="p-md">
                                Web hoạt động nhanh, yêu cầu cấu hình thấp, phù hợp những nơi có sóng điện thoại yếu
                            </p>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default Feature;
