import { motion } from 'framer-motion';
import { fadeInUp } from '../../assets/utils/utils';
import Implement from '../../components/layout/implement';
import Section from '../../components/layout/section';
import Service from '../../components/layout/service';
import MetaDecorator from '../../components/utils/MetaDecorator';
import './homepage.scss';
export default function HomePage() {
    return (
        <div>
            <MetaDecorator title="AIV" description="Giải quyết các bài toán thú vị dựa trên tư duy phân tích" />
            <div
                style={{
                    overflow: 'hidden',
                }}
                className="home-page"
            >
                <Section />
                <Implement />
                <Service />
                <section id="newsletter-1" className=" bg-custom  wide-70 newsletter-section">
                    <div className="container white-color">
                        <div className="row " style={{ marginBottom: '10px' }}>
                            <motion.div
                                className="col-lg-10 section-title  wow fadeInUp"
                                style={{ width: '100%', marginBottom: '40px' }}
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUp}
                                viewport={{ once: true }}
                            >
                                <h3 className="h3-lg new-px" style={{ fontWeight: 700, fontSize: '2.5rem' }}>
                                    Bạn muốn gia nhập đại gia đình AIV Group?
                                </h3>
                                <p
                                    style={{
                                        fontSize: '1.125rem',
                                        color: 'white',
                                    }}
                                >
                                    Chúng tôi luôn chào đón các ứng viên hoài bão, mong muốn dấn thân vào lĩnh vực công
                                    nghệ, yêu thích làm việc trong một môi trường năng động, trẻ trung và đầy thử thách.{' '}
                                </p>
                            </motion.div>
                        </div>
                        <div className="row">
                            <div className="col-md-5 col-lg-5 mx-auto offset-md-1 offset-lg-2">
                                <motion.div
                                    className="newsletter-txt text-center  wow fadeInUp"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUp}
                                    viewport={{ once: true }}
                                >
                                    <form className="newsletter-form px-custom">
                                        <div className="input-group">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email của bạn"
                                                required="Tính năng chưa hoạt động"
                                                id="s-email"
                                            />
                                            <span className="input-group-btn">
                                                <button
                                                    type="submit"
                                                    className="btn-letter  btn-reds tra-hovers tra-hover"
                                                >
                                                    <a
                                                        target="blank"
                                                        href="https://docs.google.com/forms/d/e/1FAIpQLSf44iZC2upyi0EiU-8XnfujZXOzRiFFmpa-dKsIja1exAmkuA/viewform?fbzx=-7402253991391920822"
                                                    >
                                                        Ứng tuyển ngay
                                                    </a>
                                                </button>
                                            </span>
                                        </div>
                                    </form>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
