import { motion } from 'framer-motion';
import { fadeInLeft, fadeInRight } from '../../assets/utils/utils';
export default function Price({ ...props }) {
    const { icon2, icon1 } = props;
    return (
        <section id="pricing-1" className=" wide-100 pricing-section division">
            <div className="container">
                <div className="row mb-20">
                    <div className="col-lg-10 offset-lg-1 section-title">
                        <h3 className="h3-lg">Biểu phí</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                        <div className="row">
                            <div className="col-md-6">
                                <motion.div
                                    className="pricing-table wow fadeInRight"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInRight}
                                    viewport={{ once: true }}
                                >
                                    <div className="price-icon pb-40 ">
                                        <div className="new-img mx-auto p-3">
                                            <img className="img-80 mt-3" src={icon1} alt="price-icon" />
                                        </div>
                                    </div>
                                    <div className="pricing-plan mb-10">
                                        <span className="price">400 triệu</span>
                                    </div>
                                    <ul className="features">
                                        <li style={{ fontWeight: 700, fontSize: '1.225rem' }}>vnđ / năm</li>
                                    </ul>
                                    <a href="/#" className="btn btn-tra-black black-hover">
                                        Năm đầu tiên
                                    </a>
                                </motion.div>
                            </div>
                            <div className="col-md-6">
                                <motion.div
                                    className="pricing-table wow fadeInLeft"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInLeft}
                                    viewport={{ once: true }}
                                >
                                    <div className="price-icon pb-40">
                                        <div className="p-3 new-img mx-auto">
                                            <img className="img-80 mt-3" src={icon2} alt="price-icon" />
                                        </div>
                                    </div>
                                    <div className="pricing-plan mb-10">
                                        <span className="price">300 triệu</span>
                                    </div>
                                    <ul className="features">
                                        <li style={{ fontWeight: 700, fontSize: '1.225rem' }}>vnđ / năm</li>
                                    </ul>
                                    <a href="/#" className="btn btn-red black-hover">
                                        Năm tiếp theo
                                    </a>
                                </motion.div>
                            </div>
                        </div>
                        <div className="pricing-notice text-center">
                            <p className="p-md">+ Phí theo số tài khoản sử dụng (2tr/1tài khoản/1 năm) </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
