import { motion } from 'framer-motion';
import { fadeInUp } from '../../assets/utils/utils';
const BannerVeChungToi = () => {
    return (
        <>
            <section id="banner-ve-chung-toi" className="bg-scroll hero-section division">
                <div className="container white-color mb-100 mt-100">
                    <div className="row mb-10 py-custom">
                        <div className="col-md-6 mt-90 col-xl-5 pc-on">
                            <motion.div
                                className="hero-txt wow fadeInUp"
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUp}
                                viewport={{ once: true }}
                            >
                                <h3 className="h3-xl" style={{ color: '#DA2043' }}>
                                    Về AIV Group
                                </h3>
                                <p className="p-md text-black mt-40" style={{ color: '#4D4D4D' }}>
                                    Công ty Cổ phần Công nghệ và Truyền thông AIV Group là một startup công nghệ Việt
                                    Nam, được thành lập từ ngày 30/08/2019. AIV Group hoạt động trong lĩnh vực thu thập,
                                    phân tích, cung cấp dữ liệu và các giải pháp tự động hoá quy trình sản xuất.
                                </p>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="" className=" bg-white mobile-on mb-70 pb-0 features-section division  ">
                <div className="container ">
                    <div className="row ">
                        <motion.div
                            className="col-lg-10 offset-lg-1 section-title wow fadeInUp animated"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <h3 className="h3-xl" style={{ color: '#DA2043' }}>
                                Về AIV Group
                            </h3>
                            <p className="p-md text-black mt-40" style={{ color: '#4D4D4D' }}>
                                Công ty Cổ phần Công nghệ và Truyền thông AIV Group là một startup công nghệ Việt Nam,
                                được thành lập từ ngày 30/08/2019. AIV Group hoạt động trong lĩnh vực thu thập, phân
                                tích, cung cấp dữ liệu và các giải pháp tự động hoá quy trình sản xuất.
                            </p>
                        </motion.div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BannerVeChungToi;
