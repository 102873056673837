/* eslint-disable react-hooks/exhaustive-deps */
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { fetchAPI } from '../../api';
import '../../assets/css/service.css';
import { fadeInUp } from '../../assets/utils/utils';
import SlickSlider from '../slickSlider/SlickSlider';
import { imageReducer } from '../utils/index';
const Service = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [serviceData, setServiceData] = useState([]);
    const [contentServiceData, setContentServiceData] = useState([]);
    const [homeLetterData, setHomeLetterData] = useState([]);
    const [newItem, setNewItem] = useState();

    useEffect(() => {
        fetchAPI('/home-services', '?populate=content_services.img').then((res) => setServiceData(res.data));
    }, []);

    useEffect(() => {
        fetchAPI('/content-services').then((res) => setContentServiceData(res.data));
    }, []);

    useEffect(() => {
        fetchAPI('/home-letters').then((res) => setHomeLetterData(res.data));
    }, []);

    const isActive = serviceData.find((item) => item.id === activeTab);

    const firstTwoItems = homeLetterData[0]?.attributes ? homeLetterData[0]?.attributes.img.data.slice(0, 2) : [];

    const lastItems = homeLetterData[0]?.attributes
        ? homeLetterData[0]?.attributes?.img?.data[homeLetterData[0]?.attributes?.img?.data?.length - 1]
        : [];

    const secondItems = homeLetterData[0]?.attributes
        ? homeLetterData[0]?.attributes.img.data.slice(2).filter((item) => item.id !== lastItems.id)
        : [];

    useEffect(() => {
        if (lastItems) {
            setNewItem(lastItems);
        }
    }, [lastItems]);

    return (
        <>
            <section id="info-12" className="info-12-row wide-100 wide-100-custom  hover-show info-section division">
                <div className="container">
                    <div className="row mb-40">
                        <motion.div
                            className="col-lg-10 section-title mb-0  wow fadeInUp"
                            style={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                            }}
                            initial={'initial'}
                            whileInView={'animate'}
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <h3
                                className="h4-lg mb-35 text-tranfromss"
                                style={{
                                    fontFamily: '"Roboto"',
                                    fontWeight: 700,
                                    fontSize: '2.5rem',
                                }}
                            >
                                Sản phẩm và dịch vụ
                            </h3>
                            <div className="gach-tran "></div>
                        </motion.div>
                    </div>
                    <div className="row pc-on mx-auto">
                        <div>
                            <div className="txt-block">
                                <motion.div
                                    id="list-tab"
                                    className="list-group row default-theme"
                                    role="tablist"
                                    initial={'initial'}
                                    whileInView={'animate'}
                                    variants={fadeInUp}
                                    viewport={{ once: true }}
                                >
                                    {serviceData.map((item) => (
                                        <div
                                            id="tab1-list"
                                            className={`list-group-item list-group-it p-4 em-action col-lg-3 col-md-3  wow fadeInUp ${
                                                item.id === activeTab && 'active'
                                            } `}
                                            data-toggle="list"
                                            role="tab"
                                            aria-controls="tab-1"
                                            key={item.id}
                                            data-wow-delay="0.2s"
                                            onClick={() => setActiveTab(item.id)}
                                        >
                                            <div className="ibox-5 ">
                                                <h5 className="h5-sm text-center mb-0">{item.attributes.title}</h5>
                                            </div>
                                        </div>
                                    ))}
                                </motion.div>
                            </div>
                        </div>
                    </div>
                    <motion.div
                        className="row pc-on mx-auto"
                        initial={'initial'}
                        whileInView={'animate'}
                        variants={fadeInUp}
                        viewport={{ once: true }}
                    >
                        <div className="col-md-12">
                            <div className="info-img text-center">
                                <div
                                    id="nav-tabContent"
                                    className="tab-content "
                                    style={{
                                        marginTop: '60px',
                                    }}
                                >
                                    {isActive?.attributes?.content_services?.data.length > 1 ? (
                                        <div
                                            id="tab-1"
                                            className=" h-250 active w-100"
                                            role="tabpanel"
                                            aria-labelledby="tab1-list"
                                        >
                                            <SlickSlider>
                                                {isActive?.attributes?.content_services?.data.map((item) => (
                                                    <div
                                                        className="row d-flex  wow fadeInUp"
                                                        style={{
                                                            textAlign: 'left',
                                                        }}
                                                        key={item.id}
                                                    >
                                                        <motion.div
                                                            className="row d-flex  wow fadeInUp "
                                                            initial="initial"
                                                            whileInView="animate"
                                                            variants={fadeInUp}
                                                            viewport={{ once: true }}
                                                        >
                                                            <div className="col-md-6 col-lg-6 w-50 col-xl-6">
                                                                <div
                                                                    className="txt-block pc-15 text-left"
                                                                    style={{
                                                                        textAlign: 'left',
                                                                    }}
                                                                >
                                                                    <h4 className="text-left mb-35">
                                                                        {item.attributes.title}
                                                                    </h4>
                                                                    <p className="p-md">
                                                                        {item.attributes.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-6 w-50 col-xl-6">
                                                                <img
                                                                    className="img-fluid custom-image"
                                                                    style={{ float: 'right' }}
                                                                    src={
                                                                        imageReducer(
                                                                            item?.attributes?.img?.data[0]?.attributes
                                                                                ?.url,
                                                                        ).url
                                                                    }
                                                                    alt="info"
                                                                />
                                                            </div>
                                                        </motion.div>
                                                    </div>
                                                ))}
                                            </SlickSlider>
                                        </div>
                                    ) : (
                                        <div id="tab-2" className="h-250" role="tabpanel" aria-labelledby="tab2-list">
                                            <div className="row d-flex ">
                                                <div className="col-md-6">
                                                    <div
                                                        className="txt-block pc-15 text-left "
                                                        style={{
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <h4 className="text-left mb-35">
                                                            {
                                                                isActive?.attributes.content_services?.data[0]
                                                                    .attributes.title
                                                            }
                                                        </h4>
                                                        <p className="p-md">
                                                            {
                                                                isActive?.attributes.content_services?.data[0]
                                                                    .attributes.description
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <img
                                                            className="img-fluid custom-image"
                                                            src={
                                                                imageReducer(
                                                                    isActive?.attributes?.content_services?.data[0]
                                                                        ?.attributes.img?.data[0]?.attributes.url,
                                                                )?.url
                                                            }
                                                            alt="info"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        className="row mobile-on slick-slider-3"
                        initial={'initial'}
                        whileInView={'animate'}
                        variants={fadeInUp}
                        viewport={{ once: true }}
                    >
                        <SlickSlider>
                            {contentServiceData.map((item) => (
                                <div className="col-md-6 " key={item.id}>
                                    <div className=" icon-sm wow fadeInUp" data-wow-delay="0.3s">
                                        <div
                                            className="ibox-5 color-custom "
                                            style={{
                                                marginBottom: '25px',
                                            }}
                                        >
                                            <h5
                                                className="h5-sm px-30 text-center mb-0"
                                                style={{ color: '#da2043', fontWeight: 600 }}
                                            >
                                                {item?.attributes.home_service.data.attributes.title}
                                            </h5>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <img
                                                className="img-fluid mb-15 "
                                                style={{ borderRadius: '10px', height: '200px' }}
                                                src={imageReducer(item?.attributes?.img?.data[0].attributes.url).url}
                                                alt=""
                                            />
                                        </div>
                                        <div className="text-center">
                                            <h5 className="h5-sm text-uppercase">{item.attributes.title}</h5>
                                            <p className="p-md mt-25 px-custom">{item.attributes.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </SlickSlider>
                    </motion.div>
                </div>
                <div
                    className="container pc-on mb-20 wide-100"
                    style={{
                        marginTop: '120px',
                    }}
                >
                    <div className="row">
                        {homeLetterData[0]?.attributes.img.data.map((item) => (
                            <div
                                className="col-md-6 col-lg-4"
                                key={item.id}
                                style={{
                                    paddingTop: '15px',
                                    paddingBottom: '15px',
                                }}
                            >
                                <motion.div
                                    className="fbox-2 icon-sm wow fadeInUp"
                                    data-wow-delay="0.3s"
                                    initial={'initial'}
                                    whileInView={'animate'}
                                    variants={fadeInUp}
                                    viewport={{ once: true }}
                                >
                                    <div className="fbox-2-txt">
                                        <img
                                            className="img-fluid "
                                            style={{ borderRadius: '10px' }}
                                            src={imageReducer(item?.attributes?.url)?.url}
                                            alt=""
                                        />
                                    </div>
                                </motion.div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* mobile */}
                <div
                    className="container mobile-on mb-20  pt-50 cover"
                    style={{
                        marginTop: '80px',
                    }}
                >
                    <div className="row px-3 ">
                        {firstTwoItems.map((item, index) => (
                            <motion.div
                                className={index % 2 === 0 ? `col-7` : 'col-5'}
                                initial={'initial'}
                                whileInView={'animate'}
                                key={item.id}
                                variants={{
                                    ...fadeInUp,
                                    animate: {
                                        y: 0,
                                        opacity: 1,
                                        transition: {
                                            duration: 1.25,
                                            ease: 'easeInOut',
                                            delay: 0.5,
                                        },
                                    },
                                }}
                                viewport={{ once: true }}
                            >
                                <img
                                    className="w-100 h-100 img-fluid wow  fadeInUp"
                                    data-wow-delay="0.5s"
                                    style={{ borderRadius: '10px' }}
                                    src={imageReducer(item?.attributes.url).url}
                                    alt=""
                                />
                            </motion.div>
                        ))}
                    </div>
                    <div className="row px-3">
                        <div className="col-5">
                            {secondItems.map((item) => (
                                <motion.div
                                    className="fbox-2 icon-sm wow fadeInUp "
                                    data-wow-delay="0.7s"
                                    style={{
                                        marginTop: '15px',
                                    }}
                                    key={item.id}
                                    initial={'initial'}
                                    whileInView={'animate'}
                                    variants={{
                                        ...fadeInUp,
                                        animate: {
                                            y: 0,
                                            opacity: 1,
                                            transition: {
                                                duration: 1.25,
                                                ease: 'easeInOut',
                                                delay: 0.7,
                                            },
                                        },
                                    }}
                                    viewport={{ once: true }}
                                >
                                    <div>
                                        <img
                                            className="img-fluid "
                                            style={{ borderRadius: '10px' }}
                                            src={imageReducer(item?.attributes?.url).url}
                                            alt=""
                                        />
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                        <motion.div
                            className="col-7  pl-0"
                            style={{
                                marginTop: '15px',
                            }}
                            initial={'initial'}
                            whileInView={'animate'}
                            variants={{
                                ...fadeInUp,
                                animate: {
                                    y: 0,
                                    opacity: 1,
                                    transition: {
                                        duration: 1.25,
                                        ease: 'easeInOut',
                                        delay: 0.5,
                                    },
                                },
                            }}
                            viewport={{ once: true }}
                        >
                            <img
                                className="w-100 h-100  wow fadeInUp"
                                style={{ borderRadius: '10px' }}
                                src={imageReducer(newItem?.attributes?.url)?.url}
                                alt=""
                            />
                        </motion.div>
                    </div>
                </div>
                <div className=" mt-60-custom">
                    <div className="col-lg-6 col-xl-5 col-md-5 mx-auto offset-lg-1 resp ">
                        <motion.div
                            className="img-block wow fadeInUp"
                            data-wow-duration="1.5s"
                            initial={'initial'}
                            whileInView={'animate'}
                            variants={{
                                ...fadeInUp,
                                animate: {
                                    y: 0,
                                    opacity: 1,
                                    transition: {
                                        duration: 1.25,
                                        ease: 'easeInOut',
                                        delay: 0.2,
                                    },
                                },
                            }}
                            viewport={{ once: true }}
                        >
                            <p className="text-center p-md" style={{ fontStyle: 'italic' }}>
                                {homeLetterData[0]?.attributes?.description}
                            </p>
                            <p className="mt-40 text-center p-md" style={{ fontWeight: 700 }}>
                                Đặng Hải Lộc, Nhà sáng lập &amp; CEO
                            </p>
                        </motion.div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Service;
