import { motion } from 'framer-motion';
import { useState } from 'react';
import '../../assets/css/province.css';
import Icon2 from '../../assets/image/icon03.svg';
import Icon1 from '../../assets/image/icon04.svg';
import image01 from '../../assets/image/image-01.svg';
import image02 from '../../assets/image/image-02.svg';
import image03 from '../../assets/image/image-03.svg';
import image04 from '../../assets/image/image-04.svg';
import image1 from '../../assets/image/image-1.svg';
import image2 from '../../assets/image/image-2.svg';
import image3 from '../../assets/image/image-3.svg';
import image4 from '../../assets/image/image-4.svg';
import image5 from '../../assets/image/image-5.svg';
import Icon from '../../assets/image/iocn01.svg';
import { fadeInUp, fadeInUpDelay1, fadeInUpDelay2 } from '../../assets/utils/utils';
import SlickSlider from '../../components/slickSlider/SlickSlider';
import MetaDecorator from '../../components/utils/MetaDecorator';
const options = [
    {
        key: 1,
        value: ' Tiết kiệm nhân lực bằng việc sử dụng hệ thống tự động theo dõi thông tin trên báo chí và mạng xã hội.',
        img: image1,
    },
    {
        key: 2,
        value: 'Đơn vị chủ động trước tin tiêu cực nhờ nhận cảnh báo trên mobile ngay khi thông tin tiêu cực xuất hiện.',
        img: image2,
    },
    {
        key: 3,
        value: ' Hỗ trợ các đơn vị tạo báo cáo phân tích truyền thông tự động, dễ dàng và tiết kiệm thời gian.',
        img: image3,
    },
    {
        key: 4,
        value: 'Cá nhân hóa thông tin cho từng cấp lãnh đạo và được tóm tắt tự động.',
        img: image4,
    },
    {
        key: 5,
        value: 'Các đơn vị dễ dàng phân loại và xác định thông tin nổi bật ở từng cấp hành chính và ban ngành.',
        img: image5,
    },
];

const Province = () => {
    const [numberTab, setNumberTab] = useState(1);

    return (
        <div>
            <MetaDecorator title="VnAlert province" />
            <div className="h3-lg" style={{ overflow: 'hidden' }}>
                <section className="banner-province bg-scroll hero-section division">
                    <div className="container white-color mb-100 mt-100">
                        <div className="row mb-10">
                            <div className="col-md-6 mt-90 col-xl-5">
                                <motion.div
                                    className="hero-txt wow fadeInUp"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUp}
                                    viewport={{ once: true }}
                                >
                                    <h3 className="h3-xl">
                                        VnAlert <span style={{ color: '#FFB155' }}>Province</span>
                                    </h3>
                                    <p className="p-md  mt-10 new-color ">Hệ thống hỗ trợ theo dõi thông tin</p>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* banner-home-1  hero-section */}
                <section className="bg-white relative pb-60 features-section bg-scroll  division  ">
                    <div className="container absolute  " style={{ bottom: '-10rem' }}>
                        <motion.div
                            className="hero-txt text-center mb-100"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <h3
                                className="h3-lg  text-tranfromss wow fadeInUp"
                                style={{
                                    fontFamily: '"Roboto"',
                                    fontWeight: 700,
                                    fontSize: '2.5rem',
                                    marginBottom: '35px',
                                }}
                            >
                                VnAlert Province là gì?
                            </h3>
                            <div className="gach-tran "></div>
                        </motion.div>
                        <div className="row ">
                            <motion.div
                                className="col-md-6 col-lg-4 px-30 mb-25 wow fadeInUp "
                                style={{
                                    marginBottom: '25px',
                                }}
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUp}
                                viewport={{ once: true }}
                            >
                                <div className="bg-white center box-home p-4 pt-5">
                                    <div className=" pb-25 box-item" data-wow-delay="0.3s">
                                        <img className="img-90 image-province" src={Icon} alt="icon" />
                                    </div>
                                    {/* <div class="">
                                    <h5 class="h5-sm">Cung cấp dữ liệu truyền thông</h5>
                                </div> */}
                                    <p className="p-md box-text">
                                        VnAlert Province là hệ thống theo dõi thông tin cho các đơn vị bằng cách thu
                                        thập dữ liệu từ các nguồn báo chí, trang tin, mạng xã hội thường xuyên đưa tin
                                        về đơn vị đó.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div
                                className="col-md-6 col-lg-4 px-30 mb-25 wow fadeInUp "
                                style={{
                                    marginBottom: '25px',
                                }}
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUpDelay1}
                                viewport={{ once: true }}
                            >
                                <div className="bg-white center box-home p-4 pt-5">
                                    <div className="pb-25 wow fadeInUp box-item" data-wow-delay="0.5s">
                                        <img className="img-90 image-province" src={Icon1} alt="feature-icon" />
                                    </div>
                                    <p className="p-md box-text">
                                        VnAlert Province áp dụng trí tuệ nhân tạo để tự động hóa việc phân tích, tóm tắt
                                        thông tin, thống kê và tạo báo cáo tự động.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div
                                className="col-md-6 col-lg-4 px-30 mb-25 wow fadeInUp d-flex mx-auto"
                                style={{
                                    marginBottom: '25px',
                                }}
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUpDelay2}
                                viewport={{ once: true }}
                            >
                                <div className="bg-white center box-home p-4 pt-5">
                                    <div className=" pb-25 wow fadeInUp box-item" data-wow-delay="0.7s">
                                        <img className="img-90 image-province" src={Icon2} alt="feature-icon" />
                                    </div>
                                    <p className="p-md box-text">
                                        Hỗ trợ lãnh đạo của đơn vị trong việc theo dõi và quản lý thông tin có liên quan
                                        đến đơn vị đó trên không gian mạng.
                                    </p>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </section>
                {/*   #info-12 {
            background: left top url(image/bg-ve-chung-toi.svg) !important;
            background-repeat: no-repeat !important;
            padding-bottom: 50px !important;
        } */}
                <section id="info-12" className="info-12-row wide-100  info-section division">
                    <div className="container">
                        <div
                            className="row"
                            style={{
                                marginBottom: '20px',
                            }}
                        >
                            <div className="col-lg-10 col-xl-10 offset-lg-1 offset-xl-1">
                                <motion.div
                                    className="txt-block text-center mb-50 wow fadeInUp"
                                    data-wow-delay="0.3s"
                                    style={{ marginBottom: '50px' }}
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay1}
                                    viewport={{ once: true }}
                                >
                                    <h3
                                        className="h3-lg  text-tranfromss"
                                        style={{ fontWeight: 700, fontSize: '2.5rem', marginBottom: '35px' }}
                                    >
                                        Hiệu quả mà VnAlert Province mang lại
                                    </h3>
                                    <div className="gach-tran" />
                                </motion.div>
                            </div>
                        </div>
                        <div className="pc-on">
                            <div className="row d-flex align-items-center ">
                                <div className="col-md-6">
                                    <div className="txt-block">
                                        <motion.div
                                            id="list-tab"
                                            className="list-group-province"
                                            initial="initial"
                                            whileInView="animate"
                                            variants={fadeInUp}
                                            viewport={{ once: true }}
                                        >
                                            {/* TAB #1  */}
                                            {options.map((item) => (
                                                <div
                                                    className="pt-0 pb-4 list-group-item "
                                                    style={{ cursor: 'pointer' }}
                                                    key={item.key}
                                                    onClick={() => setNumberTab(item.key)}
                                                >
                                                    <h5 className={`h5-sm ${item.key === numberTab && 'active'}`}>
                                                        {item.value}
                                                    </h5>
                                                </div>
                                            ))}
                                        </motion.div>
                                    </div>
                                </div>
                                {/* IMAGE BLOCK */}

                                <div className="col-md-6">
                                    <div className="info-img text-center ">
                                        <motion.div
                                            id="nav-tabContent"
                                            className="tab-content"
                                            initial="initial"
                                            whileInView="animate"
                                            variants={fadeInUpDelay2}
                                            viewport={{ once: true }}
                                        >
                                            {options.map((item) => {
                                                if (item.key === numberTab) {
                                                    return (
                                                        <div id="tab-1" className="tab-pane show active" key={item.key}>
                                                            <img className="img-fluid" src={item.img} alt="tab" />
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* mobile */}
                        <div className="mobile-on">
                            <SlickSlider>
                                {options.map((item) => (
                                    <div className="col-md-6 col-lg-4" key={item.key}>
                                        <motion.div
                                            className="fbox-2 icon-sm wow fadeInUp"
                                            data-wow-delay="0.3s"
                                            initial="initial"
                                            whileInView="animate"
                                            variants={fadeInUpDelay1}
                                            viewport={{ once: true }}
                                        >
                                            <div
                                                className="ibox-5 color-custom "
                                                style={{
                                                    marginBottom: '60px',
                                                }}
                                            >
                                                <h5 className="h5-sm px-30 text-center mb-0">{item.value}</h5>
                                            </div>
                                            <img
                                                className="img-fluid"
                                                style={{ borderRadius: '10px', margin: 'auto', marginBottom: '45px' }}
                                                src={item.img}
                                                alt=""
                                            />
                                        </motion.div>
                                    </div>
                                ))}
                            </SlickSlider>
                        </div>
                    </div>
                </section>
                {/* bg-ve-chung-toi */}
                <section className="wide-100 bg-white pb-80 features-section division  ">
                    <div className="container ">
                        <div className="row ">
                            <motion.div
                                className="col-lg-10 offset-lg-1 section-title wow fadeInUp"
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUp}
                                viewport={{ once: true }}
                            >
                                <h3
                                    className="h3-lg "
                                    style={{ fontWeight: 700, fontSize: '2.5rem', marginBottom: '35px' }}
                                >
                                    Tính năng nổi bật của VnAlert Province
                                </h3>
                                <div className="gach-tran" />
                            </motion.div>
                        </div>
                    </div>
                    <div className="container mt-100">
                        {/*1*/}
                        <div className="row d-flex align-items-center section-reverse">
                            <div className="col-md-6 d-block">
                                <motion.div
                                    className="img-block  wow fadeInUp"
                                    data-wow-duration="1.5s"
                                    data-wow-delay="0.8s"
                                    style={{
                                        marginBottom: '40px',
                                    }}
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay2}
                                    viewport={{ once: true }}
                                >
                                    <img className="img-fluid w-100" src={image1} alt="info" />
                                </motion.div>
                            </div>
                            <div className="col-md-6">
                                <motion.div
                                    className="txt-block pl-60 mb-40 wow fadeInUp"
                                    data-wow-delay="0.3s"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay1}
                                    viewport={{ once: true }}
                                >
                                    <h3 className="h3-lg" style={{ fontSize: '2.25rem' }}>
                                        Theo dõi thông tin Sở ngành và Địa phương
                                    </h3>
                                    <div className="gach-tran-2 mb-35" />
                                    <ul className="txt-list mb-35 ml-4 text-ul-li-color">
                                        <li>
                                            Tự động phân loại thông tin vào các cảnh báo Sở ban ngành và Địa phương{' '}
                                        </li>
                                        <li>Phân cấp thông tin đến từng đơn vị</li>
                                        <li>
                                            Toàn bộ thông tin liên quan đến địa phương được thu thập vào một kho dữ liệu
                                        </li>
                                        <li>Thống kê tự động số lượng và sắc thái tin thông tin dưới dạng biểu đồ</li>
                                        <li>Đo lường chỉ số lan tỏa của thông tin </li>
                                        <li>Cho phép thêm bớt nguồn theo dõi thông tin tự động </li>
                                    </ul>
                                </motion.div>
                            </div>
                        </div>
                        {/*2  */}
                        <div className="row d-flex align-items-center">
                            <div className="col-md-6">
                                <motion.div
                                    className="txt-block pr-70 mb-40 wow fadeInUp"
                                    data-wow-delay="0.3s"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay1}
                                    viewport={{ once: true }}
                                >
                                    <h3 className="h3-lg" style={{ fontSize: '2.25rem' }}>
                                        Cảnh báo thông tin qua Viber/Telegram
                                    </h3>
                                    <div className="gach-tran-2 mb-35" />
                                    <ul className="txt-list mb-35 ml-4 text-ul-li-color">
                                        <li>
                                            Gửi thông báo tin tức mới định kỳ về điện thoại cá nhân (thông qua
                                            Viber/Telegram) theo thiết lập tùy chỉnh{' '}
                                        </li>
                                        <li>Tự động cảnh báo báo tin tích cực/tiêu cực</li>
                                    </ul>
                                </motion.div>
                            </div>
                            <div className="col-md-6">
                                <motion.div
                                    className="img-block  mb-40 wow fadeInUp"
                                    data-wow-duration="1.5s"
                                    data-wow-delay="0.8s"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay2}
                                    viewport={{ once: true }}
                                >
                                    <img className="img-fluid" src={image01} alt="info" />
                                </motion.div>
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="row d-flex align-items-center section-reverse">
                            <div className="col-md-6 d-block">
                                <motion.div
                                    className="img-block mb-40 wow fadeInUp"
                                    data-wow-duration="1.5s"
                                    data-wow-delay="0.8s"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay2}
                                    viewport={{ once: true }}
                                >
                                    <img className="img-fluid" src={image02} alt="info" />
                                </motion.div>
                            </div>
                            <div className="col-md-6">
                                <motion.div
                                    className="txt-block pl-60 mb-40 wow fadeInUp"
                                    data-wow-delay="0.3s"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay1}
                                    viewport={{ once: true }}
                                >
                                    <h3 className="h3-lg" style={{ fontSize: '2.25rem' }}>
                                        Tạo báo cáo phân tích truyền thông tự động
                                    </h3>
                                    <div className="gach-tran-2 mb-35" />
                                    <ul className="txt-list mb-35 ml-4 text-ul-li-color">
                                        <li>
                                            Tạo/tải báo cáo phân tích truyền thông về một đối tượng bất kỳ trong đơn vị
                                        </li>
                                        <li>Tạo/tải báo cáo định kỳ và tự động gửi theo danh sách có sẵn</li>
                                    </ul>
                                </motion.div>
                            </div>
                        </div>
                        {/* 4 */}
                        <div className="row d-flex align-items-center">
                            <div className="col-md-6">
                                <motion.div
                                    className="txt-block pr-70 mb-40 wow fadeInUp"
                                    data-wow-delay="0.3s"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay1}
                                    viewport={{ once: true }}
                                >
                                    <h3 className="h3-lg" style={{ fontSize: '2.25rem' }}>
                                        Tải danh sách tin bài về <br /> file excel
                                    </h3>
                                    <div className="gach-tran-2 mb-35" />
                                    <ul className="txt-list mb-35 ml-4 text-ul-li-color">
                                        <li>
                                            Tải danh sách tin bài về file excel nhằm hỗ trợ đơn vị trong việc phân tích,
                                            thống kê nâng cao
                                        </li>
                                    </ul>
                                </motion.div>
                            </div>
                            <div className="col-md-6">
                                <motion.div
                                    className="img-block  mb-40 wow fadeInUp"
                                    data-wow-duration="1.5s"
                                    data-wow-delay="0.8s"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay2}
                                    viewport={{ once: true }}
                                >
                                    <img className="img-fluid" src={image03} alt="info" />
                                </motion.div>
                            </div>
                        </div>
                        {/* 5 */}
                        <div className="row d-flex align-items-center section-reverse">
                            <div className="col-md-6 d-block">
                                <motion.div
                                    className="img-block mb-40 wow fadeInUp"
                                    data-wow-duration="1.5s"
                                    data-wow-delay="0.8s"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay2}
                                    viewport={{ once: true }}
                                >
                                    <img className="img-fluid" src={image04} alt="info" />
                                </motion.div>
                            </div>
                            <div className="col-md-6">
                                <motion.div
                                    className="txt-block pl-60 mb-40 wow fadeInUp"
                                    data-wow-delay="0.3s"
                                    initial="initial"
                                    whileInView="animate"
                                    variants={fadeInUpDelay1}
                                    viewport={{ once: true }}
                                >
                                    <h3 className="h3-lg" style={{ fontSize: '2.25rem' }}>
                                        Cá nhân hóa thông tin{' '}
                                    </h3>
                                    <div className="gach-tran-2 mb-35" />
                                    <ul className="txt-list mb-35 ml-4 text-ul-li-color">
                                        <li>
                                            Trang chủ của hệ thống tự động chọn lựa và hiển thị thông tin cá nhân hóa
                                            tời từng tài khoản{' '}
                                        </li>
                                        <li>
                                            Người dùng có thể chọn các chủ đề quan tâm để hiển thị ngay trên trang chủ{' '}
                                        </li>
                                        <li>
                                            Xu hướng thông tin nổi bật của chủ đề được chọn sẽ hiển thị trên trang chủ
                                        </li>
                                    </ul>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*  */}
                <section className="bg-white relative pb-60 features-section bg-scroll mt-100 division ">
                    <div className="container absolute  " style={{ bottom: '-10rem' }}>
                        <motion.div
                            className="hero-txt text-center mb-100"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <h3
                                className="h3-lg  mb-35  wow fadeInUp"
                                style={{ fontFamily: '"Roboto"', fontWeight: 700, fontSize: '2.5rem' }}
                            >
                                Tính năng nổi bật của VnAlert Province{' '}
                            </h3>
                            <div className="gach-tran "></div>
                        </motion.div>
                        <motion.div
                            className="row justify-content-center"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            <div
                                className="col-md-6 col-lg-4 px-30 wow fadeInUp pricing-table"
                                style={{
                                    marginBottom: '25px',
                                }}
                            >
                                <div className="bg-white center box-home p-4 pt-5">
                                    <p className="p-md">
                                        Hệ thống được thiết lập và hoạt động trên server của AIV Group. Đơn vị sử dụng
                                        qua website và tài khoản được cung cấp.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="col-md-6 col-lg-4 px-30 wow fadeInUp "
                                style={{
                                    marginBottom: '25px',
                                }}
                            >
                                <div className="bg-white center box-home p-4 pt-5">
                                    <p className="p-md">
                                        AIV Group hỗ trợ kỹ thuật 24/7 thông qua group chat và các hình thức hỗ trợ trực
                                        tuyến khác.
                                    </p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Province;
