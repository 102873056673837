/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import image1 from '../../../assets/image/business-img-1.svg';
import image2 from '../../../assets/image/clip-path-group.svg';
import SlickSlider from '../../../components/slickSlider/SlickSlider';
const Different = () => {
    const options = [
        {
            id: 1,
            content:
                'Doanh nghiệp chỉ mất chi phí cho domain, và hỗ trợ migrate dữ liệu từ Gsuite hoặc server riêng(nếu  cần), tuyệt đối không mất chi phí sử dụng và duy trì business mail như thông thường! ',
        },
        {
            id: 2,
            content: 'Tiết kiệm hàng chục triệu/năm so với Business Email của Gsuite',
        },
        {
            id: 3,
            content: 'Giải pháp hoàn toàn hợp pháp và ổn định trong sử dụng',
        },
        {
            id: 3,
            content: 'Có tới 200Gb dung lượng mail miễn phí',
        },
        {
            id: 5,
            content: 'Tạo tới 20 Mail group, gửi tối đa 450 mail/ngày/địa chỉ mail  ',
        },
        {
            id: 6,
            content: 'Có thể thêm nhiều domain khác nhau để tạo mail alias',
        },
        {
            id: 7,
            content:
                'Được sử dụng miễn phí nhiều module quản trị khác: chat, video call, giao task, docs, chấm công, phê duyệt theo luồng tự động hóa ',
        },
    ];

    return (
        <div
            className="diff-business"
            style={{
                position: 'relative',
                paddingTop: '150px',
                paddingBottom: '150px',
            }}
        >
            <object
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 300,
                    opacity: 0.5,
                }}
                className="pc-on"
                type="image/svg+xml"
                data={image2}
            />
            <div className="container">
                <div style={{ textAlign: 'center' }}>
                    <h2 className="h2-xs" style={{ marginBottom: '20px' }}>
                        Sự khác biệt của dịch vụ
                    </h2>
                    <h4 className="font-400 text-cuxtom-h4-dfif" style={{ textTransform: 'uppercase' }}>
                        hỗ trợ doanh nghiệp sử dụng 50
                        <br className="mobile-on" /> business mail với giá 0đ
                    </h4>
                </div>
                <div className="diff-content">
                    <object className="diff-img" type="image/svg+xml" data={image1} />
                    <div className="father-box" style={{ width: 500 }}>
                        <SlickSlider
                            settings={{
                                customPaging: (i) => <div className="ft-slick__dots--custom" />,
                            }}
                        >
                            {options.map((item) => (
                                <div className="diff-box" key={item.id}>
                                    <p className="p-md" style={{ textAlign: 'justify' }}>
                                        {item.content}
                                    </p>
                                </div>
                            ))}
                        </SlickSlider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Different;
