import { motion } from 'framer-motion';
import React from 'react';
import image1 from '../../../assets/image/ebook-solution-1.svg';
import image2 from '../../../assets/image/ebook-solution-2.svg';
import image3 from '../../../assets/image/ebook-solution-3.svg';
import { fadeInUp } from '../../../assets/utils/utils';
const Solution = () => {
    const options = [
        {
            img: image1,
            description: 'Chưa có giao diện tối ưu cho việc đọc tài liệu trên điện thoại di động',
        },
        {
            img: image2,
            description: 'Chưa có nguồn lưu trữ thống nhất để đăng tải và xem tài liệu',
        },
        {
            img: image3,
            description: 'Giải pháp truyền thống yêu cầu máy chủ mạnh, quản trị phức tạp, dễ dính virus',
        },
    ];
    return (
        <div className="solution-ebook" style={{ background: '#F5F8FF', paddingBottom: '80px', paddingTop: '80px' }}>
            <div className="container">
                <motion.div
                    style={{ textAlign: 'center' }}
                    initial="initial"
                    whileInView="animate"
                    variants={fadeInUp}
                    viewport={{ once: true }}
                >
                    <h2 className="h2-custom-ebook">
                        Giải pháp website sách điện tử<br className="pc-on"></br> có thể giúp gì cho địa phương?
                    </h2>
                    <h4 className="py-5">
                        Việc phổ biến sách báo, tài liệu điện tử tại các địa phương hiện gặp<br className="pc-on"></br>{' '}
                        một số khó khăn
                    </h4>
                </motion.div>
                <motion.div
                    className="solution-content"
                    initial="initial"
                    whileInView="animate"
                    variants={fadeInUp}
                    viewport={{ once: true }}
                >
                    {options.map((item) => (
                        <div className="solution-box" key={item.img}>
                            <div className="d-flex justify-content-center">
                                <img src={item.img} alt={item.description} />
                            </div>
                            <p className="p-md">{item.description}</p>
                        </div>
                    ))}
                </motion.div>
            </div>
        </div>
    );
};

export default Solution;
