export const fetchAPI = async (path, urlParamsObject) => {
    // Merge default and user options
    const mergedOptions = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    // Build request URL
    const requestUrl = !urlParamsObject
        ? `${process.env.REACT_APP_AIV_API_URL}/api${path}?populate=*`
        : `${process.env.REACT_APP_AIV_API_URL}/api${path + urlParamsObject}`;

    // Trigger API call
    const response = await fetch(requestUrl, mergedOptions);

    // Handle response
    if (!response.ok) {
        throw new Error(`An error occurred please try again`);
    }

    const data = await response.json();

    return data;
};

export const PostAPI = async (path, data, token) => {
    // Merge default and user options
    const mergedOptions = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + token,
        },
        method: 'POST',
        body: JSON.stringify({
            data: data,
        }),
    };

    // Build request URL
    const requestUrl = `${process.env.REACT_APP_AIV_API_URL}/api${path}`;

    try {
        const response = await fetch(requestUrl, mergedOptions);
        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (error) {
        console.error(error);
    }
};
