import React from 'react';
import Banner from './components/Banner';
import Benefit from './components/Benefit';
import Feature from './components/Feature';
import Illustration from './components/illustration';
import Information from './components/Information';
import Section from './components/Section';
import Solution from './components/Solution';
import './style/ebook.scss';

const Ebook = () => {
    return (
        <div className="ebook">
            <Banner />
            <Information />
            <Benefit />
            <Solution />
            <Section />
            <Feature />
            <Illustration />
        </div>
    );
};

export default Ebook;
