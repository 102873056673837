import { motion } from 'framer-motion';

import { useState } from 'react';
import { fadeInUp, fadeInUpDelay1, fadeInUpDelay2 } from '../../assets/utils/utils';
import SlickSlider from '../../components/slickSlider/SlickSlider';
import { imageReducer } from '../../components/utils';
const Table4 = ({ dataProducts }) => {
    const [selectedText, setSelectedText] = useState(1);

    const handleClick = (id) => {
        setSelectedText(id);
    };

    return (
        <section id="info-12" className="info-12-row wide-100   info-section division">
            <div className="container">
                <div className="row mb-20">
                    <div className="col-lg-10 col-xl-10 offset-lg-1 offset-xl-1">
                        <motion.div
                            className="txt-block text-center mb-50 wow fadeInUp"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUpDelay1}
                            viewport={{ once: true }}
                        >
                            <h3 className="h3-lg mb-35 text-tranfromss" style={{ fontWeight: 700, fontSize: '2.5rem' }}>
                                Vì sao nên lựa chọn các <br className="mobile-on" /> sản phẩm của AIV?
                            </h3>
                            <div className="gach-tran" />
                        </motion.div>
                    </div>
                </div>
                <div className="pc-on">
                    <div className="row d-flex  align-items-center ">
                        <div className="col-md-6">
                            <motion.div
                                className="txt-block"
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUp}
                                viewport={{ once: true }}
                            >
                                <div id="list-tab" role="tablist">
                                    {dataProducts.map((item) => (
                                        <div
                                            // className="pt-0 pb-4 list-group-item"
                                            style={{ cursor: 'pointer' }}
                                            key={item.id}
                                            onClick={() => handleClick(item.id)}
                                        >
                                            <h5
                                                className={
                                                    item.id === selectedText
                                                        ? 'red-text pt-0 pb-4 list-group-item'
                                                        : 'default-text pt-0 pb-4 list-group-item'
                                                }
                                            >
                                                {item.attributes.title}
                                            </h5>
                                        </div>
                                    ))}
                                </div>
                            </motion.div>
                        </div>
                        <motion.div
                            className="col-md-6"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUpDelay2}
                            viewport={{ once: true }}
                        >
                            {dataProducts.map(
                                (image) =>
                                    image.id === selectedText && (
                                        <img
                                            src={imageReducer(image?.attributes.img.data[0]?.attributes.url)?.url}
                                            key={image.id}
                                            className="img-fluid"
                                            alt="fluid"
                                        />
                                    ),
                            )}
                        </motion.div>
                    </div>
                </div>
                <div className="row mobile-on ">
                    <SlickSlider>
                        {dataProducts.map((item) => (
                            <div className="fbox-2 icon-sm wow fadeInUp animated" key={item.id}>
                                <div className="ibox-5 mb-60 color-custom ">
                                    <h5 className="h5-sm px-30 text-center mb-0">{item.attributes.title}</h5>
                                </div>
                                <img
                                    className="img-fluid mb-45 "
                                    style={{ borderRadius: 10 }}
                                    src={imageReducer(item?.attributes.img.data[0]?.attributes.url)?.url}
                                    alt={item.attributes.title}
                                />
                            </div>
                        ))}
                    </SlickSlider>
                </div>
            </div>
        </section>
    );
};

export default Table4;
