import ElementVector from '../../../../assets/image/ElementVector.svg';
export default function TextCustomer({ title }) {
    return (
        <div className="d-flex text-style">
            <div className="text-customer">
                <h2 className="h2-xs">{title}</h2>
                <img src={ElementVector} alt="vector" />
            </div>
        </div>
    );
}
