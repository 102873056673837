import React from 'react';
import Banner from './components/Banner';
import './style/ebook.scss';
import Integration from './components/integration';
import Salientfeatures from './components/salientfeatures';
import Services from './components/services';
import DigitalConv from './components/digitalConv';
import Story from './components/story';
import Contact from './components/contact';

const Lark = () => {
    return (
        <div className="lark">
            <Banner />
            <Integration />
            <Salientfeatures />
            <Services />
            <DigitalConv />
            <Story />
            <Contact />
        </div>
    );
};

export default Lark;
