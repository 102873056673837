/* eslint-disable react/jsx-no-target-blank */
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchAPI } from '../../api';
import '../../assets/css/footer.css';
import logo from '../../assets/image/AIV-logo-red.svg';
import mail from '../../assets/image/ant-design_mail.svg';
import banner from '../../assets/image/banner-f.png';
import location from '../../assets/image/bx_bx-map.svg';
import global from '../../assets/image/global.svg';
import faceBook from '../../assets/image/icon-f.svg';
import instagram from '../../assets/image/icon-insta.svg';
import twitter from '../../assets/image/icon-tw.svg';
import logomindmaid from '../../assets/image/logo-mindmaid.svg';

import linken from '../../assets/image/linken.svg';
import { fadeInUp } from '../../assets/utils/utils';
import { imageReducer } from '../utils';
const Footer = () => {
    const [dataFooter, setDataFooter] = useState([]);

    useEffect(() => {
        fetchAPI('/footers').then((res) =>
            setDataFooter(
                res.data.sort((a, b) => {
                    if (a.id > b.id) return 1;
                    if (a.id < b.id) return -1;
                    return null;
                }),
            ),
        );
    }, []);

    return (
        <div
            className="bg-image position-relative bottom-0"
            style={{
                backgroundImage: `url(${banner})`,
                backgroundSize: 'cover',
            }}
        >
            <div
                className="container p-4"
                style={{
                    borderBottom: 'solid #E5E5EA',
                }}
            >
                {/*Grid row*/}
                <div className="row">
                    <div className="col-lg-6 col-md-6 mb-4 mb-md-0 ">
                        <img
                            src={logo}
                            style={{
                                width: '200px',
                                height: '81px',
                            }}
                            className="mb-4"
                            alt=""
                        />
                        <h5 className="mb-4">Công ty Cổ phần Công nghệ và Truyền thông AIV Group</h5>
                        <ul className="list-unstyled text-black gap-3">
                            <li className="d-flex align-items-center mb-4 gap-3">
                                <img src={location} alt="location" />
                                <div className="font-weight-bold md-fs-4 address">
                                    Địa chỉ: Tầng 23 tòa nhà Eurowindow Multi Complex, 27 Trần Duy Hưng,quận Cầu Giấy,
                                    T.P Hà Nội
                                </div>
                            </li>
                            <li className="d-flex align-items-center mb-4 gap-3">
                                <img src={global} alt="phone" />
                                <div className="font-weight-bold address ">
                                    <a href="https://aivgroup.vn" className="text-black">
                                        https://aivgroup.vn
                                    </a>
                                </div>
                            </li>
                            <li className="d-flex align-items-center mb-4 gap-3">
                                <img src={mail} alt="mail" />
                                <div className="font-weight-bold address">info@aivgroup.vn</div>
                            </li>
                        </ul>
                    </div>
                    {/*Grid column*/}
                    <div className="col-lg-6 col-md-6 mt-4">
                        <div>
                            <h4 className="h3-lg">Hệ sinh thái AIV Group</h4>
                            <div
                                style={{
                                    borderBottom: 'solid',
                                    borderRadius: '5px',
                                    width: '100px',
                                    color: 'black',
                                }}
                            />
                        </div>

                        <motion.div
                            className="d-flex flex-wrap mt-50"
                            style={{
                                marginTop: '64px',
                            }}
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUp}
                            viewport={{ once: true }}
                        >
                            {dataFooter.map((item) => (
                                <div className="col-sm-6 col-6 mb-cus col-md-6 col-lg-4 p-2" key={item.id}>
                                    <Link to={item.attributes.href} className="w-100 project">
                                        <img
                                            className="w-100 "
                                            style={{ boxShadow: '0 0 1px #c4c4c4' }}
                                            src={imageReducer(item?.attributes?.img?.data[0]?.attributes?.url).url}
                                            alt=""
                                        />
                                    </Link>
                                </div>
                            ))}
                            <div className="col-sm-6 col-6 mb-cus col-md-6 col-lg-4 p-2">
                                <a href="https://mindmaid.ai/" target="_blank" className="w-100 project">
                                    <img
                                        className="w-100 py-0.5 "
                                        style={{ boxShadow: '0 0 1px #c4c4c4', height: '46px' }}
                                        src={logomindmaid}
                                        alt=""
                                    />
                                </a>
                            </div>
                        </motion.div>
                    </div>
                    {/*Grid column*/}
                </div>
                {/*Grid row*/}
            </div>
            {/* Grid container */}
            {/* Copyright */}
            <div className="container">
                <div className="row d-flex align-items-center justify-content-between">
                    <div className="col-md-4 col-sm-12 col-lg-5">
                        <ul className=" d-flex gap-5 footer_social">
                            <li className="pr-40 pr-custom">
                                <img src={faceBook} alt="" />
                            </li>
                            <li className="pr-40 pr-custom ">
                                <img src={linken} alt="" />
                            </li>
                            <li className="pr-40 pr-custom">
                                <img src={instagram} alt="" />
                            </li>
                            <li className="">
                                <img src={twitter} alt="" />
                            </li>
                        </ul>
                    </div>
                    <div
                        className="col-lg-7 pc-on mt-un col-md-8 col-sm-12 footer-info "
                        style={{
                            marginBottom: '40px',
                        }}
                    >
                        <div
                            className=" ml-10 ml-10-custom "
                            style={{
                                marginTop: '40px',
                            }}
                        >
                            <ul className=" d-flex gap-5 justify-content-end">
                                <li className="pr-40 pr-custom2 cursor">
                                    <a href="ve-chung-toi.html" style={{ color: '#333' }}>
                                        Về chúng tôi
                                    </a>
                                </li>
                                <li className="pr-40 pr-custom2">
                                    <a href="https://vnalert.vn" style={{ color: '#333' }}>
                                        Sản phẩm
                                    </a>
                                </li>

                                <li className="pr-40 pr-custom2">
                                    <a href="giai-phap.html" style={{ color: '#333' }}>
                                        Giải pháp
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Copyright */}
        </div>
    );
};

export default Footer;
