import IconFb from '../../assets/image/ant-design_facebook-filled.svg';
import IconIn from '../../assets/image/ant-design_linkedin-filled.svg';
import IconEmail from '../../assets/image/ant-design_mail-outlined.svg';
import IconLocation from '../../assets/image/bx-bx-map.svg';
import IconPhone from '../../assets/image/ic-baseline-phone-android.svg';
import Banners from './banner';
import BoxConnect from './connect';
import BoxDiscover from './discover';
import './style.scss';

export default function Recruit() {
    return (
        <div id="recruit-style">
            <Banners />
            <BoxDiscover />
            <BoxConnect
                IconLocation={IconLocation}
                IconPhone={IconPhone}
                IconEmail={IconEmail}
                IconFb={IconFb}
                IconIn={IconIn}
            />
        </div>
    );
}
