import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollToTop } from 'react-simple-scroll-up';
import '../src/assets/css/global.css';
import './assets/css/style.css';
import Footer from './components/footer/footer';
import FooterBusiness from './components/footer/footer-business';
import FooterEbook from './components/footer/footer_ebook';
import NavbarLark from './components/navbar/lark/navBarLark';
import HeaderMindmaid from './components/navbar/mindmaid/headerMindmaid';
import Navbar from './components/navbar/navBar';
import NavBarBusiness from './components/navbar/navBar-business';
import NavbarEbook from './components/navbar/navBarEbook';
import Routers from './routes';

function App() {
    const [uiHeader, setUiHeader] = useState(<Navbar />);
    const [uiFooter, setUiFooter] = useState(<Footer />);
    const { pathname } = useLocation();

    const handleRenderHeader = () => {
        switch (pathname) {
            case '/business':
                setUiHeader(<NavBarBusiness />);
                setUiFooter(<FooterBusiness />);
                break;
            case '/ebook':
                setUiHeader(<NavbarEbook />);
                setUiFooter(<FooterEbook />);
                break;
            case '/lark':
                setUiHeader(<NavbarLark />);
                break;
            case '/mindmaid':
                setUiHeader(<HeaderMindmaid />);
                setUiFooter(<FooterEbook />);
                break;
            default:
                if (uiHeader !== <Navbar />) setUiHeader(<Navbar />);
                if (uiFooter !== <Footer />) setUiFooter(<Footer />);
                break;
        }
    };

    useEffect(() => {
        handleRenderHeader();
    }, [pathname]);

    return (
        <div className="page">
            {uiHeader}
            <Routers />
            {uiFooter}
            <ScrollToTop bgColor="#ff1f1f" size="60" style={{ bottom: 90, right: 20 }} symbol="⬆" symbolSize="28" />
        </div>
    );
}

export default App;
