export const fadeInUp = {
    initial: {
        y: '30%',
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeInOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1.25,
            ease: 'easeInOut',
        },
    },
};

export const fadeInUpDelay1 = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeInOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1.25,
            ease: 'easeInOut',
            delay: 0.3,
        },
    },
};

export const fadeInUpDelay2 = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeInOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1.25,
            ease: 'easeInOut',
            delay: 0.5,
        },
    },
};

export const fadeInUpDelay3 = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeInOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1.25,
            ease: 'easeInOut',
            delay: 0.7,
        },
    },
};

export const fadeInDown = {
    initial: {
        y: -60,
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeInOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            ease: 'easeInOut',
        },
    },
};

export const fadeInLeft = {
    initial: {
        x: -60,
        opacity: 0,
        transition: { duration: 0.03, ease: 'easeInOut' },
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            ease: 'easeInOut',
        },
    },
};

export const fadeInRight = {
    initial: {
        x: 60,
        opacity: 0,
        transition: { duration: 0.03, ease: 'easeInOut' },
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1.25,
            ease: 'easeInOut',
        },
    },
};
