import { motion } from 'framer-motion';
import { fadeInUpDelay1 } from '../../assets/utils/utils';
export default function Banners() {
    return (
        <section id="banner-tuyen-dung" className="bg-scroll hero-section division">
            <div className="container white-color mb-100 mt-100">
                <div className="row mb-10">
                    <div className="col-md-6 mt-90 col-xl-5">
                        <motion.div
                            className="hero-txt  wow fadeInUp"
                            initial="initial"
                            whileInView="animate"
                            variants={fadeInUpDelay1}
                            viewport={{ once: true }}
                        >
                            <h3 className="h3-xl" style={{ color: '#4D4D4D' }}>
                                Tìm kiếm <br /> công việc ước mơ
                            </h3>
                            <p className="p-md text-black mt-40" style={{ color: '#4D4D4D' }}>
                                AIV Group chào đón những ai dám đón nhận thách thức để theo đuổi đam mê
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
}
