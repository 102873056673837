import { motion } from 'framer-motion';
import { fadeInUpDelay1 } from '../../assets/utils/utils';
import { imageReducer } from '../../components/utils';
const Feature2 = ({ dataAchievement }) => {
    return (
        <>
            <section id="features-2" className="wide-100 mt-60 bg-white pb-60 features-section division  ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-xl-10 offset-lg-1 offset-xl-1">
                            <motion.div
                                className="txt-block text-center wow fadeInUp"
                                initial="initial"
                                whileInView="animate"
                                variants={fadeInUpDelay1}
                                viewport={{ once: true }}
                            >
                                <h3
                                    className="h3-lg mb-35 text-tranfromss"
                                    style={{ fontWeight: 700, fontSize: '2.5rem' }}
                                >
                                    Thành tựu nổi bật{' '}
                                </h3>
                                <div className="gach-tran" />
                            </motion.div>
                        </div>
                    </div>
                    <div className="row mt-3 ">
                        {dataAchievement.map((item) => (
                            <div key={item.id}>
                                <p className="p-md  mb-50" style={{ color: '#888', textAlign: 'center' }}>
                                    {item.attributes.description}
                                </p>
                                <div className="col-lg-10 offset-lg-1">
                                    <motion.div
                                        className="img-block wow fadeInUp pc-custom pc-30"
                                        initial="initial"
                                        whileInView="animate"
                                        variants={fadeInUpDelay1}
                                        viewport={{ once: true }}
                                    >
                                        <img
                                            className="img-fluid"
                                            src={imageReducer(item?.attributes?.img?.data[0]?.attributes.url)?.url}
                                            alt="info"
                                        />
                                    </motion.div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Feature2;
